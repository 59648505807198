import { StyleSheet } from 'react-native'

import { LightScreenWrapper, Button, ExpoImage, Text, Center } from '~components'
import { ERROR } from '~constants'
import { useCallback, useEffect, useTranslation } from '~hooks'
import { useConfirmChangeEmailMutation } from '~query-hooks'

export const ConfirmChangeEmailScreen = (props: ConfirmChangeEmailScreenProps): JSX.Element => {
  const {
    navigation: { navigate },
    route: {
      params: { oobCode },
    },
  } = props
  const { t } = useTranslation()

  const { confirmChangeEmailIsLoading, confirmChangeEmailMutation, isError } =
    useConfirmChangeEmailMutation()

  useEffect(() => {
    confirmChangeEmailMutation({ oobCode })
  }, [confirmChangeEmailMutation, oobCode])

  const navigateToChangeEmail = useCallback(() => {
    navigate('ChangeEmail')
  }, [navigate])

  return (
    <LightScreenWrapper
      isBackButton={false}
      isLoading={confirmChangeEmailIsLoading}
      title={t(
        confirmChangeEmailIsLoading
          ? 'confirm_change_email_screen.processing'
          : isError
          ? 'confirm_change_email_screen.email_changed_error'
          : 'confirm_change_email_screen.email_changed_successfully'
      )}
      showDivider={false}
    >
      {isError ? (
        <>
          <Center mt={6}>
            <ExpoImage
              alt="error confirmation image"
              contentFit="contain"
              source={ERROR}
              style={styles.infoImage}
            />
          </Center>
          <Text my={6}>{t('confirm_change_email_screen.email_changed_error_description')}</Text>
          <Button onPress={navigateToChangeEmail}>
            {t('confirm_change_email_screen.try_again')}
          </Button>
        </>
      ) : null}
    </LightScreenWrapper>
  )
}

const styles = StyleSheet.create({
  infoImage: { height: 94, width: 94 },
})
