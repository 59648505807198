import { useForm } from 'react-hook-form'

import { useChangeEmailMutation } from '~query-hooks'
import { ChangeEmailFormValues } from '~types/authForms'
import { hapticImpact } from '~utils'

const defaultValues: ChangeEmailFormValues = {
  newEmail: '',
}
type UseChangeEmailProps = {
  callback(newEmail: string): void
}
export const useChangeEmailForm = ({ callback }: UseChangeEmailProps) => {
  const { changeEmailMutation, isChangeEmailLoading } = useChangeEmailMutation()

  const {
    clearErrors: clearFormErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setError: setFormError,
    setFocus,
  } = useForm<ChangeEmailFormValues>({
    mode: 'onTouched',
    defaultValues,
  })

  const onSubmit = ({ newEmail }: ChangeEmailFormValues) => {
    changeEmailMutation(
      { newEmail },
      { onSuccess: (_, { newEmail }) => callback(newEmail), onError: () => hapticImpact() }
    )
  }

  return {
    clearFormErrors,
    control,
    errors,
    getValues,
    isSubmitting: isChangeEmailLoading,
    setFocus,
    setFormError,
    submit: handleSubmit(onSubmit),
  }
}
