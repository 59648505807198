import { createNotifications } from 'react-native-notificated'

export const { NotificationsProvider, notify } = createNotifications({
  defaultStylesSettings: {
    globalConfig: {
      defaultIconType: 'no-icon',
      multiline: 8,
    },
  },
  duration: 5000,
  gestureConfig: {
    direction: 'full',
    x: { activationDistances: 100, activationVelocities: 5 },
    y: { activationDistances: 100, activationVelocities: 5 },
  },
  isNotch: true,
  notificationPosition: 'top',
})
