import { Image as ExpoImage } from 'expo-image'
import { FC } from 'react'
import { StyleSheet } from 'react-native'

import { LOGO } from '~constants'
import { useWeb } from '~hooks'

type UnAuthorizeLogoProps = {
  showAlways?: boolean
}

export const UnAuthorizeLogo: FC<UnAuthorizeLogoProps> = ({ showAlways = false }) => {
  const { shouldApplyMobileStyles } = useWeb()
  return showAlways || shouldApplyMobileStyles ? (
    <ExpoImage alt="app logo" contentFit="contain" source={LOGO} style={styles.logo} />
  ) : null
}

const styles = StyleSheet.create({
  logo: {
    alignSelf: 'center',
    height: 70,
    marginTop: 48,
    width: 160,
  },
})
