export const PRIVACY_POLICY_URL_EN = 'https://walluta.de/privacy-policy/'
export const PRIVACY_POLICY_URL_DE = 'https://walluta.de/datenschutzerklarung/'
export const TERMS_AND_CONDITION_URL_EN = 'https://walluta.de/terms-and-conditions/'
export const TERMS_AND_CONDITION_URL_DE = 'https://walluta.de/allgemeine-geschaftsbedingungen/'
export const BUY_CRYPTO_URL = 'https://connect.mondo-coin.com/en_GB/shop'

export const BITCOIN_SCAN_URL = 'https://btcscan.org/tx/'
export const ETHEREUM_SCAN_URL = 'https://etherscan.io/tx/'

export const BITCOIN_TEST_SCAN_URL = 'https://live.blockcypher.com/btc-testnet/tx/'
export const ETHEREUM_TEST_SCAN_URL = 'https://sepolia.etherscan.io/tx/'
