import { useMutation } from '@tanstack/react-query'

import { QueryKeys } from '~enums'
import { queryClient } from '~queryClient'
import { updateUserDataRequest } from '~services'
import { getToast } from '~utils'

export const useUpdateUserDataMutation = ({ successMessage }: { successMessage?: string }) => {
  const { mutateAsync, isLoading } = useMutation(updateUserDataRequest, {
    onSuccess: (userData) => {
      const { showSuccessToast } = getToast()
      successMessage && showSuccessToast(successMessage)
      queryClient.setQueryData([QueryKeys.MeQuery], userData)
    },
  })

  return { updateUserDataMutation: mutateAsync, isLoading }
}
