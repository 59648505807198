import { isError } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useMakeTransactionMutation } from '~query-hooks'
import { MakeTransactionFormValues } from '~services'
import { convertFromEuro, hapticImpact } from '~utils'

const defaultValues: MakeTransactionFormValues = {
  amount: '',
  shortName: undefined,
  to: '',
}

type UseMakeTransactionFormProps = {
  sendFromCrypto: boolean
  cryptoToEuroRate: number
}

export const useMakeTransactionForm = ({
  sendFromCrypto,
  cryptoToEuroRate,
}: UseMakeTransactionFormProps) => {
  const { t } = useTranslation()

  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { makeTransactionMutation } = useMakeTransactionMutation()

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    resetField,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<MakeTransactionFormValues>({
    defaultValues,
    mode: 'onTouched',
  })

  const onSubmit = async (data: MakeTransactionFormValues) => {
    try {
      setIsSubmitting(true)

      const amount = sendFromCrypto
        ? data?.amount
        : convertFromEuro(data?.amount, 1 / cryptoToEuroRate) || ''

      await makeTransactionMutation({ ...data, amount })
      resetField('amount')
      resetField('to')
      setError('')
    } catch (e) {
      hapticImpact()
      if (isError(e)) {
        setError(e.message)
        throw Error(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
        throw Error(t('errors.something_went_wrong'))
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    control,
    error,
    errors,
    getValues,
    handleSubmit,
    isSubmitting,
    resetField,
    setFocus,
    setIsSubmitting,
    setValue,
    submit: handleSubmit(onSubmit),
    trigger,
    watch,
  }
}
