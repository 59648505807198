import { useCallback, useEffect, useMemo, useState } from 'react'
import { FlatList, ListRenderItem, StyleSheet } from 'react-native'

import {
  Background,
  CommunicationCard,
  ListEmptyComponentBase,
  Loader,
  Select,
  Spacer,
  SwitchButton,
  TransactionItem,
  WalletActionsCard,
} from '~components'
import { Box, Divider, Row, Text } from '~components/atoms'
import {
  ASYNC_STORAGE_KEYS,
  breakpoints,
  EMPTY_STATE_ACTIVITIES,
  NETWORKS,
  PLACEHOLDER_TOKEN,
  TOKENS,
} from '~constants'
import { ACCOUNT_TYPE, COMMUNICATION_CARD_TYPE, KYC_STATUS } from '~enums'
import { useBoolean, useKycVerificationModal, useTranslation } from '~hooks'
import {
  useGetAssetsListQuery,
  useGetTransactionHistoryQuery,
  useGetVideoVerificationStatusQuery,
  useMeQuery,
} from '~query-hooks'
import { TransactionHistory } from '~services'
import { TOKEN_SHORTNAME_TYPE } from '~types/assets'
import { calculateWalletValueInEuro, generateAccountTypeItems, secureStore } from '~utils'

const keyExtractor = (item: TransactionHistory) => String(item.transactionHash)
const { CONFIRM_CHANGE_EMAIL_OOB_CODE } = ASYNC_STORAGE_KEYS

const ItemSeparatorComponent = () => (
  <Box px={4} bg="white">
    <Divider />
  </Box>
)

const ListEmptyComponent = () => {
  const { t } = useTranslation()
  return (
    <ListEmptyComponentBase
      emptyText={t('home_screen.no_activities')}
      source={EMPTY_STATE_ACTIVITIES}
    />
  )
}

export const HomeScreen = ({ navigation: { navigate } }: HomeScreenProps): JSX.Element => {
  const { t } = useTranslation()

  // LOCAL STATES
  const [isSeeMore, setIsSeeMore] = useBoolean(false)
  const [accountType, setAccountType] = useState<string[]>([])

  // MODAL STATES
  const { userData } = useMeQuery()
  const { videoVerificationStatus } = useGetVideoVerificationStatusQuery({ id: userData?.identId })

  const { kycModalComponent } = useKycVerificationModal()

  useEffect(() => {
    const checkIsConfirmEmailRequired = async () => {
      const oobCode = await secureStore.getItem(CONFIRM_CHANGE_EMAIL_OOB_CODE)
      if (oobCode) {
        navigate('ConfirmChangeEmail', { oobCode })
      }
    }

    try {
      checkIsConfirmEmailRequired()
    } catch (e) {
      console.log(e)
    }
  }, [navigate])

  const showMissingDataBadge = useMemo(
    () =>
      userData?.accountType === ACCOUNT_TYPE.PRIVATE
        ? !userData?.checkAllUserRequiredData?.personalData ||
          !userData?.checkAllUserRequiredData?.contactAddress ||
          !userData?.checkAllUserRequiredData?.registrationAddress
        : !userData?.checkAllUserRequiredData?.personalData ||
          !userData?.checkAllUserRequiredData?.contactAddress ||
          !userData?.checkAllUserRequiredData?.registrationAddress ||
          !userData?.checkAllUserRequiredData?.companyDetails ||
          !userData?.checkAllUserRequiredData?.companyRepresentative,
    [
      userData?.accountType,
      userData?.checkAllUserRequiredData?.companyDetails,
      userData?.checkAllUserRequiredData?.companyRepresentative,
      userData?.checkAllUserRequiredData?.contactAddress,
      userData?.checkAllUserRequiredData?.personalData,
      userData?.checkAllUserRequiredData?.registrationAddress,
    ]
  )

  const communicationCardType: COMMUNICATION_CARD_TYPE | undefined = useMemo(() => {
    if (!userData) return undefined
    if (showMissingDataBadge) return COMMUNICATION_CARD_TYPE.COMPLETE_PROFILE
    // FIXME: allow proceed kyc when ready on BE
    if (userData?.accountType === ACCOUNT_TYPE.BUSINESS) return undefined
    if (videoVerificationStatus === KYC_STATUS.NOT_STARTED)
      return COMMUNICATION_CARD_TYPE.COMPLETE_KYC
    if (videoVerificationStatus === KYC_STATUS.PENDING) {
      return COMMUNICATION_CARD_TYPE.KYC_PENDING
    }
    if (videoVerificationStatus === KYC_STATUS.FAILED) {
      return COMMUNICATION_CARD_TYPE.KYC_FAILED
    }
    if (videoVerificationStatus === KYC_STATUS.SUCCESS) {
      return COMMUNICATION_CARD_TYPE.DISCOVER_MONDOCOIN
    }
    // TODO: add option to return:
    // COMMUNICATION_CARD_TYPE.ENABLE_2FA
  }, [userData, showMissingDataBadge, videoVerificationStatus])

  // LIST ELEMENTS

  const { transactionHistoryList, isInitialLoadingTransactionHistoryList } =
    useGetTransactionHistoryQuery()
  const { assetsList, isInitialLoadingAssetsList } = useGetAssetsListQuery()

  const walletValueInEuro = useMemo(() => calculateWalletValueInEuro({ assetsList }), [assetsList])

  const cryptoToEuroRates: { [kye in TOKEN_SHORTNAME_TYPE]?: number } = useMemo(
    () =>
      assetsList.reduce((acc, el) => {
        return { ...acc, [el.tokenShortName]: el.cryptoToEuroRate }
      }, {}),
    [assetsList]
  )

  const data = useMemo(
    () =>
      !transactionHistoryList
        ? []
        : isSeeMore
        ? transactionHistoryList
        : transactionHistoryList?.slice(0, 3),
    [isSeeMore, transactionHistoryList]
  )

  const renderItem: ListRenderItem<TransactionHistory> = useCallback(
    ({ item, index }) => {
      return (
        <TransactionItem
          borderBottomEndRadius={index === data?.length - 1 ? 8 : 0}
          borderBottomLeftRadius={index === data?.length - 1 ? 8 : 0}
          cryptoToEuroRate={cryptoToEuroRates[item.shortName] || 1}
          networkImage={NETWORKS[item.networkLabel]}
          tokenImage={TOKENS[item.shortName] ?? PLACEHOLDER_TOKEN}
          {...item}
        />
      )
    },
    [cryptoToEuroRates, data?.length]
  )

  const ListHeaderComponent = useCallback(() => {
    const accountTypeItems = generateAccountTypeItems(t)
    return (
      <>
        <Spacer y="6" />
        <WalletActionsCard title={t('home_screen.total')} valueInEuro={walletValueInEuro} />
        <Spacer y="4" />
        {communicationCardType ? <CommunicationCard {...{ communicationCardType }} /> : null}
        <Spacer y="4" />
        <Box bg="white" borderTopEndRadius={8} borderTopLeftRadius={8} p={4}>
          <Row alignItems="center" justifyContent="space-between">
            <Text.Bold color="muted.500">{t('home_screen.recent_activities')}</Text.Bold>
            <SwitchButton isActive={isSeeMore} onPress={setIsSeeMore.toggle} />
          </Row>
          <Spacer y="3" />
          <Text.Caption color="text.500" mb={1}>
            {t('common.account')}
          </Text.Caption>
          <Select
            placeholder={t('form.placeholders.account_type')}
            items={accountTypeItems}
            value={accountType}
            setValue={setAccountType}
          />
        </Box>
      </>
    )
  }, [accountType, communicationCardType, isSeeMore, setIsSeeMore.toggle, t, walletValueInEuro])

  return (
    <Background>
      {isInitialLoadingAssetsList && isInitialLoadingTransactionHistoryList ? (
        <Loader type="bubbles" />
      ) : (
        <FlatList
          contentContainerStyle={styles.contentContainerStyle}
          showsVerticalScrollIndicator={false}
          {...{
            data,
            keyExtractor,
            ListEmptyComponent,
            ListHeaderComponent,
            ItemSeparatorComponent,
            renderItem,
          }}
        />
      )}
      {kycModalComponent}
    </Background>
  )
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    alignSelf: 'center',
    flexGrow: 1,
    maxWidth: breakpoints.lg,
    paddingBottom: 16,
    paddingHorizontal: 16,
    width: '100%',
  },
})
