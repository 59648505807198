import React from 'react'
import { StyleSheet } from 'react-native'

import { Box, Center, Spacer, Text } from '~components'

export const DataFromBeScreen_EXAMPLE = () => {
  return (
    <Box style={styles.container}>
      <Center>
        <Text fontSize="xl">THIS IS EXAMPLE SCREEN</Text>
        <Text fontSize="xl">which contains data from backend</Text>
        <Spacer y="1" />
      </Center>
    </Box>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
