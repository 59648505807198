import { Dimensions, StyleSheet } from 'react-native'

import { Box } from '../atoms/Box'
import { Center } from '../atoms/Center'

import { radii } from '~constants'

const { width: windowWidth } = Dimensions.get('window')
const BOX_SIZE = windowWidth - 100

export const QrCodeFrame = () => {
  return (
    <Center {...StyleSheet.absoluteFillObject}>
      <Box bg="transparent" height={BOX_SIZE} position="relative" width={BOX_SIZE}>
        <Box
          borderLeftColor="primary.500"
          borderLeftWidth={4}
          borderTopColor="primary.500"
          borderTopLeftRadius={radii.lg}
          borderTopWidth={4}
          height="40px"
          left={0}
          position="absolute"
          top={0}
          width="40px"
        />
        <Box
          bg="transparent"
          borderRightColor="primary.500"
          borderRightWidth={4}
          borderTopColor="primary.500"
          borderTopEndRadius={radii.lg}
          borderTopWidth={4}
          height="40px"
          position={'absolute'}
          right={0}
          top={0}
          width="40px"
        />
        <Box
          borderBottomColor="primary.500"
          borderBottomLeftRadius={radii.lg}
          borderBottomWidth={4}
          borderLeftColor="primary.500"
          borderLeftWidth={4}
          bottom={0}
          height="40px"
          left={0}
          position="absolute"
          width="40px"
        />
        <Box
          borderBottomColor="primary.500"
          borderBottomEndRadius={radii.lg}
          borderBottomWidth={4}
          borderRightColor="primary.500"
          borderRightWidth={4}
          bottom={0}
          height="40px"
          position={'absolute'}
          right={0}
          width="40px"
        />
      </Box>
    </Center>
  )
}
