import { PropsWithChildren, forwardRef } from 'react'
import { StyleSheet } from 'react-native'
import { KeyboardAwareScrollView as RNKeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { SafeAreaView } from 'react-native-safe-area-context'

import { KeyboardAwareScrollView } from '../KeyboardAwareScrollView'
import { Background } from '../atoms/Background'
import { Box } from '../atoms/Box'
import { UnAuthorizeLogo } from '../atoms/UnAuthorizeLogo'
import { UnauthorizedTopNav } from '../organisms/UnauthorizedTopNav'

import { breakpoints, BACKGROUND_WEB_UNAUTHORIZE, theme } from '~constants'
import { useWeb } from '~hooks'

type UnAuthorizeWrapperProps = {
  showTopNavBar?: boolean
  stretchWidth?: boolean
}

export const UnAuthorizeWrapper = forwardRef<
  RNKeyboardAwareScrollView,
  PropsWithChildren<UnAuthorizeWrapperProps>
>(({ children, showTopNavBar = true, stretchWidth = false }, ref) => {
  const { shouldApplyDesktopStyles } = useWeb()

  return (
    <SafeAreaView edges={['top']} style={styles.safeArea}>
      <Background
        contentFit={shouldApplyDesktopStyles ? 'contain' : 'cover'}
        webBackground={BACKGROUND_WEB_UNAUTHORIZE}
      >
        {showTopNavBar && shouldApplyDesktopStyles && <UnauthorizedTopNav />}
        <KeyboardAwareScrollView
          contentContainerStyle={styles.contentContainerStyle}
          ref={ref}
          showsVerticalScrollIndicator={false}
        >
          <UnAuthorizeLogo showAlways={!showTopNavBar} />
          <Box
            alignItems={stretchWidth ? 'stretch' : 'center'}
            flexGrow={1}
            justifyContent="center"
            py={16}
            w="full"
          >
            <Box maxW={stretchWidth ? undefined : 800} w="full">
              {children}
            </Box>
          </Box>
        </KeyboardAwareScrollView>
      </Background>
    </SafeAreaView>
  )
})

const styles = StyleSheet.create({
  contentContainerStyle: {
    alignSelf: 'center',
    backgroundColor: theme.light.colors.transparent,
    maxWidth: breakpoints.lg,
    paddingHorizontal: 16,
    width: '100%',
  },
  safeArea: { backgroundColor: theme.light.colors.brand_dark, flex: 1 },
})
