import { useNavigation } from '@react-navigation/native'
import { isError } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useMeQuery, useUpdateUserDataMutation } from '~query-hooks'
import { UpdateContactAddressFormValues } from '~types/authForms'
import { hapticImpact } from '~utils'

export const defaultContactAddressValues: UpdateContactAddressFormValues = {
  country: '',
  city: '',
  postalCode: '',
  street: '',
  buildingNumber: '',
  apartment: '',
  floor: '',
  sameAsRegistration: false,
}

export const useContactAddressForm = () => {
  const { t } = useTranslation()
  const { goBack } = useNavigation()

  const { isLoadingUserData, userData } = useMeQuery()
  const { updateUserDataMutation } = useUpdateUserDataMutation({
    successMessage: t('toast.success.contact_address_updated'),
  })

  const [areDefaultValuesSet, setAreDefaultValuesSet] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError: setFormError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<UpdateContactAddressFormValues>({
    mode: 'onTouched',
    defaultValues: defaultContactAddressValues,
  })

  useEffect(() => {
    if (!isLoadingUserData && userData?.contactAddress && !areDefaultValuesSet) {
      reset(userData?.contactAddress)
      setAreDefaultValuesSet(true)
    }
  }, [areDefaultValuesSet, isLoadingUserData, reset, userData?.contactAddress])

  const onSubmit = async (contactAddress: UpdateContactAddressFormValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      if (!contactAddress?.sameAsRegistration) {
        await updateUserDataMutation({ contactAddress })
      } else {
        const updateData = { ...contactAddress, ...userData?.registrationAddress }
        await updateUserDataMutation({ contactAddress: updateData })
      }
      goBack()
    } catch (e) {
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    control,
    error,
    errors,
    handleSubmit,
    isSubmitting,
    setFocus,
    setFormError,
    setIsSubmitting,
    setValue,
    submit: handleSubmit(onSubmit),
    trigger,
    watch,
  }
}
