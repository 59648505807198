import { TFunction } from 'i18next'

export const generateGenderItems = (t: TFunction<'translation', undefined>) => [
  { label: t('form.select.gender.male'), value: 'Male' },
  { label: t('form.select.gender.female'), value: 'Female' },
  { label: t('form.select.gender.other'), value: 'Other' },
  { label: t('form.select.gender.decide_not_to_say'), value: 'Decide not to say' },
]

export const generateTitleItems = (t: TFunction<'translation', undefined>) => [
  { label: t('form.select.title.prof'), value: 'Prof.' },
  { label: t('form.select.title.phd'), value: 'Ph.D.' },
  { label: t('form.select.title.master'), value: 'M.A / M.S' },
  { label: t('form.select.title.bachelor'), value: 'B.A. / B.S.' },
  { label: t('form.select.title.none'), value: 'None' },
]
export const generateSalutationItems = (t: TFunction<'translation', undefined>) => [
  { label: t('form.select.salutation.mr'), value: 'Mr.' },
  { label: t('form.select.salutation.ms'), value: 'Ms.' },
  { label: t('form.select.salutation.mx'), value: 'Mx.' },
]

export const generateAccountTypeItems = (t: TFunction<'translation', undefined>) => [
  { label: t('form.select.account_type.digital'), value: 'digital' },
  { label: t('form.select.account_type.traditional'), value: 'traditional' },
]
