import { apiClient } from '~services/api'

export type DeleteAccountRequestData = {
  password: string
}

export type DeleteAccountReturnData = {
  message: string
}

export const deleteAccountRequest = async ({ password }: DeleteAccountRequestData) => {
  return await apiClient.delete<DeleteAccountRequestData, DeleteAccountReturnData>('/user/me', {
    data: {
      password,
    },
  })
}
