import CountryPicker, { Country, CountryCode } from 'react-native-country-picker-modal'
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar'
import { string } from 'yup'

import { Box, Button, ControlledField, LightScreenWrapper, Spacer } from '~components'
import { COUNTRIES, MIN_BIRTH_DATE, REGEX, isWeb } from '~constants'
import {
  useCallback,
  useEffect,
  useMemo,
  usePersonalDataForm,
  useRef,
  useState,
  useTranslation,
  useWeb,
} from '~hooks'
import {
  generateGenderItems,
  generateSalutationItems,
  generateTitleItems,
  getInitialCountryCode,
  hideKeyboard,
} from '~utils'

export const PersonalDataScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { shouldApplyDesktopStyles } = useWeb()

  const datePickerRef = useRef<{ openDatePicker(): void } | null>(null)

  const genderItems = useMemo(() => generateGenderItems(t), [t])
  const titleItems = useMemo(() => generateTitleItems(t), [t])
  const salutationItems = useMemo(() => generateSalutationItems(t), [t])

  const {
    areDefaultValuesSet,
    control,
    errors,
    getValues,
    isSubmitting,
    setFocus,
    setValue,
    submit,
  } = usePersonalDataForm()

  const setFocusFirstName = useCallback(() => {
    !isWeb && setFocus('firstName')
  }, [setFocus])

  const setFocusLastName = useCallback(() => setFocus('lastName'), [setFocus])

  const setFocusBirthData = useCallback(() => {
    datePickerRef.current?.openDatePicker()
    hideKeyboard()
  }, [])

  const setFocusBirthCountry = useCallback(() => setFocus('birthCountry'), [setFocus])

  useEffect(() => {
    if (areDefaultValuesSet) {
      const initialPhonePrefix = getValues('phoneNumberPrefix')
      getInitialCountryCode(initialPhonePrefix).then((initialCountryCode) => {
        setCountryCode(initialCountryCode)
      })
    }
  }, [areDefaultValuesSet, getValues])

  // phone validation
  const [countryCode, setCountryCode] = useState<CountryCode>('DE')
  const phoneSchema = string().phone(countryCode, true)
  const onSelectPhoneCountry = useCallback(
    (country: Country) => {
      setValue('phoneNumberPrefix', country.callingCode?.[0])
      setCountryCode(country.cca2)
    },
    [setValue]
  )

  return (
    <LightScreenWrapper
      iconName="account-pin-circle-line"
      title={t('personal_data_screen.personal_data')}
    >
      <Box flex={1} flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <Box flex={1} mb={2}>
          <ControlledField.Select
            {...{ control, errors }}
            items={titleItems}
            label={t('form.labels.title')}
            maxSelectedItems={1}
            name="title"
            placeholder={t('form.placeholders.title')}
          />
        </Box>
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <Box flex={1} mb={2}>
          <ControlledField.Select
            {...{ control, errors }}
            items={salutationItems}
            label={t('form.labels.salutation')}
            maxSelectedItems={1}
            name="salutation"
            placeholder={t('form.placeholders.salutation')}
          />
        </Box>
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <Box flex={1} mb={2}>
          <ControlledField.Select
            {...{ control, errors }}
            items={genderItems}
            label={t('form.labels.gender')}
            maxSelectedItems={1}
            name="gender"
            onPick={setFocusFirstName}
            placeholder={t('form.placeholders.gender')}
          />
        </Box>
      </Box>
      <ControlledField.Input
        {...{ control, errors }}
        autoComplete="name"
        autoCapitalize="none"
        isRequired
        label={t('form.labels.first_name')}
        mb={4}
        name="firstName"
        onSubmitEditing={setFocusLastName}
        placeholder={t('form.placeholders.first_name')}
        returnKeyType="next"
        rules={{
          required: t('form.errors.required'),
          pattern: {
            value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
            message: t('form.errors.special_characters', { max_length: 64 }),
          },
        }}
      />
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        isRequired
        label={t('form.labels.last_name')}
        mb={4}
        name="lastName"
        onSubmitEditing={setFocusBirthData}
        placeholder={t('form.placeholders.last_name')}
        returnKeyType="next"
        rules={{
          required: t('form.errors.required'),
          pattern: {
            value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
            message: t('form.errors.special_characters', { max_length: 64 }),
          },
        }}
      />
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <Box flex={shouldApplyDesktopStyles ? 1 : undefined}>
          <ControlledField.DatePicker
            {...{ control, errors }}
            isRequired
            name="birthDate"
            ref={datePickerRef}
            rules={{
              required: t('form.errors.required'),
              validate: {
                isAdult: (value: CalendarDate | string) => {
                  if (value) {
                    const currentDate =
                      typeof value === 'string' ? new Date(value).getTime() : value?.getTime()
                    if (currentDate > MIN_BIRTH_DATE?.getTime()) {
                      return t('form.errors.minimum_18_years_old')
                    }
                  }
                  return undefined
                },
              },
            }}
          />
        </Box>
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          isRequired
          label={t('form.labels.birth_city')}
          mb={2}
          name="birthCity"
          onSubmitEditing={setFocusBirthCountry}
          placeholder={t('form.placeholders.birth_city')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
              message: t('form.errors.special_characters', { max_length: 64 }),
            },
          }}
        />
      </Box>
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <Box flex={shouldApplyDesktopStyles ? 1 : undefined} mb={2}>
          <ControlledField.Select
            {...{ control, errors }}
            items={COUNTRIES}
            label={t('form.labels.birth_country')}
            maxSelectedItems={1}
            name="birthCountry"
            placeholder={t('form.placeholders.birth_country')}
          />
        </Box>
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <Box flex={shouldApplyDesktopStyles ? 1 : undefined} mb={2}>
          <ControlledField.Select
            {...{ control, errors }}
            items={COUNTRIES}
            label={t('form.labels.nationality')}
            maxSelectedItems={1}
            name="nationality"
            placeholder={t('form.placeholders.nationality')}
          />
        </Box>
      </Box>
      <ControlledField.Input
        {...{ control, errors }}
        isRequired
        keyboardType="phone-pad"
        label={t('form.labels.phone_number')}
        leftElement={
          <Box height="100%" justifyContent="center" pl="2">
            <CountryPicker
              {...{ countryCode }}
              onSelect={onSelectPhoneCountry}
              withCallingCode
              withCallingCodeButton
              withCloseButton
              withEmoji
            />
          </Box>
        }
        name="phoneNumber"
        onSubmitEditing={hideKeyboard}
        placeholder={t('form.placeholders.phone_number')}
        rules={{
          required: t('form.errors.required'),
          validate: {
            phone: async (value) => {
              try {
                phoneSchema.validateSync(value)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
              } catch (e: unknown) {
                return t('form.errors.phone_number')
              }
            },
          },
        }}
        returnKeyType="next"
        type="text"
      />
      <Button disabled={isSubmitting} loading={isSubmitting} mt={4} onPress={submit}>
        {t('common.save_changes')}
      </Button>
    </LightScreenWrapper>
  )
}
