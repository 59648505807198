import { createRef } from 'react'

import { refreshTokenRequest } from './requests/refreshTokenRequest'

import { ASYNC_STORAGE_KEYS } from '~constants'
import { decodeAccessToken, secureStore, wait } from '~utils'

const { USER_TOKEN, USER_REFRESH_TOKEN } = ASYNC_STORAGE_KEYS

// TOKEN
export function setToken(token: string) {
  return secureStore.setItem(USER_TOKEN, token)
}

export async function getToken(): Promise<string | undefined> {
  const token = await secureStore.getItem(USER_TOKEN)

  if (!token) {
    return
  }

  return await refreshTokenIfNeeded(token)
}

export async function deleteToken() {
  const token = await secureStore.getItem(USER_TOKEN)

  if (!token) return

  return secureStore.removeItem(USER_TOKEN)
}

export function setRefreshToken(refreshToken: string) {
  return secureStore.setItem(USER_REFRESH_TOKEN, refreshToken)
}

// REFRESH TOKEN
export async function getRefreshToken(): Promise<string | undefined> {
  const refreshToken = await secureStore.getItem(USER_REFRESH_TOKEN)

  if (!refreshToken) {
    return
  }

  return refreshToken
}

export async function deleteRefreshToken() {
  const refreshToken = await secureStore.getItem(USER_REFRESH_TOKEN)

  if (!refreshToken) return

  return secureStore.removeItem(USER_REFRESH_TOKEN)
}

// REFRESH TOKEN LOGIC
export async function getTokenInfo(): Promise<string | undefined> {
  const token = await secureStore.getItem(USER_TOKEN)

  if (token) {
    const newToken = await refreshTokenIfNeeded(token)
    return newToken
  }
}

const isRefreshing = createRef<boolean>()
// @ts-expect-error: wrong interface of react createRef()
isRefreshing.current = false

export const refreshTokenIfNeeded = async (token: string): Promise<string> => {
  try {
    const decodedToken = decodeAccessToken(token)
    const expirationTime = decodedToken?.exp ?? 0

    if (!expirationTime) return token

    const tenMinutesForwardUnix = (Date.now() + 1000 * 4 * 10) / 1000
    const shouldRefreshToken = expirationTime < Math.round(tenMinutesForwardUnix)

    // If token is still refreshing  app should try again to get token after 0.5 second
    if (isRefreshing.current) {
      await wait(500)
      const savedToken = await getTokenInfo()
      if (savedToken) {
        return savedToken
      }
    }

    // If token is expired and app not refreshing token -> refresh token
    if (shouldRefreshToken && !isRefreshing.current) {
      // @ts-expect-error: wrong interface of react createRef()
      isRefreshing.current = true
      const refreshToken = await getRefreshToken()
      if (refreshToken) {
        const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
          await refreshTokenRequest({ refreshToken })
        await setToken(newAccessToken)
        await setRefreshToken(newRefreshToken)

        // @ts-expect-error: wrong interface of react createRef()
        isRefreshing.current = false
        return newAccessToken
      }
    }

    // @ts-expect-error: wrong interface of react createRef()
    isRefreshing.current = false

    // If token is not expired app is returning token that was provided in params
    return token
  } catch {
    // @ts-expect-error: wrong interface of react createRef()
    isRefreshing.current = false
    return token
  }
}
