import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs'
import { StackNavigationOptions } from '@react-navigation/stack'
import { t } from 'i18next'

import { Header } from '../../components'
import {
  BottomTabsScreens,
  ExamplesStackScreens,
  HomeStackScreens,
  WalletStackScreens,
} from './enums'

import { isWeb } from '~constants'
import {
  ColorsScreen,
  ComponentsScreen,
  DataFromBeScreen_EXAMPLE,
  DetailsScreen,
  ExamplesScreen,
  HomeScreen,
  TestFormScreen,
  TypographyScreen,
  WalletScreen,
} from '~screens'
import { IconNames } from '~types/icon'

type ScreenType<T extends string> = {
  name: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: { navigation: any; route: any }) => JSX.Element
  options?: StackNavigationOptions
  deeplink: string
}

// WalletStack_SCREENS_START
const walletStackScreensData: ScreenType<keyof typeof WalletStackScreens>[] = [
  {
    name: WalletStackScreens.Wallet,
    component: WalletScreen,
    // TODO: Add translation here
    options: { title: 'Wallet', header: (props) => <Header {...props} />, headerShown: !isWeb },
    deeplink: '/wallet',
  },
  // WalletStack_SCREENS_END
]
const examplesStackScreensData: ScreenType<keyof typeof ExamplesStackScreens>[] = [
  {
    name: ExamplesStackScreens.Examples,
    component: ExamplesScreen,
    options: { title: t('navigation.screen_titles.examples') },
    deeplink: '/examples',
  },
  {
    name: ExamplesStackScreens.Typography,
    component: TypographyScreen,
    options: { title: t('navigation.screen_titles.typography') },
    deeplink: '/typography',
  },
  {
    name: ExamplesStackScreens.Colors,
    component: ColorsScreen,
    options: { title: t('navigation.screen_titles.colors') },
    deeplink: '/colors',
  },
  {
    name: ExamplesStackScreens.Components,
    component: ComponentsScreen,
    options: { title: t('navigation.screen_titles.components') },
    deeplink: '/components',
  },
  {
    name: ExamplesStackScreens.DataFromBeScreen_EXAMPLE,
    component: DataFromBeScreen_EXAMPLE,
    options: { title: t('navigation.screen_titles.data_from_be_screen_example') },
    deeplink: '/data-example',
  },
  {
    name: ExamplesStackScreens.TestForm,
    component: TestFormScreen,
    // TODO: Add translation here
    options: { title: 'TestForm' },
    deeplink: '/testform',
  },
  // ExamplesStack_SCREENS_END
]
// HomeStack_SCREENS_START
const homeStackScreensData: ScreenType<keyof typeof HomeStackScreens>[] = [
  {
    name: HomeStackScreens.Home,
    component: HomeScreen,
    options: {
      title: t('navigation.screen_titles.home'),
      header: (props) => <Header {...props} />,
      headerShown: !isWeb,
    },
    deeplink: '/',
  },
  {
    name: HomeStackScreens.Details,
    component: DetailsScreen,
    options: { title: t('navigation.screen_titles.details') },
    deeplink: '/details',
  },
  // HomeStack_SCREENS_END
]

type BottomTabIcons = {
  active: IconNames
  inactive: IconNames
}

type BottomTabsScreenData = {
  icons: BottomTabIcons
  name: keyof typeof BottomTabsScreens
  options: BottomTabNavigationOptions
  screens: (
    | ScreenType<keyof typeof HomeStackScreens>
    | ScreenType<keyof typeof ExamplesStackScreens>
    | ScreenType<keyof typeof WalletStackScreens>
  )[]
}

// Extra bottom tabs in DEV mode START
const devBottomTabsScreenData: BottomTabsScreenData[] = __DEV__
  ? [
      {
        icons: {
          active: 'account-box-fill',
          inactive: 'account-box-line',
        },
        name: BottomTabsScreens.ExamplesStack,
        screens: examplesStackScreensData,
        options: { title: 'Development' },
      },
    ]
  : []
// Extra bottom tabs in DEV mode END

// BottomTab_SCREENS_START
export const bottomTabsScreensData: BottomTabsScreenData[] = [
  {
    icons: {
      active: 'home-6-line',
      inactive: 'home-6-line',
    },
    name: BottomTabsScreens.HomeStack,
    screens: homeStackScreensData,
    options: { title: t('navigation.screen_titles.dashboard') },
  },
  {
    icons: {
      active: 'wallet-line',
      inactive: 'wallet-line',
    },
    name: BottomTabsScreens.WalletStack,
    screens: walletStackScreensData,
    options: { title: t('navigation.screen_titles.wallet') },
  },
  ...devBottomTabsScreenData,
]
// BottomTab_SCREENS_END

export const webScreensData = bottomTabsScreensData.map((tab) => tab?.screens ?? []).flat()
