import { useCallback, useState } from 'react'
import { Modal } from 'react-native'

import { DeleteAccountModalStep0 } from './DeleteAccountModalStep0'
import { DeleteAccountModalStep1 } from './DeleteAccountModalStep1'

import { Box, Center } from '~components'
import { breakpoints, radii } from '~constants'

type DeleteAccountModalProps = {
  visible: boolean
  onClose(): void
}

export const DeleteAccountModal = ({ visible, onClose }: DeleteAccountModalProps) => {
  const [showFormStep, setShowFormStep] = useState<boolean>(false)

  const handleClose = useCallback(() => {
    setShowFormStep(false)
    onClose()
  }, [onClose])

  const onContinue = useCallback(() => {
    setShowFormStep(true)
  }, [])

  return (
    <Modal animationType="fade" onRequestClose={handleClose} transparent visible={visible}>
      <Center bg="brand_dark" bgOpacity={0.8} h="full" px={4} w="full">
        <Box
          alignItems={showFormStep ? 'flex-start' : 'center'}
          bg="white"
          borderRadius={radii.md}
          gap={6}
          maxWidth={breakpoints.sm}
          p={4}
          width="full"
          zIndex={10}
        >
          {!showFormStep ? (
            <DeleteAccountModalStep0
              onCancel={handleClose}
              onContinue={onContinue}
              visible={visible}
            />
          ) : (
            <DeleteAccountModalStep1 onCancel={handleClose} />
          )}
        </Box>
      </Center>
    </Modal>
  )
}
