import {
  getPermissionsAsync,
  PermissionStatus,
  requestPermissionsAsync,
} from 'expo-barcode-scanner'
import { useCallback, useState } from 'react'
import { Linking, StyleSheet } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { Background, Receive, SegmentedControl, Send, Spacer } from '~components'
import { breakpoints } from '~constants'
import { useTranslation } from '~hooks'
import { alert } from '~utils'

const navigateToSettings = async () => {
  try {
    await Linking.openSettings()
  } catch (e) {
    console.log('Could not navigate to device settings', e)
  }
}

export const TransactionsScreen = ({ navigation, route }: TransactionsScreenProps): JSX.Element => {
  const { navigate } = navigation
  const { params } = route

  const { t } = useTranslation()

  const [selectedSegment, setSelectedSegment] = useState<number>(Number(params?.index) ?? 0)

  const goToQRScanner = useCallback(async () => {
    try {
      const response = await getPermissionsAsync()
      const { canAskAgain, status } = response
      if (status === PermissionStatus.GRANTED) {
        navigate('QRScanner')
      } else if (canAskAgain) {
        const { status } = await requestPermissionsAsync()
        if (status === PermissionStatus.GRANTED) {
          navigate('QRScanner')
        }
      } else if (status === PermissionStatus.DENIED) {
        alert(
          t('transactions_screen.camera_permission_alert_title'),
          t('transactions_screen.camera_permission_alert_message'),
          [
            { text: t('common.cancel') },
            { text: t('transactions_screen.go_to_settings'), onPress: navigateToSettings },
          ]
        )
      }
    } catch (e) {
      console.log('ERROR', e)
    }
  }, [navigate, t])

  return (
    <Background>
      <KeyboardAwareScrollView
        contentContainerStyle={styles.contentContainerStyle}
        showsVerticalScrollIndicator={false}
      >
        <Spacer y="6" />
        <SegmentedControl
          disabledIndices={[1]}
          {...{ selectedSegment, setSelectedSegment }}
          values={[t('common.send'), t('common.exchange'), t('common.receive')]}
        />
        <Spacer y="6" />
        {selectedSegment === 0 ? <Send {...{ goToQRScanner }} /> : null}
        {selectedSegment === 2 ? <Receive /> : null}
      </KeyboardAwareScrollView>
    </Background>
  )
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    alignSelf: 'center',
    flexGrow: 1,
    maxWidth: breakpoints['desktop'],
    paddingBottom: 16,
    paddingHorizontal: 16,
    width: '100%',
  },
})
