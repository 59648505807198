import { forwardRef, useCallback, useMemo } from 'react'
import { View, Pressable, StyleSheet } from 'react-native'

import { Box } from './Box'
import { Icon } from './Icon'
import { Text } from './Text'
import { CheckboxProps } from './types'

import { useTranslation } from '~hooks'

const hitSlop = {
  top: 5,
  left: 5,
  bottom: 5,
}

export const Checkbox = forwardRef<View, CheckboxProps>(
  (
    {
      borderWidth = 2,
      checkboxes,
      checkboxText,
      disabled,
      extraText,
      isChecked,
      isError,
      isRequired,
      onChange,
      showExtraText = true,
      size = 22,
      value,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()
    const handleValueChange = useCallback(() => {
      return checkboxes ? onChange(value) : onChange(!value)
    }, [onChange, value, checkboxes])

    const iconColor = useMemo(() => {
      if (disabled && value) {
        return 'gray.500'
      }

      return 'muted.50'
    }, [disabled, value])

    const bgColor = useMemo(() => {
      if (!value) {
        return 'white'
      }
      if (disabled) {
        return 'lightGray'
      }

      return 'primary.500'
    }, [disabled, value])

    const borderColor = useMemo(
      () => (isError ? 'red.500' : disabled ? 'gray.500' : !value ? 'muted.400' : 'primary.500'),
      [isError, disabled, value]
    )
    return (
      <Pressable
        ref={ref}
        onPress={handleValueChange}
        style={styles.mainContainer}
        {...{ disabled, hitSlop }}
      >
        <Box
          bg={bgColor}
          borderColor={borderColor}
          borderRadius={4}
          borderWidth={borderWidth}
          height={size}
          mr={2}
          width={size}
          {...props}
        >
          {isChecked ? (
            <Icon color={iconColor} name="check-line" size={size - 2 * borderWidth} />
          ) : null}
        </Box>
        <Text selectable={false} style={typeof checkboxText === 'object' ? styles.text : {}}>
          {checkboxText}
          {showExtraText &&
            (extraText ? (
              <Text color="text.400">{` (${extraText})`}</Text>
            ) : isRequired ? (
              <Text color={isError ? 'red.600' : 'text.400'}>{` (${t('common.required')})`}</Text>
            ) : (
              <Text color="text.400">{` (${t('common.optional')})`}</Text>
            ))}
        </Text>
      </Pressable>
    )
  }
)

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    flex: 1,
  },
})
