import { useCallback, useEffect } from 'react'

import { VerificationModal } from '../components/molecules/VerificationModal'
import { useToggle } from './useToggle'

import { KYC_STATUS } from '~enums'
import {
  useGetVideoVerificationStatusQuery,
  useMeQuery,
  useUpdateUserDataMutation,
} from '~query-hooks'

export const useKycVerificationModal = () => {
  const { userData } = useMeQuery()
  const { videoVerificationStatus } = useGetVideoVerificationStatusQuery({ id: userData?.identId })
  const { updateUserDataMutation } = useUpdateUserDataMutation({ successMessage: undefined })

  const [showKycModal, setShowKycModal] = useToggle(false)

  const onVideoVerificationStatusChange = useCallback(
    async ({ videoVerificationStatus }: { videoVerificationStatus?: KYC_STATUS }) => {
      try {
        if (videoVerificationStatus) {
          await updateUserDataMutation({ lastShownKycModal: videoVerificationStatus })
        }
      } catch (e) {
        console.log('Could not update shown modal status on BE', e)
      }
    },
    [updateUserDataMutation]
  )

  useEffect(() => {
    if (
      !!videoVerificationStatus &&
      videoVerificationStatus !== KYC_STATUS.NOT_STARTED &&
      videoVerificationStatus !== userData?.lastShownKycModal &&
      !showKycModal
    ) {
      onVideoVerificationStatusChange({ videoVerificationStatus })
      setShowKycModal()
    }
  }, [
    onVideoVerificationStatusChange,
    setShowKycModal,
    showKycModal,
    userData,
    userData?.lastShownKycModal,
    videoVerificationStatus,
  ])

  const kycModalComponent =
    userData?.lastShownKycModal === KYC_STATUS.SUCCESS ? null : (
      <VerificationModal
        visible={showKycModal}
        onClose={setShowKycModal}
        status={videoVerificationStatus}
      />
    )
  return { kycModalComponent }
}
