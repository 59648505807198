import { apiClient } from '~services/api'
import { TOKEN_SHORTNAME_TYPE } from '~types/assets'

export type MakeTransactionFormValues = {
  to: string
  amount: string
  shortName: TOKEN_SHORTNAME_TYPE | undefined
}

type MakeTransactionResponse = {
  requestId: string
}

export const makeTransactionRequest = async (data: MakeTransactionFormValues) => {
  const response = await apiClient.post<MakeTransactionResponse, MakeTransactionResponse>(
    '/tangany/make-transaction',
    data
  )

  return response
}
