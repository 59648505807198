import { Box, Button, Spacer, Text, UnAuthorizeWrapper } from '~components'
import { useCallback, useTranslation } from '~hooks'
import { useResetPasswordMutation } from '~query-hooks'
import { getToast } from '~utils'

export const ResetPasswordInfoScreen = ({
  navigation,
  route,
}: ResetPasswordInfoScreenProps): JSX.Element => {
  const { navigate } = navigation
  const { params } = route
  const { t } = useTranslation()

  const { showSuccessToast } = getToast()
  const { resetPasswordMutation } = useResetPasswordMutation()

  const navigateToLogin = useCallback(() => navigate('SignIn'), [navigate])

  const resetPassword = useCallback(async () => {
    try {
      await resetPasswordMutation({ email: params?.email })
      showSuccessToast(t('reset_password_info_screen.send_link_success'))
    } catch (e) {
      console.log(e)
    }
  }, [resetPasswordMutation, params?.email, showSuccessToast, t])

  return (
    <UnAuthorizeWrapper showTopNavBar={false}>
      <Spacer y="16" />
      <Box bg="white" borderRadius={8} p={4}>
        <Text.H4>{t('reset_password_screen.reset_password')}</Text.H4>
        <Spacer y="6" />
        <Text.Body>
          {t('reset_password_info_screen.link_have_been_send', { email: params?.email })}
        </Text.Body>
        <Spacer y="6" />
        <Text.Body>
          {t('reset_password_info_screen.check_spam') + ' '}
          <Text.Body color="primary.500" onPress={resetPassword}>
            {t('reset_password_info_screen.send_again')}
          </Text.Body>
        </Text.Body>
        <Spacer y="6" />
        <Text.Body>{t('reset_password_info_screen.link_is_valid')}</Text.Body>
        <Spacer y="6" />
        <Button onPress={navigateToLogin} width="100%">
          {t('reset_password_info_screen.return_to_login')}
        </Button>
      </Box>
    </UnAuthorizeWrapper>
  )
}
