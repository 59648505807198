import * as Application from 'expo-application'
import Constants from 'expo-constants'

import { Spacer, SettingsItem, Text, Row, LightScreenWrapper } from '~components'
import { useTranslation } from '~hooks'
import { noop, openPrivacyPolicy, openTermAndConditions } from '~utils'

const APP_VERSION = Application?.nativeApplicationVersion ?? Constants?.expoConfig?.version

export const AboutAppScreen = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <LightScreenWrapper iconName="information-line" title={t('settings_screen.about_app')}>
      <Row alignItems="center" justifyContent="space-between" pb={4}>
        <Text.Subtitle>{t('about_app_screen.app_version')}</Text.Subtitle>
        <Text.Subtitle>{APP_VERSION}</Text.Subtitle>
      </Row>
      <SettingsItem
        onPress={openTermAndConditions}
        title={t('about_app_screen.terms_of_service')}
      />
      <Spacer y="2" />
      <SettingsItem onPress={openPrivacyPolicy} title={t('about_app_screen.privacy_policy')} />
      <Spacer y="2" />
      <SettingsItem onPress={noop} title={t('about_app_screen.license')} />
    </LightScreenWrapper>
  )
}
