import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { ENV, isWeb } from '~constants'
import { alert } from '~utils'

const showPasswordPrompt = ({
  setShowContent,
}: {
  setShowContent: Dispatch<SetStateAction<boolean>>
}) => {
  const password = window.prompt('Enter password')
  if (password === ENV.WEB_PASSWORD) {
    setShowContent(true)
  } else {
    alert('WRONG PASSWORD', undefined, [
      {
        style: 'default',
        onPress: () => showPasswordPrompt({ setShowContent }),
      },
      { style: 'cancel', onPress: () => showPasswordPrompt({ setShowContent }) },
    ])
  }
}

export const useSecureWebContent = () => {
  const [showContent, setShowContent] = useState(
    ENV.ENVIRONMENT === 'production' || !isWeb || __DEV__
  )

  useEffect(() => {
    if (!showContent) {
      showPasswordPrompt({ setShowContent })
    }
  }, [showContent])

  return { showContent }
}
