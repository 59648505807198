import React, { useState, FC, useCallback, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, LayoutChangeEvent } from 'react-native'
import Animated, { useSharedValue, withTiming, useAnimatedStyle } from 'react-native-reanimated'

import { Box, Text, Pressable } from '../atoms'

import { radii, theme } from '~constants'

type SegmentedControlProps = {
  disabledIndices?: number[]
  selectedSegment: number
  setSelectedSegment: (index: number) => void
  values: string[]
}

export const SegmentedControl: FC<SegmentedControlProps> = ({
  selectedSegment,
  setSelectedSegment,
  values,
  disabledIndices = [],
}) => {
  const { t } = useTranslation()
  const [segmentedControlWidth, setSegmentedControlWidth] = useState(0)

  const activeIndicatorPosition = useSharedValue(0)

  const segmentedWidthRef = useRef(0)

  const segmentWidth = useMemo(
    () => segmentedControlWidth / values.length,
    [segmentedControlWidth, values.length]
  )

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setSegmentedControlWidth(event.nativeEvent.layout.width)
  }, [])

  const handleSegmentChange = useCallback(
    (index: number) => {
      setSelectedSegment(index)
      activeIndicatorPosition.value = withTiming(index * segmentWidth)
    },
    [activeIndicatorPosition, segmentWidth, setSelectedSegment]
  )

  useEffect(() => {
    if (segmentedWidthRef.current !== segmentedControlWidth) {
      activeIndicatorPosition.value = selectedSegment * segmentWidth
      segmentedWidthRef.current = segmentedControlWidth
    }
  }, [activeIndicatorPosition, segmentWidth, segmentedControlWidth, selectedSegment, values.length])

  const activeIndicatorStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: activeIndicatorPosition.value }],
    }
  })
  return (
    <View>
      <View style={styles.wrapper} onLayout={onLayout}>
        {values.map((value, index) => {
          const disabled = disabledIndices.includes(index)
          return (
            <Pressable
              alignItems="center"
              bg={disabled ? 'primary.200' : 'transparent'}
              disabled={disabled}
              height="100%"
              justifyContent="center"
              key={`segment-${index}`}
              // eslint-disable-next-line react/jsx-no-bind
              onPress={() => handleSegmentChange(index)}
              w={segmentWidth}
              zIndex={disabled ? undefined : 200}
            >
              <Text.Body
                color={
                  disabled ? 'primary.400' : selectedSegment === index ? 'text.50' : 'primary.900'
                }
                fontWeight="medium"
              >
                {value}
              </Text.Body>
            </Pressable>
          )
        })}
        <Animated.View
          style={[
            styles.indicator,
            activeIndicatorStyle,
            {
              width: segmentWidth,
            },
          ]}
        />
      </View>
      {disabledIndices?.map((index) => (
        <Box
          alignItems="center"
          flexWrap="nowrap"
          key={`disabled-${index}`}
          left={index * segmentWidth}
          position="absolute"
          top={38}
          width={segmentWidth}
        >
          <Box bg="secondary.500" borderRadius={2} height={5} justifyContent="center" px={2}>
            <Text.Small color="white" flexWrap="nowrap" noOfLines={1} selectable={false}>
              {t('common.coming_soon')}
            </Text.Small>
          </Box>
        </Box>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  indicator: {
    backgroundColor: theme.light.colors.primary[500],
    height: '100%',
    position: 'absolute',
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: theme.light.colors.primary[100],
    borderRadius: radii.lg,
    flexDirection: 'row',
    height: 48,
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
})
