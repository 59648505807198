import { Box, Button, Center, ControlledField, Text, UnAuthorizeWrapper } from '~components'
import { REGEX } from '~constants'
import { useCallback, useTranslation, useConfirmResetPasswordPasswordForm } from '~hooks'

export const ConfirmResetPasswordScreen = (props: ConfirmResetPasswordScreenProps): JSX.Element => {
  const {
    navigation: { navigate },
    route: {
      params: { oobCode },
    },
  } = props
  const { t } = useTranslation()

  const { clearFormErrors, control, errors, isSubmitting, setFocus, setFormError, submit } =
    useConfirmResetPasswordPasswordForm({ oobCode })

  const setFocusRepeatNewPassword = useCallback(() => {
    setFocus('repeatNewPassword')
  }, [setFocus])

  const navigateToLogin = useCallback(() => {
    navigate('SignIn')
  }, [navigate])

  return (
    <UnAuthorizeWrapper>
      <Box bg="white" borderRadius={8} p={4}>
        <Text.H4 pb={6}>{t('confirm_reset_password_screen.set_new_password')}</Text.H4>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          isRequired
          label={t('form.labels.new_password')}
          name="newPassword"
          onSubmitEditing={setFocusRepeatNewPassword}
          placeholder={t('form.placeholders.new_password')}
          returnKeyType="next"
          rules={{
            required: t(' '),
            validate: {
              passwordEqual: (value, formValues) => {
                if (value === formValues.repeatNewPassword && formValues.repeatNewPassword) {
                  clearFormErrors('repeatNewPassword')
                }
                if (value !== formValues.repeatNewPassword && formValues.repeatNewPassword) {
                  setFormError('repeatNewPassword', {
                    message: t('form.errors.password_does_not_match'),
                  })
                }
                return undefined
              },
              pattern: (value) => {
                return REGEX.REGISTRATION_PASSWORD.every((pattern) => pattern.test(value)) || ' '
              },
            },
          }}
          type="password"
        />
        <Text.Caption
          color={errors.newPassword ? 'red.400' : 'text.500'}
          mb={6}
          mt={errors.newPassword ? -7 : 0}
        >
          {t('sign_up_screen.password_requirements')}
        </Text.Caption>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          isRequired
          label={t('form.labels.repeat_new_password')}
          name="repeatNewPassword"
          placeholder={t('form.placeholders.repeat_new_password')}
          returnKeyType="done"
          rules={{
            required: t('form.errors.required'),
            validate: {
              passwordsEqual: (value, formValues) => {
                if (value !== formValues.newPassword)
                  return t('form.errors.password_does_not_match')
              },
            },
          }}
          type="password"
        />
        <Center mt={6}>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            onPress={submit}
            testID="signInButton"
            width="100%"
          >
            {t('confirm_reset_password_screen.save_new_password')}
          </Button>
          <Button mt={2} onPress={navigateToLogin} width="100%" variant="Ghost">
            {t('common.cancel')}
          </Button>
        </Center>
      </Box>
    </UnAuthorizeWrapper>
  )
}
