import { format, parseISO } from 'date-fns'
import { pl, enGB, de, Locale } from 'date-fns/locale'

import { TranslationKeys } from '~i18n'

const LOCALE: { [key in TranslationKeys]: Locale } = {
  de,
  en: enGB,
  pl,
}

const DATE_FORMAT_LONG_WITH_TIME = 'dd MMMM yyyy; HH:mm'

export const formatToLongDateWithTime = (
  date: Date | string | null | undefined,
  locale: TranslationKeys = 'de'
): string => {
  if (!date) return ''

  if (typeof date === 'string') {
    return format(parseISO(date), DATE_FORMAT_LONG_WITH_TIME, { locale: LOCALE[locale] })
  }
  return format(date, DATE_FORMAT_LONG_WITH_TIME, { locale: LOCALE[locale] })
}

const DATE_FORMAT_LONG = 'dd MMMM yyyy'

export const formatToLongDate = (
  date: Date | string | null | undefined,
  locale: TranslationKeys
): string => {
  if (!date) return ''
  if (typeof date === 'string') {
    return format(parseISO(date), DATE_FORMAT_LONG, { locale: LOCALE[locale] })
  }
  return format(date, DATE_FORMAT_LONG, { locale: LOCALE[locale] })
}
