import { Button, ControlledField, LightScreenWrapper, Spacer, Text } from '~components'
import { REGEX } from '~constants'
import { useCallback, useChangePasswordForm, useTranslation } from '~hooks'
import { hideKeyboard } from '~utils'

export const ChangePasswordScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { clearFormErrors, control, errors, submit, isSubmitting, setFocus, setFormError } =
    useChangePasswordForm()

  const setFocusNewPassword = useCallback(() => setFocus('newPassword'), [setFocus])
  const setFocusConfirmNewPassword = useCallback(() => setFocus('repeatNewPassword'), [setFocus])

  return (
    <LightScreenWrapper
      iconName="lock-password-line"
      title={t('change_password_screen.change_password')}
    >
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        isRequired
        label={t('form.labels.current_password')}
        name="oldPassword"
        onSubmitEditing={setFocusNewPassword}
        placeholder={t('form.placeholders.current_password')}
        returnKeyType="next"
        rules={{
          required: t('form.errors.required'),
        }}
        type="password"
      />
      <Spacer y="6" />
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        isRequired
        label={t('form.labels.new_password')}
        mb={1}
        name="newPassword"
        onSubmitEditing={setFocusConfirmNewPassword}
        placeholder={t('form.placeholders.new_password')}
        returnKeyType="next"
        rules={{
          required: ' ',
          validate: {
            passwordEqual: (value, formValues) => {
              if (value === formValues.repeatNewPassword && formValues.repeatNewPassword) {
                clearFormErrors('repeatNewPassword')
              }
              if (value !== formValues.repeatNewPassword && formValues.repeatNewPassword) {
                setFormError('repeatNewPassword', {
                  message: t('form.errors.password_does_not_match'),
                })
              }
              return undefined
            },

            pattern: (value) => {
              return REGEX.REGISTRATION_PASSWORD.every((pattern) => pattern.test(value)) || ' '
            },
          },
        }}
        type="password"
      />
      <Text.Caption
        color={errors.newPassword ? 'red.400' : 'text.500'}
        mt={errors.newPassword ? -7 : 0}
      >
        {t('sign_up_screen.password_requirements')}
      </Text.Caption>
      <Spacer y="6" />
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        isRequired
        label={t('form.labels.repeat_new_password')}
        name="repeatNewPassword"
        onSubmitEditing={hideKeyboard}
        placeholder={t('form.placeholders.repeat_new_password')}
        returnKeyType="done"
        rules={{
          required: t('form.errors.required'),
          validate: {
            passwordsEqual: (value, formValues) => {
              if (value !== formValues.newPassword) return t('form.errors.password_does_not_match')
            },
          },
        }}
        type="password"
      />
      <Spacer y="6" />
      <Button disabled={isSubmitting} loading={isSubmitting} onPress={submit}>
        {t('change_password_screen.save_change')}
      </Button>
    </LightScreenWrapper>
  )
}
