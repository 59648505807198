import { useLinkProps } from '@react-navigation/native'
import { ImageSource } from 'expo-image'
import { FC } from 'react'

import { Box, BoxProps, Row, Spacer, Text, Touchable } from '../atoms'
import { TokenAndNetworkLogo } from './TokenAndNetworkLogo'

import { LOW_BALANCE } from '~constants'
import { NETWORK_LABEL_TYPE, TOKEN_SHORTNAME_TYPE } from '~types/assets'
import { convertToEuro, renderMaxDecimalPlaces } from '~utils'

export type WalletItemProps = {
  cryptoToEuroRate: number
  network?: string
  networkImage?: ImageSource
  networkLabel?: NETWORK_LABEL_TYPE
  tokenFullName: string
  tokenShortName: TOKEN_SHORTNAME_TYPE
  tokenImage: ImageSource
  value: number
} & BoxProps

export const WalletItem: FC<WalletItemProps> = ({
  cryptoToEuroRate,
  network,
  networkImage,
  networkLabel,
  tokenFullName,
  tokenShortName,
  tokenImage,
  value,
  ...BoxProps
}) => {
  const linkProps = useLinkProps({
    to: {
      screen: 'TokenDetails',
      params: { tokenShortName },
    },
  })

  const showOnlyToken = tokenFullName === networkLabel || !networkLabel
  const valueInEuro = Number(convertToEuro(value, cryptoToEuroRate))

  return (
    <Touchable {...linkProps}>
      <Row bg="white" flex={1} p={4} {...BoxProps}>
        <Row alignItems="center" flex={1}>
          <TokenAndNetworkLogo {...{ networkImage, tokenImage, showOnlyToken }} />
          <Spacer x="4" />
          <Text.Bold>
            {showOnlyToken ? tokenFullName : tokenFullName + '  •  ' + networkLabel}
          </Text.Bold>
        </Row>
        <Box flex={1}>
          <Text.Bold textAlign="right">
            {valueInEuro < LOW_BALANCE ? `< €${LOW_BALANCE}` : `€${valueInEuro}`}
          </Text.Bold>
          {typeof value === 'number' && (
            <Text.Caption color="coolGray.400" mt={2} textAlign="right">
              {renderMaxDecimalPlaces(value)} {tokenShortName}
            </Text.Caption>
          )}
        </Box>
      </Row>
    </Touchable>
  )
}
