import { StyleSheet } from 'react-native'

import {
  Button,
  Center,
  ControlledField,
  ExpoImage,
  Loader,
  Spacer,
  Text,
  UnAuthorizeWrapper,
} from '~components'
import { CONGRATULATIONS, ERROR, REGEX } from '~constants'
import { useCallback, useEffect, useResendVerificationLinkForm, useTranslation } from '~hooks'
import { useConfirmEmailMutation } from '~query-hooks'

export const AccountCreatedScreen = (props: AccountCreatedScreenProps): JSX.Element => {
  const {
    navigation: { navigate },
    route: {
      params: { oobCode },
    },
  } = props
  const { t } = useTranslation()

  const { confirmEmailMutation, isLoading, isError } = useConfirmEmailMutation()
  const { control, errors, isSubmitting, submit } = useResendVerificationLinkForm()

  useEffect(() => {
    const verifyAccount = async () => {
      await confirmEmailMutation({ oobCode })
    }
    try {
      verifyAccount()
    } catch (e) {
      console.log(e)
    }
  }, [confirmEmailMutation, oobCode])

  const navigateToLogin = useCallback(() => {
    navigate('SignIn')
  }, [navigate])

  if (isLoading) return <Loader type="bubbles" />

  return (
    <UnAuthorizeWrapper showTopNavBar={false}>
      <Center bg="white" borderRadius={8} p={4}>
        <Spacer y="6" />

        {isError ? (
          <>
            <ExpoImage
              alt="medal icon"
              contentFit="contain"
              source={ERROR}
              style={styles.errorImage}
            />
            <Text.H4 my={6}>{t('errors.something_went_wrong')}!</Text.H4>
            <Text mb={6}>{t('account_created_screen.problem_with_verification')}</Text>
            <ControlledField.Input
              {...{ control, errors }}
              isRequired
              keyboardType="email-address"
              label={t('form.labels.email')}
              name="email"
              placeholder={t('form.placeholders.email')}
              rules={{
                required: t('form.errors.required'),
                pattern: {
                  value: REGEX.EMAIL,
                  message: t('form.errors.invalid_email_format'),
                },
              }}
            />
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              mt={6}
              onPress={submit}
              width="100%"
            >
              {t('account_created_screen.send_verification_link')}
            </Button>
          </>
        ) : (
          <>
            <ExpoImage
              alt="medal icon"
              contentFit="contain"
              source={CONGRATULATIONS}
              style={styles.congratulationImage}
            />
            <Spacer y="6" />
            <Text.H4>{t('account_created_screen.congratulations')}!</Text.H4>
            <Spacer y="6" />
            <Text.Body>{t('account_created_screen.account_created_successfully')}!</Text.Body>
            <Button mt={6} onPress={navigateToLogin} width="100%">
              {t('account_created_screen.login')}
            </Button>
          </>
        )}
      </Center>
    </UnAuthorizeWrapper>
  )
}

const styles = StyleSheet.create({
  congratulationImage: { height: 135, width: 115 },
  errorImage: { height: 94, width: 94 },
})
