import { uniq } from 'ramda'
import { useCallback, useMemo, useState } from 'react'
import { FlatList, ListRenderItem, StyleSheet } from 'react-native'

import {
  Background,
  Box,
  Divider,
  Field,
  ListEmptyComponentBase,
  Loader,
  Row,
  Select,
  Spacer,
  Text,
  WalletActionsCard,
  WalletItem,
  WalletItemProps,
} from '~components'
import { breakpoints, EMPTY_STATE_TOKENS, LOW_BALANCE } from '~constants'
import { useBoolean, useTranslation } from '~hooks'
import { useGetAssetsListQuery } from '~query-hooks'
import { calculateWalletValueInEuro, convertToEuro } from '~utils'

const keyExtractor = (item: WalletItemProps) => String(item.tokenFullName + item.network)

const ItemSeparatorComponent = () => (
  <Box px={4} bg="white">
    <Divider />
  </Box>
)

const ListEmptyComponent = () => {
  const { t } = useTranslation()
  return (
    <ListEmptyComponentBase emptyText={t('wallet_screen.no_tokens')} source={EMPTY_STATE_TOKENS} />
  )
}

export const WalletScreen = (_: WalletScreenProps): JSX.Element => {
  const { t } = useTranslation()
  const [hideLowBalance, setHideLowBalance] = useBoolean(false)

  const { assetsList, isInitialLoadingAssetsList } = useGetAssetsListQuery()

  const walletValueInEuro = useMemo(() => calculateWalletValueInEuro({ assetsList }), [assetsList])

  const [pickedToken, setPickedToken] = useState<string[]>([])
  const tokenItems = useMemo(() => {
    const tokenItems = uniq(
      assetsList.map((asset) => {
        return { label: asset.tokenFullName, value: asset.tokenFullName }
      })
    )
    tokenItems.unshift({ label: t('common.all_tokens'), value: '' })
    return tokenItems
  }, [assetsList, t])

  const [pickedNetwork, setPickedNetwork] = useState<string[]>([])

  const networkItems = useMemo(() => {
    const networkItems = uniq(
      assetsList.map((asset) => {
        const value = asset?.networkLabel || asset?.tokenFullName
        return { label: value, value }
      })
    )
    networkItems.unshift({ label: t('common.all_networks'), value: '' })
    return networkItems
  }, [assetsList, t])

  const data = useMemo(() => {
    let filteredData = assetsList
    const filterNetwork = pickedNetwork?.[0]
    const filterToken = pickedToken?.[0]

    if (filterNetwork) {
      filteredData = filteredData.filter(
        (asset) => asset.networkLabel === filterNetwork || asset.tokenFullName === filterNetwork
      )
    }
    if (filterToken) {
      filteredData = filteredData.filter((asset) => asset.tokenFullName === filterToken)
    }
    return hideLowBalance
      ? filteredData.filter(
          (item) => Number(convertToEuro(item.value, item.cryptoToEuroRate)) > LOW_BALANCE
        )
      : filteredData
  }, [assetsList, hideLowBalance, pickedNetwork, pickedToken])

  const ListHeaderComponent = useCallback(() => {
    return (
      <>
        <Spacer y="6" />
        <WalletActionsCard title={t('wallet_screen.crypto')} valueInEuro={walletValueInEuro} />
        <Spacer y="4" />
        <Box bg="white" borderTopEndRadius={8} borderTopLeftRadius={8} p={4}>
          <Row alignItems="center" justifyContent="space-between">
            <Text.Bold color="muted.500">{t('wallet_screen.my_cryptos')}</Text.Bold>
            <Field.Checkbox
              checkboxText={t('wallet_screen.hide_balance')}
              mb={0}
              name="hide-low_balance"
              onChange={setHideLowBalance.toggle}
              showExtraText={false}
              value={hideLowBalance}
            />
          </Row>
          <Spacer y="6" />
          <Row>
            <Box flex={1}>
              <Text.Caption color="muted.500" mb={2}>
                {t('common.tokens')}
              </Text.Caption>
              <Field.Select
                listTitle={t('wallet_screen.select_token')}
                items={tokenItems}
                placeholder={t('common.all_tokens')}
                setValue={setPickedToken}
                value={pickedToken}
              />
            </Box>
            <Spacer x="6" />
            <Box flex={1}>
              <Text.Caption color="muted.500" mb={2}>
                {t('common.networks')}
              </Text.Caption>
              <Select
                items={networkItems}
                listTitle={t('wallet_screen.select_network')}
                placeholder={t('common.all_networks')}
                setValue={setPickedNetwork}
                value={pickedNetwork}
              />
            </Box>
          </Row>
        </Box>
      </>
    )
  }, [
    hideLowBalance,
    networkItems,
    pickedNetwork,
    pickedToken,
    setHideLowBalance.toggle,
    t,
    tokenItems,
    walletValueInEuro,
  ])

  const renderItem: ListRenderItem<WalletItemProps> = useCallback(
    ({ item, index }) => {
      return (
        <WalletItem
          borderBottomEndRadius={index === data?.length - 1 ? 8 : 0}
          borderBottomLeftRadius={index === data?.length - 1 ? 8 : 0}
          {...item}
        />
      )
    },
    [data?.length]
  )

  return (
    <Background>
      {isInitialLoadingAssetsList ? (
        <Loader type="bubbles" />
      ) : (
        <FlatList
          contentContainerStyle={styles.contentContainerStyle}
          showsVerticalScrollIndicator={false}
          {...{
            data,
            ItemSeparatorComponent,
            keyExtractor,
            ListEmptyComponent,
            ListHeaderComponent,
            renderItem,
          }}
        />
      )}
    </Background>
  )
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    alignSelf: 'center',
    flexGrow: 1,
    maxWidth: breakpoints.lg,
    paddingBottom: 16,
    paddingHorizontal: 16,
    width: '100%',
  },
})
