import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

import { useMeQuery } from './useMeQuery'

import { QueryKeys } from '~enums'
import { getWalletStatusRequest } from '~services'

export const useGetWalletStatusQuery = () => {
  const { userData } = useMeQuery()

  const getWalletStatus = useCallback(
    () => getWalletStatusRequest({ walletName: userData?.wallet || '' }),
    [userData?.wallet]
  )

  const {
    data: walletStatus,
    isInitialLoading: isLoadingWalletStatus,
    refetch: refetchWalletStatus,
  } = useQuery([QueryKeys.WalletStatus], getWalletStatus, { enabled: !!userData?.wallet })

  return { walletStatus, isLoadingWalletStatus, refetchWalletStatus }
}
