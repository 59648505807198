import type { Theme } from '@react-navigation/native'

export const palette = {
  red: {
    50: '#fff1f2',
    100: '#ffe4e6',
    200: '#fecdd3',
    300: '#fda4af',
    400: '#fb7185',
    500: '#EF4444',
    600: '#DC2626',
    700: '#be123c',
    800: '#9f1239',
    900: '#881337',
  },
  green: {
    50: '#e1ffe1',
    100: '#b1ffb1',
    200: '#7fff7f',
    300: '#4cff4c',
    400: '#1aff1a',
    500: '#00e600',
    600: '#00b400',
    700: '#008200',
    800: '#005000',
    900: '#002100',
  },
  info: {
    50: '#f0f9ff',
    100: '#e0f2fe',
    200: '#bae6fd',
    300: '#7dd3fc',
    400: '#38bdf8',
    500: '#0ea5e9',
    600: '#0284c7',
    700: '#0369a1',
    800: '#075985',
    900: '#0c4a6e',
  },
  blue: {
    50: '#eff6ff',
    100: '#dbeafe',
    200: '#bfdbfe',
    300: '#93c5fd',
    400: '#60a5fa',
    500: '#3b82f6',
    600: '#2563eb',
    700: '#1d4ed8',
    800: '#1e40af',
    900: '#1e3a8a',
  },
  yellow: {
    50: '#fffde1',
    100: '#fffbb1',
    200: '#fff87f',
    300: '#fff54c',
    400: '#fff21a',
    500: '#ffe900',
    600: '#b4b400',
    700: '#828200',
    800: '#505000',
    900: '#1a1a00',
  },
  coolGray: {
    50: '#f9fafb',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '#111827',
  },
  gray: {
    50: '#f2f2f2',
    100: '#e6e6e6',
    200: '#cccccc',
    300: '#b3b3b3',
    400: '#999999',
    500: '#808080',
    600: '#666666',
    700: '#4d4d4d',
    800: '#333333',
    900: '#1a1a1a',
  },
  primary: {
    50: '#F2F3FF',
    100: '#E8EAFE',
    200: '#D1D5FD',
    300: '#B9C0FD',
    400: '#A2ABFC',
    500: '#8B96FB',
    600: '#6F78C9',
    700: '#1F34F7',
    800: '#071CD6',
    900: '#1C1E32',
  },
  secondary: {
    50: '#FFFFFF',
    100: '#FFFFFF',
    200: '#F5E6FE',
    300: '#E5BEFE',
    400: '#D696FD',
    500: '#C66EFC',
    600: '#B037FB',
    700: '#9A05F5',
    800: '#7704BE',
    900: '#550387',
  },
  tertiary: {
    50: '#AAF5F7',
    100: '#9AF3F5',
    200: '#79EFF2',
    300: '#59EBEE',
    400: '#38E7EB',
    500: '#18E3E8',
    600: '#14C4C8',
    700: '#11A4A8',
    800: '#0D8487',
    900: '#0A6567',
  },
  muted: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d4d4d4',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
  },
  error: {
    50: '#fef2f2',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#f87171',
    500: '#ef4444',
    600: '#dc2626',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d',
  },
  success: {
    50: '#f0fdf4',
    100: '#dcfce7',
    200: '#bbf7d0',
    300: '#86efac',
    400: '#4ade80',
    500: '#22c55e',
    600: '#16a34a',
    700: '#15803d',
    800: '#166534',
    900: '#14532d',
  },
  warning: {
    50: '#fff7ed',
    100: '#ffedd5',
    200: '#fed7aa',
    300: '#fdba74',
    400: '#fb923c',
    500: '#f97316',
    600: '#ea580c',
    700: '#c2410c',
    800: '#9a3412',
    900: '#7c2d12',
  },
  text: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d4d4d4',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
  },
  rose: {
    50: '#fff1f2',
    100: '#ffe4e6',
    200: '#fecdd3',
    300: '#fda4af',
    400: '#fb7185',
    500: '#f43f5e',
    600: '#e11d48',
    700: '#be123c',
    800: '#9f1239',
    900: '#881337',
  },
  pink: {
    50: '#fdf2f8',
    100: '#fce7f3',
    200: '#fbcfe8',
    300: '#f9a8d4',
    400: '#f472b6',
    500: '#ec4899',
    600: '#db2777',
    700: '#be185d',
    800: '#9d174d',
    900: '#831843',
  },
  fuchsia: {
    50: '#fdf4ff',
    100: '#fae8ff',
    200: '#f5d0fe',
    300: '#f0abfc',
    400: '#e879f9',
    500: '#d946ef',
    600: '#c026d3',
    700: '#a21caf',
    800: '#86198f',
    900: '#701a75',
  },
  purple: {
    50: '#faf5ff',
    100: '#f3e8ff',
    200: '#e9d5ff',
    300: '#d8b4fe',
    400: '#c084fc',
    500: '#a855f7',
    600: '#9333ea',
    700: '#7e22ce',
    800: '#6b21a8',
    900: '#581c87',
  },
  violet: {
    50: '#f5f3ff',
    100: '#ede9fe',
    200: '#ddd6fe',
    300: '#c4b5fd',
    400: '#a78bfa',
    500: '#8b5cf6',
    600: '#7c3aed',
    700: '#6d28d9',
    800: '#5b21b6',
    900: '#4c1d95',
  },
  indigo: {
    50: '#eef2ff',
    100: '#e0e7ff',
    200: '#c7d2fe',
    300: '#a5b4fc',
    400: '#818cf8',
    500: '#6366f1',
    600: '#4f46e5',
    700: '#4338ca',
    800: '#3730a3',
    900: '#312e81',
  },
  lightBlue: {
    50: '#f0f9ff',
    100: '#e0f2fe',
    200: '#bae6fd',
    300: '#7dd3fc',
    400: '#38bdf8',
    500: '#0ea5e9',
    600: '#0284c7',
    700: '#0369a1',
    800: '#075985',
    900: '#0c4a6e',
  },
  darkBlue: {
    50: '#dbf4ff',
    100: '#addbff',
    200: '#7cc2ff',
    300: '#4aa9ff',
    400: '#1a91ff',
    500: '#0077e6',
    600: '#005db4',
    700: '#004282',
    800: '#002851',
    900: '#000e21',
  },
  cyan: {
    50: '#ecfeff',
    100: '#cffafe',
    200: '#a5f3fc',
    300: '#67e8f9',
    400: '#22d3ee',
    500: '#06b6d4',
    600: '#0891b2',
    700: '#0e7490',
    800: '#155e75',
    900: '#164e63',
  },
  teal: {
    50: '#f0fdfa',
    100: '#ccfbf1',
    200: '#99f6e4',
    300: '#5eead4',
    400: '#2dd4bf',
    500: '#14b8a6',
    600: '#0d9488',
    700: '#0f766e',
    800: '#115e59',
    900: '#134e4a',
  },
  emerald: {
    50: '#ecfdf5',
    100: '#d1fae5',
    200: '#a7f3d0',
    300: '#6ee7b7',
    400: '#34d399',
    500: '#10b981',
    600: '#059669',
    700: '#047857',
    800: '#065f46',
    900: '#064e3b',
  },
  lime: {
    50: '#f7fee7',
    100: '#ecfccb',
    200: '#d9f99d',
    300: '#bef264',
    400: '#a3e635',
    500: '#84cc16',
    600: '#65a30d',
    700: '#4d7c0f',
    800: '#3f6212',
    900: '#365314',
  },
  amber: {
    50: '#fffbeb',
    100: '#fef3c7',
    200: '#fde68a',
    300: '#fcd34d',
    400: '#fbbf24',
    500: '#f59e0b',
    600: '#d97706',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
  },
  orange: {
    50: '#fff7ed',
    100: '#ffedd5',
    200: '#fed7aa',
    300: '#fdba74',
    400: '#fb923c',
    500: '#f97316',
    600: '#ea580c',
    700: '#c2410c',
    800: '#9a3412',
    900: '#7c2d12',
  },
  warmGray: {
    50: '#fafaf9',
    100: '#f5f5f4',
    200: '#e7e5e4',
    300: '#d6d3d1',
    400: '#a8a29e',
    500: '#78716c',
    600: '#57534e',
    700: '#44403c',
    800: '#292524',
    900: '#1c1917',
  },
  trueGray: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d4d4d4',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
  },
  blueGray: {
    50: '#f8fafc',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cbd5e1',
    400: '#94a3b8',
    500: '#64748b',
    600: '#475569',
    700: '#334155',
    800: '#1e293b',
    900: '#0f172a',
  },
  dark: {
    50: '#18181b',
    100: '#27272a',
    200: '#3f3f46',
    300: '#52525b',
    400: '#71717a',
    500: '#a1a1aa',
    600: '#d4d4d8',
    700: '#e4e4e7',
    800: '#f4f4f5',
    900: '#fafafa',
  },
  danger: {
    50: '#fff1f2',
    100: '#ffe4e6',
    200: '#fecdd3',
    300: '#fda4af',
    400: '#fb7185',
    500: '#f43f5e',
    600: '#e11d48',
    700: '#be123c',
    800: '#9f1239',
    900: '#881337',
  },
  light: {
    50: '#fafaf9',
    100: '#f5f5f4',
    200: '#e7e5e4',
    300: '#d6d3d1',
    400: '#a8a29e',
    500: '#78716c',
    600: '#57534e',
    700: '#44403c',
    800: '#292524',
    900: '#1c1917',
  },
}

export const colors = {
  // primary: palette.primary['500'],
  primaryLight: palette.primary['400'],
  primaryDark: palette.primary['600'],
  // secondary: palette.red['500'],
  secondaryLight: palette.red['300'],
  secondaryDark: palette.red['700'],
  // tertiary: palette.green['500'],
  tertiaryLight: palette.green['100'],
  tertiaryDark: palette.green['900'],
  // light: palette.gray['50'],
  // dark: palette.black,
  darkText: '#000',
  lightText: '#fff',
  black: '#000',
  white: '#fff',
  transparent: 'transparent',
}

export const ProjectColors = {
  //CONFIG: Add your project specific colors here
  modalBackground: 'rgba(0, 0, 0, 0.5)',
  brand_dark: '#283265',
  brand_bg: '#E4DCEC',
  hover: '#4E60B9',
  lightGray: '#EBEBE4',
  transparent: 'rgba(0,0,0,0)',
  white20opacity: '#FFFFFF22',
  white40opacity: '#FFFFFF66',
}

export const fontSizes = {
  '2xs': 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  '2xl': 24,
  '3xl': 30,
  '4xl': 36,
  '5xl': 48,
  '6xl': 60,
  '7xl': 72,
  '8xl': 96,
  '9xl': 128,
} as const

export const scale = fontSizes.md

export const radii = {
  none: 0,
  sm: 2,
  md: 4,
  lg: 6,
  xl: 8,
  pill: 25,
  full: 9999,
}

export const letterSpacings = {
  xs: '-0.05em',
  sm: '-0.025em',
  md: 0,
  lg: '0.025em',
  xl: '0.05em',
  '2xl': '0.1em',
} as const

export const lineHeights = {
  '2xs': '1em',
  xs: '1.125em',
  sm: '1.25em',
  md: '1.375em',
  lg: '1.5em',
  xl: '1.75em',
  '2xl': '2em',
  '3xl': '2.5em',
  '4xl': '3em',
  '5xl': '4em',
} as const

export const fontWeights = {
  hairline: '100',
  thin: '200',
  light: '300',
  normal: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extrabold: '800',
  black: '900',
} as const

export const fonts = {
  heading: 'Lato-Bold',
  body: 'Lato-Regular',
  mono: 'Lato-Black',
} as const

export const size = {
  '0': 0,
  '0.5': 0.125 * scale,
  '1': 0.25 * scale,
  '1.5': 6,
  '1/2': '50%',
  '1/3': '33.333%',
  '1/4': '25%',
  '1/5': '20%',
  '1/6': '16.666%',
  '10': 2.5 * scale,
  '12': 3 * scale,
  '16': 4 * scale,
  '2': 0.5 * scale,
  '2.5': 10,
  '2/3': '66.666%',
  '2/4': '50%',
  '2/5': '40%',
  '2/6': '33.333%',
  '20': 5 * scale,
  '24': 6 * scale,
  '3': 0.75 * scale,
  '3.5': 14,
  '3/4': '75%',
  '3/5': '60%',
  '3/6': '50%',
  '32': 8 * scale,
  '4': scale,
  '4/5': '80%',
  '4/6': '66.666%',
  '40': 10 * scale,
  '48': 12 * scale,
  '5': 1.25 * scale,
  '5/6': '83.333%',
  '56': 14 * scale,
  '6': 1.5 * scale,
  '64': 16 * scale,
  '7': 1.75 * scale,
  '72': 18 * scale,
  '8': 2 * scale,
  '80': 20 * scale,
  '9': 2.25 * scale,
  '96': 24 * scale,
  full: '100%',
  px: 1,
} as const

export const breakpoints = {
  mobile: 360,
  sm: 480,
  tablet: 568,
  md: 768,
  lg: 1024,
  desktop: 1024,
  xl: 1440,
  xxl: 1920,
}

export const shadows = {
  '0': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1,
    elevation: 1,
  },
  '1': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  '2': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  '3': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  '4': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  '5': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    elevation: 6,
  },
  '6': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,
    elevation: 7,
  },
  '7': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8,
  },
  '8': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,
    elevation: 9,
  },
  '9': {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 10,
  },
  none: {
    shadowColor: 'transparent',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
  },
  inputShadow: {
    shadowOffset: { width: 0, height: 2 },
    shadowColor: palette.primary[500],
    shadowOpacity: 0.16,
    elevation: 3,
  },
}

export const _appTheme = {
  breakpoints,
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },
  fonts,
  letterSpacings,
  fontSizes,
  lineHeights,
  fontWeights,
  colors: {
    ...palette,
    ...colors,
    ...ProjectColors,
  },
  size,
  shadows,
}

export const theme = {
  light: {
    ..._appTheme,
    colors: {
      ..._appTheme.colors,
    },
  },
  dark: {
    ..._appTheme,
    colors: {
      ..._appTheme.colors,
    },
  },
}

// FIXME: adjust light navigation theme colors
export const lightNavigationTheme: Theme = {
  colors: {
    background: _appTheme.colors.white,
    border: _appTheme.colors.white,
    card: _appTheme.colors.white,
    notification: _appTheme.colors.secondaryLight,
    primary: palette.primary[500],
    text: palette.text[500],
  },
  dark: false,
}

// FIXME: adjust dark navigation theme colors
export const darkNavigationTheme: Theme = {
  colors: {
    background: _appTheme.colors.white,
    border: _appTheme.colors.white,
    card: _appTheme.colors.white,
    notification: _appTheme.colors.secondaryLight,
    primary: palette.primary[500],
    text: palette.text[500],
  },
  dark: true,
}

export const navigationTheme = {
  light: lightNavigationTheme,
  dark: darkNavigationTheme,
}
