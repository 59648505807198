import React, { useCallback, useRef } from 'react'
import { Pressable, View } from 'react-native'

import { Select as CustomSelect, Box, FormErrorMessage, FormLabel, SelectKey } from '../../atoms'
import { useHover } from '../../atoms/Touchables/useHover'
import type { FieldSelectProps } from './types'

import { useMemo } from '~hooks'

const layoutPropsKeys = [
  'flex',
  'm',
  'margin',
  'mt',
  'marginTop',
  'mr',
  'marginRight',
  'mb',
  'marginBottom',
  'ml',
  'marginLeft',
  'mx',
  'my',
  'p',
  'padding',
  'pt',
  'paddingTop',
  'pr',
  'paddingRight',
  'pb',
  'paddingBottom',
  'pl',
  'paddingLeft',
  'px',
  'py',
  'width',
]

export const Select = <T extends SelectKey>({
  isRequired,
  isInvalid,
  label,
  helperText,
  errorMessage,
  onOpen,
  labelStyle,
  ...props
}: FieldSelectProps<T>) => {
  const layoutProps = useMemo(
    () =>
      Object.fromEntries(Object.entries(props).filter(([key]) => layoutPropsKeys.includes(key))),
    [props]
  )

  const _pressableRef = useRef<View>(null)
  const { isHovered, hoverProps } = useHover()

  const handleHoverIn = useCallback(
    () => {
      hoverProps.onHoverIn()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoverProps.onHoverIn]
  )

  const handleHoverOut = useCallback(
    () => {
      hoverProps.onHoverOut()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoverProps.onHoverOut]
  )

  return (
    <Box width="100%" mb={2} {...layoutProps}>
      <Pressable
        focusable={false}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        ref={_pressableRef}
      >
        <FormLabel label={label} isRequired={isRequired} labelStyle={labelStyle} />
        <CustomSelect
          isHovered={isHovered}
          onHoverIn={handleHoverIn}
          onHoverOut={handleHoverOut}
          {...props}
        />
        <FormErrorMessage errorMessage={errorMessage} />
      </Pressable>
    </Box>
  )
}
