import { TRANSACTION_STATUS, TRANSACTION_TYPE } from '~enums'
import { apiClient } from '~services/api'
import { NETWORK_LABEL_TYPE, TOKEN_SHORTNAME_TYPE } from '~types/assets'

export type TransactionHistory = {
  createdAt: Date
  fullName: string
  networkLabel: NETWORK_LABEL_TYPE
  shortName: TOKEN_SHORTNAME_TYPE
  status: TRANSACTION_STATUS
  transactionHash: string
  transactionType: TRANSACTION_TYPE
  value: number
}

export type TransactionHistoryList = TransactionHistory[]

export const getTransactionHistoryListRequest = async () => {
  const response = await apiClient.get<TransactionHistoryList, TransactionHistoryList>(
    '/tangany/transactions-history'
  )

  return response
}
