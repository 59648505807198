import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { DatePickerModal } from 'react-native-paper-dates'
import {
  CalendarDate,
  SingleChange,
  ValidRangeType,
} from 'react-native-paper-dates/lib/typescript/Date/Calendar'

import { FormErrorMessage } from './FormErrorMessage'
import { FormLabel } from './FormLabel'
import { Icon } from './Icon'
import { Input } from './Input'
import { Touchable } from './Touchables/Touchable'
import { useHover } from './Touchables/useHover'

import { TranslationKeys } from '~i18n'
import { formatToLongDate } from '~utils'

type DatePickerProps = {
  date: CalendarDate | string
  errorMessage?: string
  isRequired?: boolean
  isInvalid?: boolean
  onConfirmDate: SingleChange
}

const validRange: ValidRangeType = { endDate: new Date() }

export const DatePicker = forwardRef<{ openDatePicker(): void }, DatePickerProps>(
  ({ date, onConfirmDate, errorMessage, isInvalid, isRequired }, ref) => {
    const { i18n, t } = useTranslation()
    const locale = i18n.language.slice(0, 2).toLowerCase() as TranslationKeys

    const [isVisible, setIsVisible] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({
      openDatePicker() {
        setIsVisible(true)
      },
    }))

    const onDismiss = useCallback(() => {
      setIsVisible(false)
    }, [])

    const onOpen = useCallback(() => {
      setIsVisible(true)
    }, [])

    const onConfirm = useCallback(
      (params: { date: CalendarDate }) => {
        onConfirmDate && onConfirmDate(params)
        setIsVisible(false)
      },
      [onConfirmDate]
    )

    const { isHovered, hoverProps } = useHover()

    const handleHoverIn = useCallback(
      () => {
        hoverProps.onHoverIn()
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hoverProps.onHoverIn]
    )

    const handleHoverOut = useCallback(
      () => {
        hoverProps.onHoverOut()
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hoverProps.onHoverOut]
    )

    const value = date ? (typeof date === 'string' ? new Date(date) : date) : undefined

    return (
      <>
        <Touchable mb={4} onHoverIn={handleHoverIn} onHoverOut={handleHoverOut} onPress={onOpen}>
          <FormLabel label={t('form.labels.birth_date')} isRequired={isRequired} />
          <Input
            rightElement={
              <Icon color="text.500" name="calendar-line" size={24} style={styles.icon} />
            }
            onFocus={onOpen}
            placeholder={t('form.placeholders.birth_date')}
            value={formatToLongDate(value, locale)}
            {...{ isInvalid, isHovered }}
          />
          <FormErrorMessage {...{ errorMessage }} />
        </Touchable>
        <DatePickerModal
          date={value}
          emptyLabel={t('form.placeholders.birth_date')}
          label={t('form.labels.birth_date')}
          mode="single"
          visible={isVisible}
          {...{ locale, onConfirm, onDismiss, validRange }}
        />
      </>
    )
  }
)

const styles = StyleSheet.create({
  icon: { marginRight: 8 },
})
