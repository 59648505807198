import { TFunction } from 'i18next'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import CountryPicker, { Country, CountryCode } from 'react-native-country-picker-modal'
import { string } from 'yup'

import { Box, Button, ControlledField, Row, Spacer, Text } from '~components'
import { COUNTRIES, REGEX, isWeb } from '~constants'
import { useSignUpForm, useWeb } from '~hooks'
import { generateSalutationItems, generateTitleItems, hideKeyboard } from '~utils'

type SignUpFormStep1Props = {
  t: TFunction<'translation', undefined>
  setCurrentStep(step: 0 | 1 | 2): void
}

export const SignUpFormStep2: FC<SignUpFormStep1Props> = ({ setCurrentStep, t }) => {
  const { shouldApplyDesktopStyles } = useWeb()

  const titleItems = useMemo(() => generateTitleItems(t), [t])
  const salutationItems = useMemo(() => generateSalutationItems(t), [t])

  const { control, errors, isSubmitting, setFocus, setValue, submit } = useSignUpForm({
    onFieldError: setCurrentStep,
  })

  const ref = useRef<{ openDatePicker(): void } | null>(null)

  const setFocusFirstName = useCallback(() => {
    !isWeb && setFocus('companyRepresentative.firstName')
  }, [setFocus])

  const setFocusLastName = useCallback(() => {
    setFocus('companyRepresentative.lastName')
  }, [setFocus])

  const setFocusBirthData = useCallback(() => {
    hideKeyboard()
    ref.current?.openDatePicker()
  }, [])

  const onSkipPress = useCallback(() => {
    setValue('companyRepresentative', undefined)
    submit()
  }, [setValue, submit])

  // FIXME:  add option to open nationality selection after pick a birth date, apply in proper place
  // const setFocusNationality = useCallback(() => { }, [])

  // phone validation
  const [countryCode, setCountryCode] = useState<CountryCode>('DE')
  const phoneSchema = string().phone(countryCode, true)
  const onSelectPhoneCountry = useCallback(
    (country: Country) => {
      setValue('phoneNumberPrefix', country.callingCode?.[0])
      setCountryCode(country.cca2)
    },
    [setValue]
  )

  return (
    <Box borderRadius={8} bg="white" p={4}>
      <Row alignItems="center" justifyContent="space-between">
        <Text.H4>{t('sign_up_screen.company_representative')}</Text.H4>
        <Text.Body>2/2</Text.Body>
      </Row>
      <Spacer y="6" />
      <Text.Body>{t('sign_up_screen.provide_details')}</Text.Body>
      <Spacer y="6" />
      {/* START */}
      <Box flex={1} flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <Box flex={1} mb={4}>
          <ControlledField.Select
            {...{ control, errors }}
            items={titleItems}
            label={t('form.labels.title')}
            maxSelectedItems={1}
            name="title"
            placeholder={t('form.placeholders.title')}
          />
        </Box>
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <Box flex={1} mb={4}>
          <ControlledField.Select
            {...{ control, errors }}
            items={salutationItems}
            label={t('form.labels.salutation')}
            maxSelectedItems={1}
            name="salutation"
            onPick={setFocusFirstName}
            placeholder={t('form.placeholders.salutation')}
          />
        </Box>
      </Box>
      <ControlledField.Input
        {...{ control, errors }}
        autoComplete="name"
        autoCapitalize="none"
        label={t('form.labels.first_name')}
        mb={4}
        name="companyRepresentative.firstName"
        onSubmitEditing={setFocusLastName}
        placeholder={t('form.placeholders.first_name')}
        returnKeyType="next"
        rules={{
          pattern: {
            value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
            message: t('form.errors.special_characters', { max_length: 64 }),
          },
        }}
      />
      <ControlledField.Input
        {...{ control, errors }}
        autoComplete="name"
        autoCapitalize="none"
        label={t('form.labels.last_name')}
        mb={4}
        name="companyRepresentative.lastName"
        onSubmitEditing={setFocusBirthData}
        placeholder={t('form.placeholders.last_name')}
        returnKeyType="next"
        rules={{
          pattern: {
            value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
            message: t('form.errors.special_characters', { max_length: 64 }),
          },
        }}
      />
      <Box flex={1} flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <Box flex={1}>
          <ControlledField.DatePicker
            {...{ control, errors }}
            ref={ref}
            name="companyRepresentative.birthDate"
          />
        </Box>
        <Spacer x="4" />
        <Box flex={1}>
          <ControlledField.Select
            {...{ control, errors }}
            isRequired
            items={COUNTRIES}
            label={t('form.labels.nationality')}
            maxSelectedItems={1}
            name="companyRepresentative.nationality"
            placeholder={t('form.placeholders.nationality')}
          />
        </Box>
      </Box>
      <ControlledField.Input
        {...{ control, errors }}
        keyboardType="phone-pad"
        label={t('form.labels.phone_number')}
        leftElement={
          <Box height="100%" justifyContent="center" pl="2">
            <CountryPicker
              {...{ countryCode }}
              onSelect={onSelectPhoneCountry}
              withCallingCode
              withCallingCodeButton
              withCloseButton
              withEmoji
            />
          </Box>
        }
        name="companyRepresentative.phoneNumber"
        onSubmitEditing={hideKeyboard}
        placeholder={t('form.placeholders.phone_number')}
        rules={{
          validate: {
            phone: async (value) => {
              if (!value) return
              try {
                phoneSchema.validateSync(value)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
              } catch (e: unknown) {
                return t('form.errors.phone_number')
              }
            },
          },
        }}
        returnKeyType="next"
        type="text"
      />
      {/* STOP */}
      <Spacer y="6" />
      <Button disabled={isSubmitting} loading={isSubmitting} onPress={submit}>
        {t('sign_up_screen.confirm_and_register')}
      </Button>
      <Spacer y="4" />
      <Button disabled={isSubmitting} loading={isSubmitting} onPress={onSkipPress} variant="Ghost">
        {t('sign_up_screen.skip_and_register')}
      </Button>
    </Box>
  )
}
