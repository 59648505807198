import { TFunction } from 'i18next'
import { FC, useCallback } from 'react'

import { Box, Button, ControlledField, Row, Spacer, Text } from '~components'
import { COUNTRIES, REGEX, isWeb } from '~constants'
import { useSignUpForm, useWeb } from '~hooks'
import { hideKeyboard } from '~utils'

type SignUpFormStep1Props = {
  t: TFunction<'translation', undefined>
  setCurrentStep(step: 0 | 1 | 2): void
}

export const SignUpFormStep1: FC<SignUpFormStep1Props> = ({ t, setCurrentStep }) => {
  const { shouldApplyDesktopStyles } = useWeb()

  const { control, errors, handleSubmit, setFocus, setValue } = useSignUpForm({
    onFieldError: setCurrentStep,
  })

  const setFocusLegalForm = useCallback(() => {
    setFocus('companyDetails.legalForm')
  }, [setFocus])

  const setFocusCRN = useCallback(() => {
    setFocus('companyDetails.crn')
  }, [setFocus])

  const setFocusLEI = useCallback(() => {
    setFocus('companyDetails.lei')
  }, [setFocus])

  const setFocusTaxId = useCallback(() => {
    setFocus('companyDetails.taxId')
  }, [setFocus])

  const setFocusISIN = useCallback(() => {
    setFocus('companyDetails.isin')
  }, [setFocus])

  const setFocusVAT = useCallback(() => {
    setFocus('companyDetails.vat')
  }, [setFocus])

  const setFocusCity = useCallback(() => {
    !isWeb && setFocus('companyDetails.city')
  }, [setFocus])

  // FIXME: open bottom sheet with list of countries
  const setFocusCountry = useCallback(() => {
    hideKeyboard()
  }, [])

  const setFocusPostalCode = useCallback(() => {
    setFocus('companyDetails.postalCode')
  }, [setFocus])

  const setFocusStreet = useCallback(() => {
    setFocus('companyDetails.street')
  }, [setFocus])

  const setFocusBuildingNumber = useCallback(() => {
    setFocus('companyDetails.buildingNumber')
  }, [setFocus])

  const setFocusApartment = useCallback(() => {
    setFocus('companyDetails.apartment')
  }, [setFocus])

  const setFocusFloor = useCallback(() => {
    setFocus('companyDetails.floor')
  }, [setFocus])

  const onConfirmPress = useCallback(async () => {
    handleSubmit(async () => {
      setCurrentStep(2)
    })()
  }, [handleSubmit, setCurrentStep])

  const onSkipPress = useCallback(() => {
    setValue('companyDetails', undefined)
    setCurrentStep(2)
  }, [setCurrentStep, setValue])

  return (
    <Box borderRadius={8} bg="white" p={4}>
      <Row alignItems="center" justifyContent="space-between">
        <Text.H4>{t('sign_up_screen.company_details')}</Text.H4>
        <Text.Body>1/2</Text.Body>
      </Row>
      <Spacer y="6" />
      <Text.Body>{t('sign_up_screen.provide_details')}</Text.Body>
      <Spacer y="6" />
      {/* START */}
      <ControlledField.Input
        {...{ control, errors }}
        autoComplete="name"
        autoCapitalize="none"
        label={t('form.labels.company_name')}
        mb={4}
        name="companyDetails.companyName"
        onSubmitEditing={setFocusLegalForm}
        placeholder={t('form.placeholders.company_name')}
        returnKeyType="next"
      />
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.legal_form')}
          mb={4}
          name="companyDetails.legalForm"
          onSubmitEditing={setFocusCRN}
          placeholder={t('form.placeholders.legal_form')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_64,
              message: t('form.errors.maximum_length', { max_length: 64 }),
            },
          }}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.commercial_register_number')}
          mb={4}
          name="companyDetails.crn"
          onSubmitEditing={setFocusLEI}
          placeholder={t('form.placeholders.commercial_register_number')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
      </Box>
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.legal_entity_identifier')}
          mb={4}
          name="companyDetails.lei"
          onSubmitEditing={setFocusTaxId}
          placeholder={t('form.placeholders.legal_entity_identifier')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.tax_identification_number')}
          mb={4}
          name="companyDetails.taxID"
          onSubmitEditing={setFocusISIN}
          placeholder={t('form.placeholders.tax_identification_number')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
      </Box>
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.international_securities_identification_number')}
          mb={4}
          name="companyDetails.isin"
          onSubmitEditing={setFocusVAT}
          placeholder={t('form.placeholders.international_securities_identification_number')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.vat')}
          mb={4}
          name="companyDetails.vat"
          onSubmitEditing={setFocusCountry}
          placeholder={t('form.placeholders.vat')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
      </Box>
      <Box flexGrow={1} flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Select
          {...{ control, errors }}
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          items={COUNTRIES}
          label={t('form.labels.country')}
          maxSelectedItems={1}
          name="companyDetails.country"
          onPick={setFocusCity}
          placeholder={t('form.placeholders.company_country')}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="characters"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.city')}
          mb={4}
          name="companyDetails.city"
          onSubmitEditing={setFocusPostalCode}
          placeholder={t('form.placeholders.city')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
              message: t('form.errors.special_characters', { max_length: 64 }),
            },
          }}
        />
      </Box>
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Input
          {...{ control, errors }}
          autoComplete="name"
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.postal_code')}
          mb={4}
          name="companyDetails.postalCode"
          onSubmitEditing={setFocusStreet}
          placeholder={t('form.placeholders.postal_code')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.SPECIAL_CHARACTERS_16_NOT_ALLOWED,
              message: t('form.errors.special_characters', { max_length: 16 }),
            },
          }}
        />
        <Spacer x="4" />
        <ControlledField.Input
          {...{ control, errors }}
          autoComplete="name"
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.street')}
          mb={4}
          name="companyDetails.street"
          onSubmitEditing={setFocusBuildingNumber}
          placeholder={t('form.placeholders.street')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_128,
              message: t('form.errors.maximum_length', { max_length: 128 }),
            },
          }}
        />
      </Box>
      <Row>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          autoComplete="name"
          flex={shouldApplyDesktopStyles ? 1 : 1.5}
          label={t('form.labels.building_number')}
          labelNumberOfLines={1}
          mb={4}
          name="companyDetails.buildingNumber"
          onSubmitEditing={setFocusApartment}
          placeholder={t('form.placeholders.building_number')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_16,
              message: t('form.errors.maximum_length', { max_length: 16 }),
            },
          }}
        />
        <Spacer x="2" />
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          autoComplete="name"
          flex={1}
          keyboardType="number-pad"
          label={t('form.labels.apartment')}
          labelNumberOfLines={1}
          mb={4}
          name="companyDetails.apartment"
          onSubmitEditing={setFocusFloor}
          placeholder={t('form.placeholders.apartment')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_16,
              message: t('form.errors.maximum_length', { max_length: 16 }),
            },
          }}
        />
        <Spacer x="2" />
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          autoComplete="name"
          flex={1}
          keyboardType="number-pad"
          label={t('form.labels.floor')}
          labelNumberOfLines={1}
          mb={4}
          name="companyDetails.floor"
          onSubmitEditing={hideKeyboard}
          placeholder={t('form.placeholders.floor')}
          returnKeyType="default"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_16,
              message: t('form.errors.maximum_length', { max_length: 16 }),
            },
          }}
        />
      </Row>
      <Spacer y="6" />
      {/* FINISH */}
      <Button onPress={onConfirmPress}>{t('common.confirm')}</Button>
      <Spacer y="4" />
      <Button onPress={onSkipPress} variant="Ghost">
        {t('sign_up_screen.skip')}
      </Button>
    </Box>
  )
}
