import { useState, useEffect } from 'react'
import { Dimensions } from 'react-native'

import { breakpoints } from '~constants'

type ReturnType = {
  shouldApplyDesktopStyles: boolean
  shouldApplyWideDesktopStyles: boolean
  shouldApplyMobileStyles: boolean
  shouldApplyWideScreenStyles: boolean
  webContentWidth: number
}
const { desktop, md: tablet, xl: wideDesktop } = breakpoints

export const useWeb: () => ReturnType = () => {
  const [width, setWidth] = useState<number>(Dimensions.get('window').width)

  useEffect(() => {
    Dimensions.addEventListener('change', ({ window: { width: windowWidth } }) => {
      setWidth(windowWidth)
    })
  }, [])

  return {
    shouldApplyDesktopStyles: width >= desktop,
    shouldApplyMobileStyles: width < desktop,
    shouldApplyWideDesktopStyles: width >= wideDesktop,
    shouldApplyWideScreenStyles: width > tablet,
    webContentWidth: width,
  }
}
