import { Box, Button, Center, ControlledField, Text, UnAuthorizeWrapper } from '~components'
import { REGEX } from '~constants'
import { useCallback, useResetPasswordForm, useTranslation } from '~hooks'

export const ResetPasswordScreen = ({ navigation }: ResetPasswordScreenProps): JSX.Element => {
  const { navigate } = navigation
  const { t } = useTranslation()
  const { control, errors, isSubmitting, submit } = useResetPasswordForm()

  const navigateToLogin = useCallback(() => {
    navigate('SignIn')
  }, [navigate])

  return (
    <UnAuthorizeWrapper>
      <Box borderRadius={8} bg="white" p={4}>
        <Text.H4>{t('reset_password_screen.reset_password')}</Text.H4>
        <Text.Body my={6}>{t('reset_password_screen.we_will_send_link')}</Text.Body>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          isRequired
          keyboardType="email-address"
          label={t('form.labels.email')}
          name="email"
          placeholder={t('form.placeholders.email')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.EMAIL,
              message: t('form.errors.invalid_email_format'),
            },
          }}
        />
        <Center mt={6}>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            onPress={submit}
            testID="signInButton"
            width="100%"
          >
            {t('reset_password_screen.send_reset_link')}
          </Button>
          <Button mt={2} onPress={navigateToLogin} width="100%" variant="Ghost">
            {t('common.cancel')}
          </Button>
        </Center>
      </Box>
    </UnAuthorizeWrapper>
  )
}
