import languages from '../../assets/languages.json'

import { LightScreenWrapper, Field } from '~components'
import { useCallback, useState, useTranslation } from '~hooks'

const languageEntries: { [key: string]: string } = Object.entries(languages).reduce(
  (acc, [key, value]) => ({ ...acc, [value.language]: key.toLowerCase() }),
  {}
)

const languageValues = Object.entries(languages).map((el) => el[1].language)

export const SelectLanguageScreen = (): JSX.Element => {
  const { t, i18n } = useTranslation()

  const [currentLng, setCurrentLng] = useState(
    () =>
      languages[i18n.language.toUpperCase() as keyof typeof languages]?.language ||
      languages?.EN?.language
  )

  const handleLanguage = useCallback(
    (lng: string) => {
      i18n.changeLanguage(languageEntries[lng])
      setCurrentLng(lng)
    },
    [i18n]
  )

  return (
    <LightScreenWrapper iconName="translate-2" title={t('common.language')}>
      <Field.Radio onChange={handleLanguage} radioOptions={languageValues} value={currentLng} />
    </LightScreenWrapper>
  )
}
