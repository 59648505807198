import { Fragment, useMemo } from 'react'

import { Box } from '../../atoms/Box'
import { Checkbox as CustomCheckbox } from '../../atoms/Checkbox'
import { FormLabel } from '../../atoms/FormLabel'
import { Spacer } from '../../atoms/Spacer'
import { FieldCheckboxProps } from './types'

const layoutPropsKeys = [
  'flex',
  'm',
  'margin',
  'mt',
  'marginTop',
  'mr',
  'marginRight',
  'mb',
  'marginBottom',
  'ml',
  'marginLeft',
  'mx',
  'my',
  'p',
  'padding',
  'pt',
  'paddingTop',
  'pr',
  'paddingRight',
  'pb',
  'paddingBottom',
  'pl',
  'paddingLeft',
  'px',
  'py',
  'width',
]

export const Checkbox = ({
  isInvalid,
  isRequired,
  isDisabled,
  label,
  errorMessage,
  labelStyle,
  checkboxes,
  onChange,
  value,
  checkboxText,
  ...props
}: FieldCheckboxProps) => {
  const layoutProps = useMemo(
    () =>
      Object.fromEntries(Object.entries(props).filter(([key]) => layoutPropsKeys.includes(key))),
    [props]
  )

  const checkBoxProps = useMemo(
    () =>
      Object.fromEntries(Object.entries(props).filter(([key]) => !layoutPropsKeys.includes(key))),
    [props]
  )

  const renderCheckboxes = useMemo(() => {
    return checkboxes?.map((item: string, index: number) => {
      const handleChange = () => {
        if (Array.isArray(value)) {
          if (!value?.includes(item)) {
            const newArr = [...value, item]
            onChange(newArr)
          } else {
            const newArr = value.filter((el) => el !== item)
            onChange(newArr)
          }
        }
      }
      return (
        <Fragment key={index}>
          <CustomCheckbox
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleChange}
            key={index}
            checkboxText={item}
            value={value}
            {...(Array.isArray(value) && { isChecked: value?.includes(item) })}
            {...props}
          />
          <Spacer y="2" />
        </Fragment>
      )
    })
  }, [checkboxes, value, props, onChange])

  return (
    <Box justifyContent="center" maxWidth="100%" mb={2} {...layoutProps}>
      <FormLabel label={label} isRequired={isRequired} labelStyle={labelStyle} />
      {checkboxes ? (
        renderCheckboxes
      ) : (
        <CustomCheckbox
          {...(typeof value === 'boolean' && { isChecked: value })}
          {...{ checkboxText, isRequired, onChange, value, ...checkBoxProps }}
        />
      )}
    </Box>
  )
}
