import { AxiosRequestConfig } from 'axios'
import i18n from 'i18next'
import { Platform } from 'react-native'
import UAParser from 'ua-parser-js'

const parsedUA = new UAParser(window.navigator.userAgent).getResult()

export const injectUserTelemetryDataToRequest = async (config: AxiosRequestConfig<object>) => {
  const Language = i18n.language

  const platformHeaders = (() => {
    if (Platform.OS === 'web') {
      return {
        'Platform-Type': 'web',
        'Platform-OS': parsedUA.os.name?.toLowerCase(),
        'Platform-Version': parsedUA.os.version,
      }
    }
    return {
      'Platform-Type': 'mobile',
      'Platform-OS': Platform.OS,
      'Platform-Version': Platform.Version,
    }
  })()

  return {
    ...config,
    headers: {
      ...config?.headers,
      Language,
      ...platformHeaders,
    },
  }
}
