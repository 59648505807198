import { Image as ExpoImage } from 'expo-image'
import { Pressable, StyleSheet } from 'react-native'

import languages from '../../../assets/languages.json'
import { Row } from '../atoms/Row'
import { Text } from '../atoms/Text'

import { LOGO } from '~constants'
import { useCallback, useNavigation, useTranslation } from '~hooks'

export const UnauthorizedTopNav = () => {
  const { t, i18n } = useTranslation()
  const { navigate } = useNavigation()

  const navigateToLogin = useCallback(() => {
    navigate('SignIn')
  }, [navigate])

  const navigateToRegister = useCallback(() => {
    navigate('SignUp')
  }, [navigate])

  const changeLanguage = useCallback(() => {
    const currentLanguage = i18n.language.toUpperCase()
    const languageKeys = Object.keys(languages)
    const index = languageKeys.findIndex((language) => language === currentLanguage)
    const nextIndex = languageKeys[index + 1] ? index + 1 : 0
    const nextLanguageKey = languageKeys[nextIndex].toLocaleLowerCase()
    i18n.changeLanguage(nextLanguageKey)
  }, [i18n])

  return (
    <Row alignItems="center" justifyContent="space-between" py={4} px={20}>
      <ExpoImage alt="app logo" contentFit="contain" source={LOGO} style={styles.logo} />
      <Row>
        <Pressable onPress={changeLanguage}>
          <Text.H4 color="white" selectable={false}>
            {i18n.language.toUpperCase()}
          </Text.H4>
        </Pressable>
        <Pressable onPress={navigateToLogin}>
          <Text.H4 color="white" ml="16" selectable={false}>
            {t('common.login')}
          </Text.H4>
        </Pressable>
        <Pressable onPress={navigateToRegister}>
          <Text.H4 color="primaryDark" ml="16" selectable={false}>
            {t('common.join')}
          </Text.H4>
        </Pressable>
      </Row>
    </Row>
  )
}

const styles = StyleSheet.create({
  logo: {
    alignSelf: 'center',
    height: 65,
    width: 150,
  },
})
