import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ApiError, deleteRefreshToken, deleteToken, signOutRequest } from '~services'
import { SignOutReturnData } from '~services/requests/signOutRequest'

export const useSignOutMutation = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<SignOutReturnData | null, ApiError, boolean>(
    async (skipRequest) => {
      if (skipRequest) return new Promise((resolve) => resolve(null))
      const response = await signOutRequest()
      return response
    },
    {
      onSettled: async () => {
        await deleteToken()
        await deleteRefreshToken()
        queryClient.clear()
      },
    }
  )

  return { signOutMutation: mutate, isLoading }
}
