import { BarCodeScanner, BarCodeScannedCallback, BarCodeEvent } from 'expo-barcode-scanner'
import React, { useCallback, useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { Box, Icon, QrCodeFrame, Text, Pressable, PressableRef, Row } from '~components'
import { theme } from '~constants'
import { DeviceEventEmitterService } from '~services'
import { validateBtcAddress, validateEthAddress } from '~utils'

export const QRScannerScreen = ({ navigation: { goBack } }: QRScannerScreenProps) => {
  const [qrCode, setQrCode] = useState<{
    barcode: BarCodeEvent | null
    code?: string
    isCryptoAddress?: boolean
  } | null>(null)

  const handleBarCodeScanned: BarCodeScannedCallback = useCallback(async (barcode) => {
    if (barcode?.data) {
      const isBtcAddress = validateBtcAddress(barcode?.data)
      const isEthAddress = validateEthAddress(barcode?.data)
      if (isBtcAddress || isEthAddress) {
        setQrCode({ barcode, code: barcode.data, isCryptoAddress: true })
        return
      }
      setQrCode({ barcode, isCryptoAddress: false })
    } else {
      setQrCode((prev) => ({ ...prev, barcode: null }))
    }
  }, [])

  const onPress = useCallback(() => {
    if (qrCode?.isCryptoAddress && qrCode?.code) {
      DeviceEventEmitterService?.emit?.('handleCryptoAddress', {
        address: qrCode?.code,
        isEthAddress: validateEthAddress(qrCode?.code),
      })
    } else {
      setQrCode(null)
    }
  }, [qrCode?.code, qrCode?.isCryptoAddress])

  useEffect(() => {
    return () => {
      DeviceEventEmitterService?.removeAllListeners('handleCryptoAddress')
    }
  }, [])

  return (
    <View style={styles.container}>
      <BarCodeScanner
        barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
        onBarCodeScanned={handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />
      <QrCodeFrame />
      {qrCode?.barcode?.data ? (
        <Box position="absolute" bottom={100} alignSelf="center" ml={4} mr={4} zIndex={10}>
          <Pressable alignItems="center" onPress={onPress}>
            {({ isPressed }: PressableRef) => {
              return (
                <Row
                  alignItems="center"
                  bg={
                    isPressed
                      ? qrCode?.isCryptoAddress
                        ? 'success.400'
                        : 'warning.400'
                      : qrCode?.isCryptoAddress
                      ? 'success.300'
                      : 'warning.300'
                  }
                  borderRadius={8}
                  flexGrow={1}
                  p={4}
                >
                  <Text numberOfLines={1} ml={4} mr={4}>
                    {qrCode?.isCryptoAddress
                      ? qrCode?.barcode?.data
                      : `It's not valid crypto address`}
                  </Text>
                  <Icon
                    name={qrCode?.isCryptoAddress ? 'arrow-right-line' : 'close-line'}
                    size={24}
                    color={qrCode?.isCryptoAddress ? 'green.600' : 'warning.600'}
                  />
                </Row>
              )
            }}
          </Pressable>
        </Box>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  container: { backgroundColor: theme.light.colors.brand_dark, flex: 1, position: 'relative' },
})
