type ErrorObjectInput<Input> = {
  [key in keyof Input]: string[]
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function getFormErrorEntries<T>(obj: any) {
  return Object.entries(obj) as Entries<ErrorObjectInput<T>>
}
