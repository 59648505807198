export enum TRANSACTION_STATUS {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  PENDING = 'Pending',
}

export enum TRANSACTION_TYPE {
  INCOMING = 'Receiving',
  OUTGOING = 'Sending',
}
