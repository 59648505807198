import { useMutation } from '@tanstack/react-query'

import { useTranslation } from '~hooks'
import { changeEmailRequest } from '~services'
import { getToast, noop } from '~utils'

const { showSuccessToast } = getToast()

export const useChangeEmailMutation = () => {
  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation(changeEmailRequest, {
    onSuccess: (_, values) => {
      showSuccessToast(t('change_email_address_screen.we_have_sent', { email: values.newEmail }))
    },
    onError: noop,
  })

  return { changeEmailMutation: mutate, isChangeEmailLoading: isLoading }
}
