import { apiClient } from '~services/api'

export type SignOutReturnData = {
  code: string
  message: string
  status: number
  data: {
    email: string
  }
}

export const signOutRequest = async (): Promise<SignOutReturnData> =>
  await apiClient.post<void, SignOutReturnData>('/auth/logout')
