export const replaceComaWithDot = (s: string) => s.replace(',', '.')

export const truncateWalletAddress = (address: string, startLength = 9, endLength = 6) => {
  let truncatedAddress = ''

  if (address.length > startLength + endLength) {
    truncatedAddress =
      address.substring(0, startLength) + '...' + address.substring(address.length - endLength)
  } else {
    truncatedAddress = address
  }

  return truncatedAddress
}
