import { StyleSheet } from 'react-native'

import {
  Box,
  Button,
  Center,
  ControlledField,
  ExpoImageBackground,
  Spacer,
  Text,
  Row,
  UnAuthorizeWrapper,
  ExpoImage,
} from '~components'
import { LOGIN_IMAGE, LOGO_DARK, REGEX } from '~constants'
import { useCallback, useSignInForm, useTranslation, useWeb } from '~hooks'

export const SignInScreen = ({ navigation: { navigate } }: SignInScreenProps): JSX.Element => {
  const { t } = useTranslation()
  const { shouldApplyDesktopStyles } = useWeb()

  const { control, errors, isSubmitting, setFocus, submit } = useSignInForm()

  const navigateToSignUp = useCallback(() => navigate('SignUp'), [navigate])
  const navigateToResetPassword = useCallback(() => navigate('ResetPassword'), [navigate])
  const focusPasswordInput = useCallback(() => setFocus('password'), [setFocus])

  return (
    <UnAuthorizeWrapper stretchWidth>
      <Row borderRadius={8} overflow="hidden">
        <Box bg="white" flex={1} p={4}>
          {shouldApplyDesktopStyles && (
            <>
              <Spacer y="5" />
              <ExpoImage
                alt="app logo dark"
                contentFit="contain"
                source={LOGO_DARK}
                style={styles.logoDark}
              />
              <Spacer y="12" />
            </>
          )}
          <Text.H4 mb={4}>{t('sign_in_screen.login')}</Text.H4>
          <ControlledField.Input
            {...{ control, errors }}
            autoCapitalize="none"
            keyboardType="email-address"
            label={t('form.labels.email')}
            name="email"
            onSubmitEditing={focusPasswordInput}
            placeholder={t('form.placeholders.email')}
            returnKeyType="next"
            rules={{
              required: t('form.errors.required'),
              pattern: {
                value: REGEX.EMAIL,
                message: t('form.errors.invalid_email_format'),
              },
            }}
            testID="emailInput"
          />
          <ControlledField.Input
            {...{ control, errors }}
            autoCapitalize="none"
            label={t('form.labels.password')}
            mt={4}
            name="password"
            onSubmitEditing={submit}
            placeholder={t('form.placeholders.password')}
            returnKeyType="send"
            rules={{
              required: t('form.errors.required'),
            }}
            testID="passwordInput"
            type="password"
          />
          <Center>
            <Row alignItems="center" mb={6} mt={4} flexWrap={'wrap'}>
              <Text.Body>{t('sign_in_screen.forgot_password')}</Text.Body>
              <Spacer x="4" />
              <Button
                disabled={isSubmitting}
                onPress={navigateToResetPassword}
                style={styles.resendPasswordButton}
                variant="Ghost"
              >
                {t('sign_in_screen.send_reset_link')}
              </Button>
            </Row>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              onPress={submit}
              testID="signInButton"
              width="100%"
            >
              {t('sign_in_screen.sign_in')}
            </Button>
            <Button
              disabled={isSubmitting}
              mt={2}
              onPress={navigateToSignUp}
              variant="Ghost"
              width="100%"
            >
              {t('sign_in_screen.sign_up')}
            </Button>
          </Center>
        </Box>
        {shouldApplyDesktopStyles && (
          <ExpoImageBackground
            alt="treasures"
            contentFit="cover"
            source={LOGIN_IMAGE}
            style={styles.webBgImage}
          >
            <Box bg="brand_dark" flex={1} justifyContent="center" opacity={0.7} p={8}>
              <Text.Bold color="tertiary.500" mb={4}>
                {t('common.join')}
              </Text.Bold>
              <Text.H3Bold color="white" maxWidth={500}>
                {t('sign_in_screen.your_wallet_keeps')}
              </Text.H3Bold>
            </Box>
          </ExpoImageBackground>
        )}
      </Row>
    </UnAuthorizeWrapper>
  )
}

const styles = StyleSheet.create({
  logoDark: {
    alignSelf: 'center',
    height: 50,
    width: 120,
  },
  resendPasswordButton: {
    paddingHorizontal: 0,
  },
  webBgImage: {
    flex: 2,
  },
})
