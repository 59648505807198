import { useCallback, useMemo } from 'react'
import { FlatList, ListRenderItem, StyleSheet } from 'react-native'

import { Box } from '../components/atoms/Box'
import { Divider } from '../components/atoms/Divider'
import { Row } from '../components/atoms/Row'
import { Text } from '../components/atoms/Text'

import {
  Background,
  ListEmptyComponentBase,
  Loader,
  Spacer,
  SwitchButton,
  TransactionCoinItem,
  WalletActionsCard,
} from '~components'
import {
  breakpoints,
  EMPTY_STATE_ACTIVITIES,
  NETWORKS,
  PLACEHOLDER_TOKEN,
  TOKENS,
} from '~constants'
import { useBoolean, useTranslation } from '~hooks'
import { useGetAssetsListQuery, useGetTokenTransactionHistoryQuery } from '~query-hooks'
import { TransactionHistory } from '~services'
import { convertToEuro } from '~utils'

const keyExtractor = (item: TransactionHistory) => String(item.transactionHash)

const ItemSeparatorComponent = () => (
  <Box px={4} bg="white">
    <Divider />
  </Box>
)
const ListEmptyComponent = () => {
  const { t } = useTranslation()
  return (
    <ListEmptyComponentBase
      emptyText={t('home_screen.no_activities')}
      source={EMPTY_STATE_ACTIVITIES}
    />
  )
}

export const TokenDetailsScreen = ({
  route: {
    params: { tokenShortName },
  },
}: TokenDetailsScreenProps) => {
  const { t } = useTranslation()
  const [isSeeMore, setIsSeeMore] = useBoolean(false)

  const { assetsList } = useGetAssetsListQuery()
  const { tokenTransactionHistoryList, isInitialLoadingTokenTransactionHistoryList } =
    useGetTokenTransactionHistoryQuery({ shortName: tokenShortName })

  const currentAsset = useMemo(() => {
    return assetsList.find((asset) => asset.tokenShortName === tokenShortName)
  }, [assetsList, tokenShortName])

  const filteredTransactionItems = useMemo(
    () => tokenTransactionHistoryList?.slice(0, 3),
    [tokenTransactionHistoryList]
  )

  const data = useMemo(
    () => (isSeeMore ? tokenTransactionHistoryList : filteredTransactionItems),
    [filteredTransactionItems, isSeeMore, tokenTransactionHistoryList]
  )

  const ListHeaderComponent = useCallback(() => {
    const showOnlyToken = currentAsset?.networkLabel === currentAsset?.tokenFullName

    return (
      <>
        <Spacer y="6" />
        <WalletActionsCard
          token={currentAsset?.tokenFullName}
          tokenAbbreviation={tokenShortName}
          networkImage={
            currentAsset?.networkLabel && !showOnlyToken
              ? NETWORKS[currentAsset?.networkLabel]
              : undefined
          }
          tokenImage={TOKENS[tokenShortName] || PLACEHOLDER_TOKEN}
          title={
            showOnlyToken
              ? currentAsset?.tokenFullName
              : currentAsset?.tokenFullName + ' • ' + currentAsset?.networkLabel
          }
          valueInEuro={convertToEuro(currentAsset?.value, currentAsset?.cryptoToEuroRate)}
          value={currentAsset?.value}
          isToken
        />
        <Spacer y="4" />
        <Box bg="white" borderTopEndRadius={8} borderTopLeftRadius={8} p={4}>
          <Row alignItems="center" justifyContent="space-between">
            <Text.Bold color="muted.500">{t('home_screen.recent_activities')}</Text.Bold>
            <SwitchButton isActive={isSeeMore} onPress={setIsSeeMore.toggle} />
          </Row>
        </Box>
      </>
    )
  }, [
    currentAsset?.cryptoToEuroRate,
    currentAsset?.networkLabel,
    currentAsset?.tokenFullName,
    currentAsset?.value,
    isSeeMore,
    setIsSeeMore.toggle,
    t,
    tokenShortName,
  ])

  const renderItem: ListRenderItem<TransactionHistory> = useCallback(
    ({ item, index }) => {
      return (
        <TransactionCoinItem
          borderBottomEndRadius={!data?.[index + 1] ? 8 : 0}
          borderBottomLeftRadius={!data?.[index + 1] ? 8 : 0}
          {...{ cryptoToEuroRate: currentAsset?.cryptoToEuroRate }}
          {...item}
        />
      )
    },
    [currentAsset?.cryptoToEuroRate, data]
  )

  return (
    <Background>
      {isInitialLoadingTokenTransactionHistoryList ? (
        <Loader type="bubbles" />
      ) : (
        <FlatList
          contentContainerStyle={styles.contentContainerStyle}
          showsVerticalScrollIndicator={false}
          {...{
            data,
            keyExtractor,
            ItemSeparatorComponent,
            ListEmptyComponent,
            ListHeaderComponent,
            renderItem,
          }}
        />
      )}
    </Background>
  )
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    alignSelf: 'center',
    flexGrow: 1,
    maxWidth: breakpoints.lg,
    paddingBottom: 16,
    paddingHorizontal: 16,
    width: '100%',
  },
})
