import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'

import { KYC_STATUS, QueryKeys } from '~enums'
import { getVideoVerificationStatusRequest } from '~services'

export const useGetVideoVerificationStatusQuery = ({ id = '' }: { id?: string }) => {
  const [kycStatus, setKycStatus] = useState<KYC_STATUS | undefined>()

  const request = useCallback(() => getVideoVerificationStatusRequest({ id }), [id])

  const {
    data,
    isInitialLoading: isInitialLoadingVideoVerificationStatus,
    refetch: refetchVideoVerificationStatus,
  } = useQuery([QueryKeys.VideoVerificationStatus, id], request, {
    enabled: !!id && kycStatus !== KYC_STATUS.SUCCESS,
    refetchInterval: kycStatus === KYC_STATUS.PENDING ? 5000 : 0,
    retry: false,
  })

  useEffect(() => {
    if (data?.result !== kycStatus) {
      setKycStatus(data?.result)
    }
  }, [data?.result, kycStatus])

  return {
    videoVerificationStatus: data?.result,
    isInitialLoadingVideoVerificationStatus,
    refetchVideoVerificationStatus,
  }
}
