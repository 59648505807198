import { useNavigation } from '@react-navigation/native'
import { isError } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useMeQuery, useUpdateUserDataMutation } from '~query-hooks'
import { UpdateRegistrationAddressFormValues } from '~types/authForms'
import { hapticImpact } from '~utils'

export const defaultValuesRegistrationAddress: UpdateRegistrationAddressFormValues = {
  country: '',
  city: '',
  postalCode: '',
  street: '',
  buildingNumber: '',
  apartment: '',
  floor: '',
}

export const useRegistrationAddressForm = () => {
  const { t } = useTranslation()
  const { goBack } = useNavigation()

  const { isLoadingUserData, userData } = useMeQuery()
  const { updateUserDataMutation } = useUpdateUserDataMutation({
    successMessage: t('toast.success.registration_address_updated'),
  })

  const [areDefaultValuesSet, setAreDefaultValuesSet] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError: setFormError,
    setFocus,
    setValue,
    watch,
    trigger,
  } = useForm<UpdateRegistrationAddressFormValues>({
    mode: 'onTouched',
    defaultValues: defaultValuesRegistrationAddress,
  })

  useEffect(() => {
    if (!isLoadingUserData && userData?.registrationAddress && !areDefaultValuesSet) {
      reset(userData?.registrationAddress)
      setAreDefaultValuesSet(true)
    }
  }, [areDefaultValuesSet, isLoadingUserData, reset, userData?.registrationAddress])

  const onSubmit = async (registrationAddress: UpdateRegistrationAddressFormValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      await updateUserDataMutation({ registrationAddress })
      goBack()
    } catch (e) {
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    control,
    error,
    errors,
    handleSubmit,
    isSubmitting,
    setFocus,
    setFormError,
    setIsSubmitting,
    setValue,
    submit: handleSubmit(onSubmit),
    trigger,
    watch,
  }
}
