import { Box, Button, ControlledField, LightScreenWrapper, Row, Spacer } from '~components'
import { COUNTRIES, REGEX, isWeb } from '~constants'
import { useTranslation, useCompanyDetailsForm, useCallback, useWeb } from '~hooks'
import { hideKeyboard } from '~utils'

export const CompanyDetailsScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { shouldApplyDesktopStyles } = useWeb()
  const { control, errors, isSubmitting, setFocus, submit } = useCompanyDetailsForm()

  const setFocusLegalForm = useCallback(() => {
    setFocus('legalForm')
  }, [setFocus])

  const setFocusCRN = useCallback(() => {
    setFocus('crn')
  }, [setFocus])

  const setFocusLEI = useCallback(() => {
    setFocus('lei')
  }, [setFocus])

  const setFocusTaxId = useCallback(() => {
    setFocus('taxId')
  }, [setFocus])

  const setFocusISIN = useCallback(() => {
    setFocus('isin')
  }, [setFocus])

  const setFocusVAT = useCallback(() => {
    setFocus('vat')
  }, [setFocus])

  const setFocusCity = useCallback(() => {
    !isWeb && setFocus('city')
  }, [setFocus])

  const setFocusCountry = useCallback(() => {
    hideKeyboard()
  }, [])

  const setFocusPostalCode = useCallback(() => {
    setFocus('postalCode')
  }, [setFocus])

  const setFocusStreet = useCallback(() => {
    setFocus('street')
  }, [setFocus])

  const setFocusBuildingNumber = useCallback(() => {
    setFocus('buildingNumber')
  }, [setFocus])

  const setFocusApartment = useCallback(() => {
    setFocus('apartment')
  }, [setFocus])

  const setFocusFloor = useCallback(() => {
    setFocus('floor')
  }, [setFocus])

  return (
    <LightScreenWrapper iconName="bank-line" title={t('navigation.screen_titles.company_details')}>
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        isRequired
        label={t('form.labels.company_name')}
        mb={2}
        name="companyName"
        onSubmitEditing={setFocusLegalForm}
        placeholder={t('form.placeholders.company_name')}
        returnKeyType="next"
        rules={{
          required: t('form.errors.required'),
          pattern: {
            value: REGEX.MAX_LENGTH_64,
            message: t('form.errors.maximum_length', { max_length: 64 }),
          },
        }}
      />
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          isRequired
          label={t('form.labels.legal_form')}
          mb={2}
          name="legalForm"
          onSubmitEditing={setFocusCRN}
          placeholder={t('form.placeholders.legal_form')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.MAX_LENGTH_64,
              message: t('form.errors.maximum_length', { max_length: 64 }),
            },
          }}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.commercial_register_number')}
          mb={2}
          name="crn"
          onSubmitEditing={setFocusLEI}
          placeholder={t('form.placeholders.commercial_register_number')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
      </Box>
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.legal_entity_identifier')}
          mb={2}
          name="lei"
          onSubmitEditing={setFocusTaxId}
          placeholder={t('form.placeholders.legal_entity_identifier')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.tax_identification_number')}
          mb={2}
          name="TaxID"
          onSubmitEditing={setFocusISIN}
          placeholder={t('form.placeholders.tax_identification_number')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
      </Box>
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.international_securities_identification_number')}
          mb={2}
          name="isin"
          onSubmitEditing={setFocusVAT}
          placeholder={t('form.placeholders.international_securities_identification_number')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          label={t('form.labels.vat')}
          mb={2}
          name="vat"
          onSubmitEditing={setFocusCountry}
          placeholder={t('form.placeholders.vat')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_32,
              message: t('form.errors.maximum_length', { max_length: 32 }),
            },
          }}
        />
      </Box>
      <Box flexGrow={1} flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Select
          {...{ control, errors }}
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          items={COUNTRIES}
          isRequired
          label={t('form.labels.country')}
          maxSelectedItems={1}
          name="country"
          onPick={setFocusCity}
          placeholder={t('form.placeholders.company_country')}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="characters"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          isRequired
          label={t('form.labels.city')}
          mb={2}
          name="city"
          onSubmitEditing={setFocusPostalCode}
          placeholder={t('form.placeholders.city')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
              message: t('form.errors.special_characters', { max_length: 64 }),
            },
          }}
        />
      </Box>
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Input
          {...{ control, errors }}
          autoComplete="name"
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          isRequired
          label={t('form.labels.postal_code')}
          mb={2}
          name="postalCode"
          onSubmitEditing={setFocusStreet}
          placeholder={t('form.placeholders.postal_code')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.SPECIAL_CHARACTERS_16_NOT_ALLOWED,
              message: t('form.errors.special_characters', { max_length: 16 }),
            },
          }}
        />
        <Spacer x="4" />
        <ControlledField.Input
          {...{ control, errors }}
          autoComplete="name"
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          isRequired
          label={t('form.labels.street')}
          mb={2}
          name="street"
          onSubmitEditing={setFocusBuildingNumber}
          placeholder={t('form.placeholders.street')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.MAX_LENGTH_128,
              message: t('form.errors.maximum_length', { max_length: 128 }),
            },
          }}
        />
      </Box>
      <Row>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          autoComplete="name"
          flex={shouldApplyDesktopStyles ? 1 : 1.5}
          isRequired
          label={t('form.labels.building_number')}
          labelNumberOfLines={1}
          mb={2}
          name="buildingNumber"
          onSubmitEditing={setFocusApartment}
          placeholder={t('form.placeholders.building_number')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.MAX_LENGTH_16,
              message: t('form.errors.maximum_length', { max_length: 16 }),
            },
          }}
        />
        <Spacer x="2" />
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          autoComplete="name"
          flex={1}
          keyboardType="number-pad"
          label={t('form.labels.apartment')}
          labelNumberOfLines={1}
          mb={2}
          name="apartment"
          onSubmitEditing={setFocusFloor}
          placeholder={t('form.placeholders.apartment')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_16,
              message: t('form.errors.maximum_length', { max_length: 16 }),
            },
          }}
        />
        <Spacer x="2" />
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          autoComplete="name"
          flex={1}
          keyboardType="number-pad"
          label={t('form.labels.floor')}
          labelNumberOfLines={1}
          mb={2}
          name="floor"
          onSubmitEditing={hideKeyboard}
          placeholder={t('form.placeholders.floor')}
          returnKeyType="default"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_16,
              message: t('form.errors.maximum_length', { max_length: 16 }),
            },
          }}
        />
      </Row>
      <Button disabled={isSubmitting} loading={isSubmitting} mt={4} onPress={submit}>
        {t('common.save_changes')}
      </Button>
      <Spacer y="6" />
    </LightScreenWrapper>
  )
}
