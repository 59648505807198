import decode from 'jwt-decode'

import { DecodedToken } from '~types/token'

export const decodeAccessToken = (token: string) => {
  if (token === 'NOT_FOUND' || !token) {
    return undefined
  }

  return decode<DecodedToken>(token)
}
