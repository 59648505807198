import { FlagType, getAllCountries } from 'react-native-country-picker-modal'

const INITIAL_PHONE_COUNTRY_CODE = 'DE'

export const getInitialCountryCode = async (phonePrefix: string | null) => {
  if (!phonePrefix) return INITIAL_PHONE_COUNTRY_CODE

  const countries = await getAllCountries(FlagType.EMOJI)
  const initialCountry = countries.find(({ callingCode }) => callingCode.includes(phonePrefix))

  return initialCountry?.cca2 || INITIAL_PHONE_COUNTRY_CODE
}
