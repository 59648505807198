import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageSourcePropType } from 'react-native'

import { Box, BoxProps, Pressable, Row, Text } from '../atoms'
import { TokenAndNetworkLogo } from './TokenAndNetworkLogo'

import { TRANSACTION_TYPE } from '~enums'
import { TranslationKeys } from '~i18n'
import { TransactionHistory } from '~services'
import {
  convertToEuro,
  formatToLongDateWithTime,
  openTransactionDetails,
  renderMaxDecimalPlaces,
} from '~utils'

export type TransactionItemProps = {
  cryptoToEuroRate: number
  networkImage: ImageSourcePropType
  tokenImage: ImageSourcePropType
} & BoxProps &
  TransactionHistory

export const TransactionItem = ({
  createdAt,
  cryptoToEuroRate,
  fullName,
  networkImage,
  networkLabel,
  shortName,
  tokenImage,
  transactionHash = '',
  transactionType,
  value,
  ...props
}: TransactionItemProps) => {
  const { i18n } = useTranslation()
  const locale = (i18n.language.slice(0, 2).toLowerCase() || 'en') as TranslationKeys

  const navigateToTransactionDetails = useCallback(
    () =>
      openTransactionDetails({
        networkLabel,
        transactionHash,
      }),
    [networkLabel, transactionHash]
  )

  const tokenAndNetworkName = fullName === networkLabel ? fullName : fullName + ' • ' + networkLabel

  return (
    <Pressable onPress={navigateToTransactionDetails}>
      <Row alignItems="center" bg="white" justifyContent="space-between" p={4} {...props}>
        <TokenAndNetworkLogo
          {...{ networkImage, tokenImage }}
          showOnlyToken={fullName === networkLabel}
        />
        <Box flex={1} ml={4} mr={2}>
          <Box flex={1} justifyContent="center">
            <Text.Bold color="brand_dark">{tokenAndNetworkName}</Text.Bold>
          </Box>

          {createdAt ? (
            <Text.Caption color="coolGray.400" mt={2}>
              {formatToLongDateWithTime(createdAt, locale)}
            </Text.Caption>
          ) : null}
        </Box>
        <Box>
          <Text.Bold
            color={transactionType === TRANSACTION_TYPE.INCOMING ? 'success.600' : 'muted.400'}
            textAlign="right"
          >
            €{convertToEuro(value, cryptoToEuroRate)}
          </Text.Bold>
          {typeof value === 'number' && (
            <Text.Caption color="coolGray.400" mt={2} textAlign="right">
              {renderMaxDecimalPlaces(value)} {shortName}
            </Text.Caption>
          )}
        </Box>
      </Row>
    </Pressable>
  )
}
