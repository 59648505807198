import { apiClient } from '~services/api'

type WalletStatus = {
  ethWallet: {
    address: string
    balance: string
    currency: 'ETH'
    nonce: string
  }
  btcWallet: {
    balance: string
    address: string
    currency: 'BTC'
    utxo: number
  }
}

export const getWalletStatusRequest = async ({ walletName }: { walletName: string }) => {
  const response = await apiClient.get<WalletStatus, WalletStatus>(
    `/tangany/wallets-status/${walletName}`
  )

  return response
}
