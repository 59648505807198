import { apiClient } from '~services/api'

export type CreateVideoVerificationData =
  | {
      id: string
      link_to_video_ident: string
    }
  | { message: string }

export const createVideoVerificationRequest = async () => {
  return await apiClient.post<CreateVideoVerificationData, CreateVideoVerificationData>(
    '/idnow/create-video-verification'
  )
}
