import { forwardRef, useCallback, useImperativeHandle, useRef, useMemo } from 'react'
import {
  NativeSyntheticEvent,
  Pressable,
  TextInput,
  TextInputFocusEventData,
  View,
} from 'react-native'

import { FormErrorMessage, FormLabel, Input as BaseInput, Box } from '../../atoms'
import { useHover } from '../../atoms/Touchables/useHover'
import type { FieldInputProps } from './types'

const layoutPropsKeys = [
  'flex',
  'm',
  'margin',
  'mt',
  'marginTop',
  'mr',
  'marginRight',
  'mb',
  'marginBottom',
  'ml',
  'marginLeft',
  'mx',
  'my',
  'p',
  'padding',
  'pt',
  'paddingTop',
  'pr',
  'paddingRight',
  'pb',
  'paddingBottom',
  'pl',
  'paddingLeft',
  'px',
  'py',
  'width',
]

export const Input = forwardRef<Partial<TextInput>, FieldInputProps>(
  (
    {
      isDisabled,
      isRequired,
      isInvalid,
      label,
      helperText,
      errorMessage,
      onFocus,
      onBlur,
      labelNumberOfLines,
      labelStyle,
      ...props
    },
    ref
  ) => {
    const _inputRef = useRef<TextInput>(null)

    const layoutProps = useMemo(
      () =>
        Object.fromEntries(Object.entries(props).filter(([key]) => layoutPropsKeys.includes(key))),
      [props]
    )
    const inputProps = useMemo(
      () =>
        Object.fromEntries(Object.entries(props).filter(([key]) => !layoutPropsKeys.includes(key))),
      [props]
    )

    const handleFocus = useCallback(() => {
      if (!isDisabled) {
        onFocus?.()
        _inputRef?.current?.focus()
      }
    }, [isDisabled, onFocus])

    const handleBlur = useCallback(
      (e?: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onBlur && e && onBlur(e)
        _inputRef.current?.blur()
      },
      [onBlur]
    )

    const _pressableRef = useRef<View>(null)
    const { isHovered, hoverProps } = useHover()

    const handleHoverIn = useCallback(
      () => {
        hoverProps.onHoverIn()
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hoverProps.onHoverIn]
    )

    const handleHoverOut = useCallback(
      () => {
        hoverProps.onHoverOut()
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hoverProps.onHoverOut]
    )

    useImperativeHandle(
      ref,
      () => ({
        focus: handleFocus,
        blur: handleBlur,
        ..._inputRef.current,
      }),
      [handleBlur, handleFocus]
    )

    return (
      <Box width="100%" mb="2" {...layoutProps}>
        <Pressable
          onHoverIn={handleHoverIn}
          onHoverOut={handleHoverOut}
          onPress={handleFocus}
          focusable={false}
          ref={_pressableRef}
        >
          <FormLabel {...{ isRequired, label, labelNumberOfLines, labelStyle }} />
          <BaseInput
            isInvalid={isInvalid || Boolean(errorMessage)}
            {...{ isDisabled, isHovered, ...inputProps }}
            ref={_inputRef}
          />
          <FormErrorMessage errorMessage={errorMessage} />
        </Pressable>
      </Box>
    )
  }
)
