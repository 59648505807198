import { t } from 'i18next'
import { StyleSheet } from 'react-native'

import { DeleteAccountModal } from './DeleteAccountModal'

import { Button } from '~components'
import { useToggle } from '~hooks'

export const DeleteAccountItem = () => {
  const [showModal, toggleShowModal] = useToggle(false)

  return (
    <>
      <Button onPress={toggleShowModal} style={styles.button} variant="Delete">
        {t('settings_screen.delete_account')}
      </Button>
      <DeleteAccountModal onClose={toggleShowModal} visible={showModal} />
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    marginTop: 'auto',
  },
})
