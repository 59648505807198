import { isError } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../useAuth'

import { SignInFormValues } from '~types/authForms'
import { hapticImpact, navigationRef } from '~utils'

const defaultValues: SignInFormValues = {
  // TODO: Reset this values when building production app
  email: __DEV__ ? 'm.baumruck+walluta@binarapps.com' : '',
  password: __DEV__ ? '12!@qwQW' : '',
}

export const useSignInForm = () => {
  const { signIn } = useAuth()
  const [error, setError] = useState('')
  const { t } = useTranslation()

  const {
    control,
    formState: { errors, isSubmitting },
    setFocus,
    handleSubmit,
  } = useForm<SignInFormValues>({
    mode: 'onTouched',
    defaultValues,
  })

  const onSubmit = async (data: SignInFormValues) => {
    try {
      setError('')
      await signIn(data, {
        onError: (err, { email }) => {
          if (err?.error === 'EmailNotVerified') {
            navigationRef.navigate('ActivateAccount', {
              email,
            })
          }
        },
      })
    } catch (e) {
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    }
  }

  return {
    control,
    error,
    errors,
    isSubmitting,
    setFocus,
    submit: handleSubmit(onSubmit),
  }
}
