import { useMemo } from 'react'

import { TRANSACTION_STATUS } from '~enums'
import { useGetAssetsListQuery, useGetTransactionHistoryQuery } from '~query-hooks'
import { calculateWalletValueInEuro } from '~utils'

const MAX_WALLET_VALUE_IN_EURO = 5

type DeleteAccountStatus = 'ok' | 'pendingTransactions' | 'excessiveBalance' | undefined

interface UseCanDeleteAccountProps {
  enabled: boolean
}

interface UseCanDeleteAccountReturn {
  canDeleteAccount: boolean
  status: DeleteAccountStatus
  isLoading: boolean
  error: string | undefined
}

export const useCanDeleteAccount = ({
  enabled,
}: UseCanDeleteAccountProps): UseCanDeleteAccountReturn => {
  const {
    transactionHistoryList,
    isInitialLoadingTransactionHistoryList,
    transactionHistoryListError,
  } = useGetTransactionHistoryQuery({ enabled })
  const { assetsList, isInitialLoadingAssetsList, assetsListError } = useGetAssetsListQuery({
    enabled,
  })

  const isLoading = isInitialLoadingTransactionHistoryList || isInitialLoadingAssetsList
  const error = transactionHistoryListError?.message || assetsListError?.message

  const walletValueInEuro = useMemo(() => calculateWalletValueInEuro({ assetsList }), [assetsList])

  const status = useMemo((): DeleteAccountStatus => {
    if (isLoading || error) return undefined
    if (
      transactionHistoryList?.find(
        (transaction) => transaction.status === TRANSACTION_STATUS.PENDING
      )
    )
      return 'pendingTransactions'
    if (walletValueInEuro > MAX_WALLET_VALUE_IN_EURO) return 'excessiveBalance'
    return 'ok'
  }, [error, isLoading, transactionHistoryList, walletValueInEuro])

  return {
    canDeleteAccount: status === 'ok',
    status,
    error,
    isLoading,
  }
}
