import { LinearGradient } from 'expo-linear-gradient'
import { useCallback, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Button } from '../atoms/Button'
import { Row } from '../atoms/Row'
import { Text } from '../atoms/Text'

import { COMMUNICATION_CARD_TYPE } from '~enums'
import { useNavigation, useTheme, useTranslation, useWeb } from '~hooks'
import { useCreateVideoVerificationMutation } from '~query-hooks'
import { getToast, openBuyCrypto, openUrlInNewTab } from '~utils'

type CommunicationCardProps = {
  communicationCardType: COMMUNICATION_CARD_TYPE
}

const { showWarningToast } = getToast()

export const CommunicationCard = ({ communicationCardType }: CommunicationCardProps) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { colors } = useTheme()
  const { shouldApplyDesktopStyles } = useWeb()

  const { createVideoVerificationMutation } = useCreateVideoVerificationMutation()

  const onButtonPress = useCallback(async () => {
    switch (communicationCardType) {
      case COMMUNICATION_CARD_TYPE.COMPLETE_PROFILE:
        navigate('Profile')
        break
      case COMMUNICATION_CARD_TYPE.COMPLETE_KYC:
      case COMMUNICATION_CARD_TYPE.KYC_FAILED:
        try {
          const response = await createVideoVerificationMutation()
          if ('link_to_video_ident' in response) {
            openUrlInNewTab({ url: response.link_to_video_ident })
          } else {
            showWarningToast(response.message)
          }
        } catch (e) {
          console.log('Can not generate KYC link', e)
        }
        break
      case COMMUNICATION_CARD_TYPE.ENABLE_2FA:
        navigate('Settings')
        break
      case COMMUNICATION_CARD_TYPE.DISCOVER_MONDOCOIN:
        openBuyCrypto()
        break
      case COMMUNICATION_CARD_TYPE.KYC_PENDING:
        return undefined
    }
  }, [communicationCardType, navigate, createVideoVerificationMutation])

  const subtitle = useMemo(() => {
    switch (communicationCardType) {
      default:
        return t('home_screen.new_member_guide')
    }
  }, [t, communicationCardType])

  const title = useMemo(() => {
    switch (communicationCardType) {
      case COMMUNICATION_CARD_TYPE.COMPLETE_PROFILE:
        return t('home_screen.complete_profile_for_benefits')
      case COMMUNICATION_CARD_TYPE.COMPLETE_KYC:
        return t('home_screen.complete_KYC_to_unlock')
      case COMMUNICATION_CARD_TYPE.KYC_PENDING:
        return t('home_screen.kyc_pending')
      case COMMUNICATION_CARD_TYPE.KYC_FAILED:
        return t('home_screen.kyc_failed')
      case COMMUNICATION_CARD_TYPE.ENABLE_2FA:
        return t('home_screen.enable_2FA_to_secure')
      case COMMUNICATION_CARD_TYPE.DISCOVER_MONDOCOIN:
        return t('home_screen.discover_mondo_coin')
    }
  }, [t, communicationCardType])

  const buttonTitle = useMemo(() => {
    switch (communicationCardType) {
      case COMMUNICATION_CARD_TYPE.COMPLETE_PROFILE:
        return t('home_screen.complete_profile')
      case COMMUNICATION_CARD_TYPE.COMPLETE_KYC:
      case COMMUNICATION_CARD_TYPE.KYC_FAILED:
        return t('home_screen.complete_KYC')
      case COMMUNICATION_CARD_TYPE.ENABLE_2FA:
        return t('home_screen.enable_2FA')
      case COMMUNICATION_CARD_TYPE.DISCOVER_MONDOCOIN:
        return t('home_screen.buy_mondo_coin')
      default:
        return undefined
    }
  }, [t, communicationCardType])

  return (
    <LinearGradient
      colors={[colors.secondary[500], colors.primary[500], colors.tertiary[500]]}
      style={styles.gradient}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 1 }}
    >
      <Text.Small color="white" fontWeight="medium">
        {subtitle}
      </Text.Small>
      <Row alignItems="center" justifyContent="space-between">
        <Text.H5Bold color="white" my={4}>
          {title}
        </Text.H5Bold>
        {shouldApplyDesktopStyles && buttonTitle && (
          <Button height={41} onPress={onButtonPress} variant="Secondary">
            {buttonTitle}
          </Button>
        )}
      </Row>
      {!shouldApplyDesktopStyles && buttonTitle && (
        <Button onPress={onButtonPress} mr="auto" px="3" variant="Secondary">
          {buttonTitle}
        </Button>
      )}
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  gradient: { borderRadius: 8, padding: 16 },
})
