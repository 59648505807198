import { StyleSheet, TouchableOpacity } from 'react-native'

import { Box, Icon, Row, Spacer, Text } from '../atoms'

import { BADGE_TYPE } from '~enums'
import { IconNames } from '~types/icon'

export type SettingsItemType = {
  iconColor?: ColorNames
  iconName?: IconNames | null
  iconSize?: number
  isDisabled?: boolean
  label?: string
  onPress?: () => void
  title: string
  badgeType?: BADGE_TYPE
  badgeTitle?: string | false
}

const badgesVariants: {
  [key in BADGE_TYPE]: { badgeBgColor: ColorNames; badgeTextColor: ColorNames }
} = {
  [BADGE_TYPE.ERROR]: { badgeBgColor: 'error.600', badgeTextColor: 'text.50' },
  [BADGE_TYPE.INFO]: { badgeBgColor: 'info.100', badgeTextColor: 'primary.600' },
  [BADGE_TYPE.SUCCESS]: { badgeBgColor: 'green.100', badgeTextColor: 'green.900' },
  [BADGE_TYPE.WARNING]: { badgeBgColor: 'warning.100', badgeTextColor: 'warning.900' },
}

export const SettingsItem = ({
  iconColor = 'primary.500',
  iconName = 'arrow-right-s-line',
  iconSize = 32,
  isDisabled,
  label,
  onPress,
  title,
  badgeType = BADGE_TYPE.WARNING, //TEST INFO
  badgeTitle,
}: SettingsItemType) => {
  const { badgeBgColor, badgeTextColor } = badgesVariants[badgeType]

  return (
    <TouchableOpacity onPress={onPress} style={styles.container} disabled={isDisabled}>
      <Row alignItems="center" flex={1} justifyContent="space-between" py={2}>
        {title && (
          <Text.Subtitle
            color="text.900"
            ellipsizeMode="tail"
            flex={1}
            flexWrap={'nowrap'}
            lineHeight="sm"
            numberOfLines={1}
          >
            {title}
          </Text.Subtitle>
        )}
        {badgeTitle && (
          <Box bg={badgeBgColor} borderRadius={8} py={0.5} px={1}>
            <Text.Small color={badgeTextColor}>{badgeTitle}</Text.Small>
          </Box>
        )}
        {label && (
          <Text.Subtitle color="text.400" lineHeight="sm">
            {label}
          </Text.Subtitle>
        )}
      </Row>
      {iconName && (
        <>
          <Spacer x="4" />
          <Icon color={iconColor} name={iconName} size={iconSize} />
        </>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 8,
    width: '100%',
  },
})
