import { forwardRef, useCallback, useMemo } from 'react'

import { FormErrorMessage, FormLabel, Box, Touchable, Text } from '../../atoms'
import type { TouchableRef } from '../../atoms/Touchables/Touchable'
import { FieldRadioProps } from './types'

export const Radio = forwardRef<TouchableRef, FieldRadioProps>(
  (
    {
      isRequired,
      value,
      radioOptions,
      errorMessage,
      isError,
      onChange,
      label,
      labelStyle,
      isDisabled,
    },
    ref
  ) => {
    const borderColor = useCallback(
      (item: string) =>
        isError
          ? 'red.500'
          : isDisabled
          ? 'gray.400'
          : item === value
          ? 'primary.600'
          : 'muted.400',
      [isError, isDisabled, value]
    )
    const bgColor = useCallback(
      (item: string) => (item === value ? 'primary.600' : 'gray.500'),
      [value]
    )

    const renderRadioButtons = useMemo(
      () =>
        radioOptions?.map((item: string, index: number) => {
          return (
            <Touchable
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
              key={index}
              // eslint-disable-next-line react/jsx-no-bind
              onPress={() => onChange(item)}
              py={3}
              ref={ref}
            >
              <Text.Subtitle ml={4}>{item}</Text.Subtitle>
              <Box
                alignItems="center"
                borderRadius={50}
                borderWidth={2}
                height={7}
                width={7}
                justifyContent="center"
                borderColor={borderColor(item)}
              >
                {item === value ? (
                  <Box borderRadius={50} height={14} width={14} bg={bgColor(item)} />
                ) : null}
              </Box>
            </Touchable>
          )
        }),
      [radioOptions, value, bgColor, borderColor, onChange, ref]
    )

    return (
      <Box width="100%" mb={2}>
        <FormLabel label={label} isRequired={isRequired} labelStyle={labelStyle} />
        {renderRadioButtons}
        <FormErrorMessage errorMessage={errorMessage} />
      </Box>
    )
  }
)
