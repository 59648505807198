import { StackHeaderProps } from '@react-navigation/stack'
import { Image as ExpoImage } from 'expo-image'
import { useCallback } from 'react'
import { StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import { Box } from '../atoms/Box'
import { Icon } from '../atoms/Icon'
import { Pressable } from '../atoms/Touchables/Pressable'

import { LOGO, LOGO_DARK, MOBILE_NAV_ICON_SIZE } from '~constants'
import { useNavigation, useTheme } from '~hooks'

interface HeaderProps extends StackHeaderProps {
  isRightIcon?: boolean
  isDark?: boolean
}

export const Header = ({
  navigation,
  back,
  isRightIcon = true,
  isDark = false,
}: HeaderProps): JSX.Element => {
  const { colors } = useTheme()
  const { navigate } = useNavigation()

  const navigateToSettings = useCallback(() => {
    navigate('Settings')
  }, [navigate])

  const navigateToProfile = useCallback(() => {
    navigate('Profile')
  }, [navigate])

  return (
    <SafeAreaView
      edges={['top']}
      style={[styles.header, { backgroundColor: isDark ? colors.brand_bg : colors.brand_dark }]}
    >
      {back ? (
        <Pressable flex={1} onPress={navigation.goBack}>
          <Icon
            color={isDark ? 'brand_dark' : 'white'}
            name="arrow-go-back-line"
            size={MOBILE_NAV_ICON_SIZE}
          />
        </Pressable>
      ) : (
        <Pressable flex={1} onPress={navigateToProfile}>
          <Icon color="white" name="account-circle-line" size={MOBILE_NAV_ICON_SIZE} />
        </Pressable>
      )}
      <Box alignItems="center" flex={1} justifyContent="center">
        <ExpoImage
          alt="logo"
          contentFit="contain"
          source={isDark ? LOGO_DARK : LOGO}
          style={styles.logo}
        />
      </Box>
      {isRightIcon ? (
        <Pressable alignItems="flex-end" flex={1} onPress={navigateToSettings}>
          <Icon color="white" name="settings-3-line" size={MOBILE_NAV_ICON_SIZE} />
        </Pressable>
      ) : (
        <Box flex={1} />
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 16,
  },
  logo: {
    height: 42,
    width: 100,
  },
})
