import { ImageBackground as ExpoImageBackground, ImageContentFit } from 'expo-image'
import { LinearGradient } from 'expo-linear-gradient'
import { PropsWithChildren } from 'react'
import { ImageSourcePropType, StyleSheet } from 'react-native'

import { WebNavBarBottom, WebNavBarTop } from '../../navigation/webNavigator/WebNavBar'

import { BACKGROUND_MOBILE, BACKGROUND_WEB, isWeb } from '~constants'
import { useAuth, useTheme, useWeb } from '~hooks'

type BackgroundProps = {
  contentFit?: ImageContentFit
  mobileBackground?: ImageSourcePropType
  webBackground?: ImageSourcePropType
}

export const Background = ({
  children,
  contentFit,
  mobileBackground = BACKGROUND_MOBILE,
  webBackground = BACKGROUND_WEB,
}: PropsWithChildren<BackgroundProps>) => {
  const { shouldApplyDesktopStyles } = useWeb()
  const { colors } = useTheme()

  const { isSignedIn } = useAuth()

  return isSignedIn ? (
    <LinearGradient
      colors={[colors.brand_dark, colors.brand_bg]}
      end={{ x: 0, y: 1 }}
      style={styles.container}
    >
      <ExpoImageBackground
        alt="app-background"
        contentFit={contentFit}
        source={shouldApplyDesktopStyles ? webBackground : mobileBackground}
        style={styles.expoBackgroundAuthorize}
      >
        {isWeb && <WebNavBarTop />}
        {children}
        {isWeb && <WebNavBarBottom />}
      </ExpoImageBackground>
    </LinearGradient>
  ) : (
    <ExpoImageBackground
      alt="app-background"
      contentFit={contentFit}
      source={shouldApplyDesktopStyles ? webBackground : mobileBackground}
    >
      {children}
    </ExpoImageBackground>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1 },
  expoBackgroundAuthorize: {
    ...StyleSheet.absoluteFillObject,
  },
  expoBackgroundUnauthorize: {
    ...StyleSheet.absoluteFillObject,
  },
})
