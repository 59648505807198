import { useMutation, useQueryClient } from '@tanstack/react-query'

import { QueryKeys } from '~enums'
import { ApiError, UserData, setRefreshToken, setToken, signInRequest } from '~services'
import { SignInFormValues } from '~types/authForms'

export const useSignInMutation = () => {
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading } = useMutation<UserData, ApiError, SignInFormValues>(
    signInRequest,
    {
      onSuccess: async (signInData) => {
        const { accessToken, refreshToken, ...userData } = signInData
        await setToken(accessToken)
        await setRefreshToken(refreshToken)
        await queryClient.cancelQueries({ queryKey: [QueryKeys.MeQuery] })
        queryClient.setQueryData([QueryKeys.MeQuery], userData)
      },
    }
  )

  return { signInMutation: mutateAsync, isLoading }
}
