import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { WalletItemProps } from '~components'
import { NETWORKS, PLACEHOLDER_TOKEN, TOKENS } from '~constants'
import { QueryKeys } from '~enums'
import { AssetList, getAssetsListRequest } from '~services'

export const useGetAssetsListQuery = (options?: UseQueryOptions<AssetList, AxiosError>) => {
  const {
    data: assetsList,
    isInitialLoading: isInitialLoadingAssetsList,
    refetch: refetchAssetsList,
    error: assetsListError,
  } = useQuery<AssetList, AxiosError>([QueryKeys.Assets], getAssetsListRequest, options)

  const assetListWithImages: WalletItemProps[] = (assetsList || [])?.map((el) => {
    const networkLabel = el?.networkLabel
    const tokenShortName = el?.shortName

    const networkImage = networkLabel ? NETWORKS[networkLabel] : undefined
    const tokenImage = TOKENS[tokenShortName] ?? PLACEHOLDER_TOKEN

    return {
      ...el,
      networkImage,
      networkLabel,
      tokenFullName: el.fullName,
      tokenShortName,
      tokenImage,
      value: Number(el.value),
    }
  })

  return {
    assetsList: assetListWithImages,
    isInitialLoadingAssetsList,
    refetchAssetsList,
    assetsListError,
  }
}
