import { MutateOptions } from '@tanstack/react-query'
import { createRef, MutableRefObject } from 'react'

import { ApiError, UserData } from '~services'
import { SignInFormValues, SignUpFormValues } from '~types/authForms'
import createGenericContext from '~utils/createGenericContext'

// TODO: modify return options from signIn, signOut, signUp and add sendPasswordResetEmail and confirmPasswordReset functions
export type AuthContextType = {
  isSignedIn: boolean | null
  signIn: (
    data: SignInFormValues,
    options?: MutateOptions<UserData, ApiError, SignInFormValues>
  ) => Promise<void>
  signOut: (skipRequest?: boolean) => void
  signUp: (data: SignUpFormValues) => Promise<void>
}

export const [useAuthContext, AuthContextProvider] =
  createGenericContext<AuthContextType>('AuthContext')

export const authContextRef: MutableRefObject<AuthContextType | null> = createRef()
