import { apiClient } from '~services/api'
import { TOKEN_SHORTNAME_TYPE } from '~types/assets'

export type EstimateTransactionFormValues = {
  to: string
  amount: string
  shortName: TOKEN_SHORTNAME_TYPE
}

type EstimateTransactionResponse = {
  fee: string
}

export const estimateTransactionFeeRequest = async (data: EstimateTransactionFormValues) => {
  const response = await apiClient.post<EstimateTransactionResponse, EstimateTransactionResponse>(
    '/tangany/estimate-transaction-fee',
    data
  )

  return response
}
