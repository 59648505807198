import { useMutation, useQueryClient } from '@tanstack/react-query'

import { QueryKeys } from '~enums'
import { makeTransactionRequest } from '~services'

export const useMakeTransactionMutation = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: makeTransactionMutation, isLoading: isLoadingMakeTransaction } = useMutation(
    makeTransactionRequest,
    {
      onSuccess: (_, { shortName }) => {
        queryClient.invalidateQueries([QueryKeys.TransactionHistory])
        queryClient.invalidateQueries([QueryKeys.TokenTransactionHistory, shortName])
        queryClient.invalidateQueries([QueryKeys.Assets])
      },
    }
  )

  return { isLoadingMakeTransaction, makeTransactionMutation }
}
