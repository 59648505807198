import React, { PropsWithChildren } from 'react'

import { Checkbox } from './Checkbox'
import { DatePicker } from './DatePicker'
import { Input } from './Input'
import { Radio } from './Radio'
import { Select } from './Select'

type ControlledFieldComposition = React.FC<PropsWithChildren> & {
  Checkbox: typeof Checkbox
  DatePicker: typeof DatePicker
  Input: typeof Input
  Radio: typeof Radio
  Select: typeof Select
}

const ControlledField: ControlledFieldComposition = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>
}

ControlledField.Checkbox = Checkbox
ControlledField.DatePicker = DatePicker
ControlledField.Input = Input
ControlledField.Radio = Radio
ControlledField.Select = Select

export { ControlledField }
export * from './types'
