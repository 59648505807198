import { useMutation } from '@tanstack/react-query'

import { createVideoVerificationRequest } from '~services'

export const useCreateVideoVerificationMutation = () => {
  const {
    mutateAsync: createVideoVerificationMutation,
    isLoading: isLoadingCreateVideoVerification,
  } = useMutation(createVideoVerificationRequest)

  return { createVideoVerificationMutation, isLoadingCreateVideoVerification }
}
