import { useNavigation } from '@react-navigation/native'
import { isError } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useMeQuery, useUpdateUserDataMutation } from '~query-hooks'
import { UpdateCompanyDetailsFormValues } from '~types/authForms'
import { hapticImpact } from '~utils'

export const defaultCompanyDetailsValues: UpdateCompanyDetailsFormValues = {
  apartment: '',
  buildingNumber: '',
  city: '',
  companyName: '',
  country: '',
  floor: '',
  legalForm: '',
  postalCode: '',
  street: '',
}

export const useCompanyDetailsForm = () => {
  const { t } = useTranslation()
  const { goBack } = useNavigation()

  const [areDefaultValuesSet, setAreDefaultValuesSet] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { isLoadingUserData, userData } = useMeQuery()
  const { updateUserDataMutation } = useUpdateUserDataMutation({
    successMessage: t('toast.success.company_details_updated'),
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError: setFormError,
    setFocus,
    setValue,
    watch,
    trigger,
  } = useForm<UpdateCompanyDetailsFormValues>({
    mode: 'onTouched',
    defaultValues: defaultCompanyDetailsValues,
  })

  useEffect(() => {
    if (!isLoadingUserData && userData?.companyDetails && !areDefaultValuesSet) {
      reset(userData?.companyDetails)
      setAreDefaultValuesSet(true)
    }
  }, [areDefaultValuesSet, isLoadingUserData, reset, userData?.companyDetails])

  const onSubmit = async (companyDetails: UpdateCompanyDetailsFormValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      await updateUserDataMutation({ companyDetails })
      goBack()
    } catch (e) {
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    control,
    error,
    errors,
    handleSubmit,
    isSubmitting,
    setFocus,
    setFormError,
    setIsSubmitting,
    setValue,
    submit: handleSubmit(onSubmit),
    trigger,
    watch,
  }
}
