import { useEffect, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { SignUpFormStep0, SignUpFormStep1, SignUpFormStep2 } from './components'

import { UnAuthorizeWrapper } from '~components'
import { isWeb } from '~constants'
import {
  useTranslation,
  useCallback,
  useForm,
  defaultValuesSignUpForm,
  defaultValuesSignUpFormDev,
} from '~hooks'

export const SignUpScreen = ({ navigation: { addListener, navigate } }: SignUpScreenProps) => {
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState<0 | 1 | 2>(0)
  const currentStepRef = useRef(0)
  const scrollRef = useRef<KeyboardAwareScrollView>(null)

  useEffect(() => {
    currentStepRef.current = currentStep
  }, [currentStep])

  const setCurrentStepWithScroll = useCallback((step: 0 | 1 | 2) => {
    setCurrentStep(step)
    scrollRef.current?.scrollToPosition(0, 0, true)
  }, [])

  useEffect(() => {
    const unsubscribe = addListener('beforeRemove', (e) => {
      if (currentStepRef.current === 0) {
        return null
      } else {
        e.preventDefault()
        if (isWeb) window.history.forward()
        if (currentStepRef.current === 1) {
          setCurrentStepWithScroll(0)
        } else {
          setCurrentStepWithScroll(1)
        }
      }
    })

    return () => unsubscribe()
  }, [addListener, setCurrentStepWithScroll])

  const goToLogin = useCallback(() => {
    navigate('SignIn')
  }, [navigate])

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: __DEV__ ? defaultValuesSignUpFormDev : defaultValuesSignUpForm,
  })

  return (
    <UnAuthorizeWrapper ref={scrollRef} showTopNavBar={false}>
      <FormProvider {...methods}>
        {currentStep === 0 && (
          <SignUpFormStep0
            setCurrentStep={setCurrentStepWithScroll}
            onGoToLoginPress={goToLogin}
            t={t}
          />
        )}
        {currentStep === 1 && <SignUpFormStep1 setCurrentStep={setCurrentStepWithScroll} t={t} />}
        {currentStep === 2 && <SignUpFormStep2 setCurrentStep={setCurrentStepWithScroll} t={t} />}
      </FormProvider>
    </UnAuthorizeWrapper>
  )
}
