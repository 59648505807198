import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { StyleSheet } from 'react-native'

import { Box, Button, ExpoImage, Loader, Text } from '~components'
import { CONFIRM } from '~constants'
import { useTranslation, useCanDeleteAccount } from '~hooks'

type DeleteAccountModalStep0Props = {
  visible: boolean
  onContinue(): void
  onCancel(): void
}

export const DeleteAccountModalStep0 = ({
  visible,
  onContinue,
  onCancel,
}: DeleteAccountModalStep0Props) => {
  const { t } = useTranslation()

  const { status, isLoading, error } = useCanDeleteAccount({ enabled: visible })

  const textKey = useMemo(() => {
    switch (status) {
      case 'pendingTransactions':
        return 'settings_screen.delete_account_pending_transactions'
      case 'excessiveBalance':
        return 'settings_screen.delete_account_excessive_balance'
      case 'ok':
        return 'settings_screen.delete_account_confirmation'
      default:
        return ''
    }
  }, [status])

  return (
    <>
      <Text.H4 color="coolGray.800">{t('settings_screen.delete_account')}</Text.H4>

      {isLoading ? (
        <Box py={12} w="full">
          <Loader type="bubbles" />
        </Box>
      ) : (
        <ExpoImage source={CONFIRM} style={styles.icon} />
      )}

      {!isLoading && (
        <Text.Body color="coolGray.800">
          {error ?? (
            <Trans
              components={{ b: <Text.Bold /> }}
              defaults="Are you sure you want to <b>delete your account</b>? After confirming this
            you won’t be able to access your account."
              i18nKey={textKey}
            />
          )}
        </Text.Body>
      )}

      <Box w="full">
        {status === 'ok' && (
          <Button onPress={onContinue} variant="Delete" w="full">
            {t('settings_screen.delete_account')}
          </Button>
        )}
        <Button onPress={onCancel} variant="Ghost" w="full">
          {t('common.close')}
        </Button>
      </Box>
    </>
  )
}

const styles = StyleSheet.create({
  icon: { height: 94, width: 94 },
})
