import { useQuery } from '@tanstack/react-query'

import { QueryKeys } from '~enums'
import { meRequest } from '~services'

export const useMeQuery = () => {
  const {
    data: userData,
    isInitialLoading: isLoadingUserData,
    refetch: refetchUserData,
  } = useQuery([QueryKeys.MeQuery], meRequest)

  return { userData, isLoadingUserData, refetchUserData }
}
