import 'yup-phone'
import { TFunction } from 'i18next'
import { FC, useCallback, useMemo, useState } from 'react'
import CountryPicker, { Country, CountryCode } from 'react-native-country-picker-modal'
import { string } from 'yup'

import { Box, Button, ControlledField, Spacer, Text } from '~components'
import { REGEX } from '~constants'
import { ACCOUNT_TYPE } from '~enums'
import { useSignUpForm } from '~hooks'
import { hideKeyboard, noop, openPrivacyPolicy, openTermAndConditions } from '~utils'

type SignUpFormStep0Props = {
  setCurrentStep(step: 0 | 1 | 2): void
  onGoToLoginPress(): void
  t: TFunction<'translation', undefined>
}

export const SignUpFormStep0: FC<SignUpFormStep0Props> = ({
  setCurrentStep,
  onGoToLoginPress,
  t,
}) => {
  const ACCOUNT_TYPES = [
    { label: t('sign_up_screen.private_account'), value: ACCOUNT_TYPE.PRIVATE },
    { label: t('sign_up_screen.business_account'), value: ACCOUNT_TYPE.BUSINESS },
  ]

  const {
    clearFormErrors,
    control,
    errors,
    handleSubmit,
    isSubmitting,
    setFocus,
    setFormError,
    setValue,
    submit,
    watch,
  } = useSignUpForm({ onFieldError: setCurrentStep })

  const watchAccountType = watch('accountType')

  const onStep0Finish = useCallback(async () => {
    switch (watchAccountType) {
      case ACCOUNT_TYPE.BUSINESS:
        handleSubmit(async () => {
          setCurrentStep(1)
        })()
        break
      case ACCOUNT_TYPE.PRIVATE:
      default:
        await submit()
        break
    }
  }, [handleSubmit, setCurrentStep, submit, watchAccountType])

  const confirmButtonLabel = useMemo(() => {
    if (watchAccountType === ACCOUNT_TYPE.BUSINESS) {
      return t('sign_up_screen.save_and_go_to_next_step')
    } else return t('sign_up_screen.create_account')
  }, [t, watchAccountType])

  const setFocusLastName = useCallback(() => setFocus('lastName'), [setFocus])
  const setFocusEmail = useCallback(() => setFocus('email'), [setFocus])
  const setFocusPassword = useCallback(() => setFocus('password'), [setFocus])
  const setFocusConfirmPassword = useCallback(() => setFocus('confirmPassword'), [setFocus])
  const setFocusPhoneNumber = useCallback(() => setFocus('phoneNumber'), [setFocus])

  // phone validation
  const [countryCode, setCountryCode] = useState<CountryCode>('DE')
  const phoneSchema = string().phone(countryCode, true)
  const onSelectPhoneCountry = useCallback(
    (country: Country) => {
      setValue('phoneNumberPrefix', country.callingCode?.[0])
      setCountryCode(country.cca2)
    },
    [setValue]
  )

  return (
    <Box borderRadius={8} bg="white" p={4}>
      <Text.H4 mb={4}>{t('sign_up_screen.create_account')}</Text.H4>
      <ControlledField.Input
        {...{ control, errors }}
        autoComplete="name"
        autoCapitalize="none"
        isRequired
        label={t('form.labels.first_name')}
        mb={4}
        name="firstName"
        onSubmitEditing={setFocusLastName}
        placeholder={t('form.placeholders.first_name')}
        returnKeyType="next"
        rules={{
          required: t('form.errors.required'),
          pattern: {
            value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
            message: t('form.errors.special_characters', { max_length: 64 }),
          },
        }}
      />
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        isRequired
        label={t('form.labels.last_name')}
        mb={4}
        name="lastName"
        onSubmitEditing={setFocusEmail}
        placeholder={t('form.placeholders.last_name')}
        returnKeyType="next"
        rules={{
          required: t('form.errors.required'),
          pattern: {
            value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
            message: t('form.errors.special_characters', { max_length: 64 }),
          },
        }}
      />
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        isRequired
        keyboardType="email-address"
        label={t('form.labels.email')}
        mb={4}
        name="email"
        onSubmitEditing={setFocusPassword}
        placeholder={t('form.placeholders.email')}
        returnKeyType="next"
        rules={{
          required: t('form.errors.required'),
          pattern: {
            value: REGEX.EMAIL,
            message: t('form.errors.invalid_email_format'),
          },
        }}
      />
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        isRequired
        label={t('form.labels.password')}
        mb={1}
        name="password"
        onSubmitEditing={setFocusConfirmPassword}
        placeholder={t('form.placeholders.password')}
        returnKeyType="next"
        rules={{
          required: ' ',
          validate: {
            passwordEqual: (value, formValues) => {
              if (value === formValues.confirmPassword && formValues.confirmPassword) {
                clearFormErrors('confirmPassword')
              }
              if (value !== formValues.confirmPassword && formValues.confirmPassword) {
                setFormError('confirmPassword', {
                  message: t('form.errors.password_does_not_match'),
                })
              }
              return undefined
            },

            pattern: (value) => {
              return REGEX.REGISTRATION_PASSWORD.every((pattern) => pattern.test(value)) || ' '
            },
          },
        }}
        type="password"
      />
      <Text.Body
        color={errors.password ? 'red.400' : 'text.500'}
        mb={4}
        mt={errors.password ? -7 : 0}
      >
        {t('sign_up_screen.password_requirements')}
      </Text.Body>
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        isRequired
        label={t('form.labels.repeat_password')}
        name="confirmPassword"
        onSubmitEditing={setFocusPhoneNumber}
        placeholder={t('form.placeholders.repeat_password')}
        returnKeyType="next"
        rules={{
          required: t('form.errors.required'),
          validate: {
            passwordsEqual: (value, formValues) => {
              if (value !== formValues.password) return t('form.errors.password_does_not_match')
            },
          },
        }}
        type="password"
      />
      <Spacer y="4" />
      <ControlledField.Input
        {...{ control, errors }}
        isRequired
        keyboardType="phone-pad"
        label={t('form.labels.phone_number')}
        name="phoneNumber"
        leftElement={
          <Box height="100%" justifyContent="center" pl="2">
            <CountryPicker
              {...{ countryCode }}
              onSelect={onSelectPhoneCountry}
              withCallingCode
              withCallingCodeButton
              withCloseButton
              withEmoji
            />
          </Box>
        }
        onSubmitEditing={hideKeyboard}
        placeholder={t('form.placeholders.phone_number')}
        rules={{
          required: t('form.errors.required'),
          validate: {
            phone: async (value) => {
              try {
                phoneSchema.validateSync(value)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
              } catch (e: unknown) {
                return t('form.errors.phone_number')
              }
            },
          },
        }}
        returnKeyType="next"
        type="text"
      />
      <Spacer y="4" />
      <ControlledField.Select
        {...{ control, errors }}
        isRequired
        items={ACCOUNT_TYPES}
        label={t('form.labels.account_type')}
        maxSelectedItems={1}
        name="accountType"
        placeholder={t('form.placeholders.account_type')}
        rules={{
          required: t('form.errors.required'),
        }}
      />
      <Spacer y="4" />
      <ControlledField.Checkbox
        {...{ control, errors }}
        checkboxText={
          <Text>
            {t('sign_up_screen.i_accept')}
            <Text color="primary.600" onPress={openTermAndConditions}>
              {t('sign_up_screen.terms_and_conditions')}
            </Text>
            {t('sign_up_screen.and_the')}
            {/* FIXME: add proper link */}
            <Text color="primary.600" onPress={noop}>
              {t('sign_up_screen.declaration_of_revocation')}
            </Text>
          </Text>
        }
        isRequired
        name="terms"
        rules={{
          required: t('form.errors.required'),
        }}
        size={18}
      />
      <Spacer y="4" />
      <ControlledField.Checkbox
        {...{ control, errors }}
        checkboxText={
          <Text>
            {t('sign_up_screen.i_accept')}
            <Text color="primary.600" onPress={openPrivacyPolicy}>
              {t('sign_up_screen.policy')}
            </Text>
          </Text>
        }
        isRequired
        name="policy"
        rules={{
          required: t('form.errors.required'),
        }}
        size={18}
      />
      <Spacer y="4" />
      <ControlledField.Checkbox
        {...{ control, errors }}
        checkboxText={
          <Text>
            {t('sign_up_screen.tangany_terms_part1')}
            {/* FIXME: add proper link */}
            <Text color="primary.600" onPress={noop}>
              {t('sign_up_screen.terms_and_conditions')}
            </Text>
            {t('sign_up_screen.tangany_terms_part2')}
          </Text>
        }
        isRequired
        name="tanganyTerms"
        rules={{
          required: t('form.errors.required'),
        }}
        size={18}
      />
      <Spacer y="4" />
      <ControlledField.Checkbox
        {...{ control, errors }}
        checkboxText={t('sign_up_screen.newsletter')}
        name="newsletter"
        size={18}
      />
      <Spacer y="6" />
      <Button loading={isSubmitting} disabled={isSubmitting} onPress={onStep0Finish}>
        {confirmButtonLabel}
      </Button>
      <Spacer y="4" />
      <Button disabled={isSubmitting} onPress={onGoToLoginPress} variant="Ghost">
        {t('sign_up_screen.login')}
      </Button>
    </Box>
  )
}
