import { isError } from '@tanstack/react-query'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../useAuth'

import { ACCOUNT_TYPE } from '~enums'
import { SignUpFormValues } from '~types/authForms'
import { getFormErrorEntries, hapticImpact, navigationRef } from '~utils'

type FormKey = Leaves<SignUpFormValues>

type FormKeys = FormKey[]

const firstScreenFields: FormKeys = [
  'firstName',
  'lastName',
  'email',
  'password',
  'confirmPassword',
  'phoneNumber',
  'phoneNumberPrefix',
  'accountType',
]
const secondScreenFields: FormKeys = [
  'companyDetails.companyName',
  'companyDetails.legalForm',
  'companyDetails.crn',
  'companyDetails.lei',
  'companyDetails.taxId',
  'companyDetails.isin',
  'companyDetails.vat',
  'companyDetails.country',
  'companyDetails.city',
  'companyDetails.postalCode',
  'companyDetails.street',
  'companyDetails.buildingNumber',
  'companyDetails.apartment',
  'companyDetails.floor',
]
const thirdScreenFields: FormKeys = [
  'companyRepresentative.title',
  'companyRepresentative.salutation',
  'companyRepresentative.firstName',
  'companyRepresentative.lastName',
  // @ts-expect-error: birth date is type Calendar date and Leaves type is getting deeper nested keys
  'companyRepresentative.birthDate',
  'companyRepresentative.nationality',
]

export const defaultValuesSignUpForm: SignUpFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  accountType: undefined,
  phoneNumber: '',
  phoneNumberPrefix: '+49',
  terms: false,
  policy: false,
  tanganyTerms: false,
  newsletter: false,
  companyDetails: undefined,
  companyRepresentative: undefined,
}

export const defaultValuesSignUpFormDev: SignUpFormValues = {
  firstName: 'Imię',
  lastName: 'Nazwisko',
  email: 'm.baumruck+walluta@binarapps.com',
  password: 'qwQW12!@',
  confirmPassword: 'qwQW12!@',
  accountType: ACCOUNT_TYPE.BUSINESS,
  phoneNumber: '15555123243',
  phoneNumberPrefix: '+49',
  terms: true,
  policy: true,
  tanganyTerms: true,
  newsletter: false,
  companyDetails: {
    companyName: 'BinarApps',
    legalForm: '',
    vat: 'PL 7262653540',
    country: 'Germany',
    city: 'Łódź',
    postalCode: '90-065',
    street: 'Roosevelta',
    buildingNumber: '6',
    apartment: '',
    floor: '4',
  },
  companyRepresentative: {
    title: 'Prof.',
    salutation: 'Mr.',
    firstName: 'TestName',
    lastName: 'TestLastName',
    birthDate: new Date('1990-01-01'),
    nationality: 'DE',
  },
}

export const useSignUpForm = ({ onFieldError }: { onFieldError(step: 0 | 1 | 2): void }) => {
  const { signUp } = useAuth()
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  const {
    clearErrors: clearFormErrors,
    control,
    formState: { errors },
    handleSubmit,
    setError: setFormError,
    setFocus,
    setValue,
    watch,
    trigger,
  } = useFormContext<SignUpFormValues>()

  const onSubmit = async (data: SignUpFormValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      if (data?.accountType === ACCOUNT_TYPE.PRIVATE) {
        const { companyDetails, companyRepresentative, ...rest } = data
        await signUp(rest)
      } else {
        await signUp(data)
      }
      navigationRef.navigate('EmailVerification', { email: data.email })
    } catch (e: unknown) {
      // FIXME: check types later
      // @ts-expect-error: fix errors later
      getFormErrorEntries<SignUpFormValues>(e).map(([key, values]) => {
        setFormError(key, { message: values.join('\n') })
      })

      let navigateToFirstScreen = false
      let navigateToSecondScreen = false
      let navigateToThirdScreen = false

      // @ts-expect-error: fix errors later
      Object.keys(e).forEach((key) => {
        if (firstScreenFields.includes(key as FormKey)) {
          navigateToFirstScreen = true
        } else if (secondScreenFields.includes(key as FormKey)) {
          navigateToSecondScreen = true
        } else if (thirdScreenFields.includes(key as FormKey)) {
          navigateToThirdScreen = true
        }
      })

      const navigateToStep = navigateToFirstScreen
        ? 0
        : navigateToSecondScreen
        ? 1
        : navigateToThirdScreen
        ? 2
        : undefined

      if (typeof navigateToStep === 'number') {
        onFieldError(navigateToStep)
        return
      }

      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    clearFormErrors,
    control,
    error,
    errors,
    handleSubmit,
    isSubmitting,
    setFocus,
    setFormError,
    setIsSubmitting,
    setValue,
    submit: handleSubmit(onSubmit),
    trigger,
    watch,
  }
}
