import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar'

import { ACCOUNT_TYPE, KYC_STATUS } from '~enums'
import { apiClient } from '~services/api'
import { AddressDataType } from '~types/address'

type CheckAllUserRequiredData = {
  companyDetails: boolean
  companyRepresentative: boolean
  contactAddress: boolean
  personalData: boolean
  registrationAddress: boolean
}

type PersonalData = {
  accountType: ACCOUNT_TYPE
  birthDate: string
  birthCity: string
  birthCountry?: string
  checkAllUserRequiredData: CheckAllUserRequiredData
  email: string
  gender?: string
  firstName: string
  lastName: string
  nationality: string
  phoneNumber: string
  phoneNumberPrefix: string
  salutation: string
  title: string
  newsletter: boolean
  policy: boolean
  tanganyTerms: boolean
  terms: boolean
}
type CompanyDetails = {
  companyName: string
  crn?: string
  isin?: string
  legalForm: string
  lei?: string
  taxId?: string
  vat?: string
} & AddressDataType
type CompanyRepresentative = {
  title: string
  salutation: string
  firstName: string
  lastName: string
  birthDate: CalendarDate | string
  nationality: string
  phoneNumber: string
  phoneNumberPrefix: string
}
type ContactAddress = AddressDataType & { sameAsRegistration: boolean }
type RegistrationAddress = AddressDataType

export type MeData = {
  companyDetails?: CompanyDetails
  companyRepresentative?: CompanyRepresentative
  contactAddress: ContactAddress
  // TODO: change interface to work with this instead of & PersonalData below
  // personalData: PersonalData
  registrationAddress: RegistrationAddress
  // KYC
  // FIXME: ADD PROPER TYPE HERE
  identId?: string
  kyc?: {
    identificationTime: Date
    transactionNumber: string
    result?: KYC_STATUS
    id: string
  }
  lastShownKycModal?: KYC_STATUS

  wallet?: string

  createdAt: { seconds: number; _nanoseconds: number }
  uid: string
  updatedAt: { seconds: number; _nanoseconds: number }
} & PersonalData

export const meRequest = async () => {
  return await apiClient.get<MeData, MeData>('/user/me')
}
