import { Fragment } from 'react'

import { Divider, Spacer, Text } from '../atoms'
import { SettingsItem, SettingsItemType } from './SettingsItem'

export const SettingsSectionTitle = ({ sectionTitle }: { sectionTitle: string }) => {
  return (
    <>
      <Divider />
      <Spacer y="3" />
      <Text.Bold color="muted.400">{sectionTitle}</Text.Bold>
      <Spacer y="3" />
    </>
  )
}

type SettingsSectionType = {
  settingsItems: SettingsItemType[]
  sectionTitle?: string
}

export const SettingSection = ({ settingsItems, sectionTitle }: SettingsSectionType) => {
  return (
    <>
      {sectionTitle ? <SettingsSectionTitle {...{ sectionTitle }} /> : null}
      {settingsItems.map((settingsItem) => {
        return (
          <Fragment key={settingsItem.title}>
            <SettingsItem {...settingsItem} />
            <Spacer y="3" />
          </Fragment>
        )
      })}
    </>
  )
}
