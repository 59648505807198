import { useNavigation } from '@react-navigation/native'
import { isError } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useMeQuery, useUpdateUserDataMutation } from '~query-hooks'
import { UpdatePersonalDataFormValues } from '~types/authForms'
import { hapticImpact } from '~utils'

export const defaultValuesPersonalDataForm: UpdatePersonalDataFormValues = {
  title: '',
  salutation: '',
  gender: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  birthCity: '',
  birthCountry: '',
  nationality: '',
  phoneNumberPrefix: '',
  phoneNumber: '',
}

export const usePersonalDataForm = () => {
  const { t } = useTranslation()
  const { goBack } = useNavigation()

  const { isLoadingUserData, userData } = useMeQuery()
  const { updateUserDataMutation } = useUpdateUserDataMutation({
    successMessage: t('toast.success.personal_data_updated'),
  })

  const [areDefaultValuesSet, setAreDefaultValuesSet] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    setError: setFormError,
    setFocus,
    setValue,
    watch,
    trigger,
  } = useForm<UpdatePersonalDataFormValues>({
    mode: 'onTouched',
    defaultValues: defaultValuesPersonalDataForm,
  })

  useEffect(() => {
    if (!isLoadingUserData && !areDefaultValuesSet) {
      const defaultValues = (
        Object.keys(defaultValuesPersonalDataForm) as (keyof UpdatePersonalDataFormValues)[]
      ).reduce((acc, key) => {
        return { ...acc, [key]: userData?.[key] || null }
      }, {})
      reset(defaultValues)
      setAreDefaultValuesSet(true)
    }
  }, [areDefaultValuesSet, isLoadingUserData, reset, userData])

  const onSubmit = async (data: UpdatePersonalDataFormValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      await updateUserDataMutation(data)
      goBack()
    } catch (e) {
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    areDefaultValuesSet,
    control,
    error,
    errors,
    getValues,
    handleSubmit,
    isSubmitting,
    setFocus,
    setFormError,
    setIsSubmitting,
    setValue,
    submit: handleSubmit(onSubmit),
    trigger,
    watch,
  }
}
