import {
  Box,
  Button,
  ControlledField,
  Divider,
  LightScreenWrapper,
  Row,
  Spacer,
  Text,
} from '~components'
import { COUNTRIES, REGEX, isWeb } from '~constants'
import { useTranslation, useWeb, useContactAddressForm, useCallback } from '~hooks'
import { useMeQuery } from '~query-hooks'
import { hideKeyboard } from '~utils'

export const ContactAddressScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { shouldApplyDesktopStyles } = useWeb()

  const { userData } = useMeQuery()
  const { control, errors, isSubmitting, setFocus, submit, watch } = useContactAddressForm()

  const isSameAsRegistrationAddress = watch('sameAsRegistration')

  const setFocusCity = useCallback(() => {
    !isWeb && setFocus('city')
  }, [setFocus])

  const setFocusPostalCode = useCallback(() => {
    setFocus('postalCode')
  }, [setFocus])

  const setFocusStreet = useCallback(() => setFocus('street'), [setFocus])
  const setFocusBuildingNumber = useCallback(() => setFocus('buildingNumber'), [setFocus])
  const setFocusApartment = useCallback(() => setFocus('apartment'), [setFocus])
  const setFocusFloor = useCallback(() => setFocus('floor'), [setFocus])

  return (
    <LightScreenWrapper
      iconName="map-pin-line"
      title={t('navigation.screen_titles.contact_address')}
    >
      <ControlledField.Checkbox
        {...{ control, errors }}
        checkboxText={
          <Text.Subtitle>{t('contact_address_screen.is_the_same_address')}</Text.Subtitle>
        }
        disabled={!userData?.registrationAddress}
        extraText={t('form.errors.registration_address_missing')}
        name="sameAsRegistration"
        showExtraText={!userData?.registrationAddress}
      />
      {isSameAsRegistrationAddress && (
        <Box bg="primary.50" borderRadius={4} mt={4} p={2}>
          <Text selectable={false}>
            {userData?.registrationAddress?.street} {userData?.registrationAddress?.buildingNumber}
            {userData?.registrationAddress?.apartment &&
              ' ' + userData?.registrationAddress?.apartment}
            {userData?.registrationAddress?.floor &&
              ' ' + userData?.registrationAddress?.floor + 'floor'}
            , {userData?.registrationAddress?.postalCode} {userData?.registrationAddress?.city},{' '}
            {COUNTRIES.find((el) => el.value === userData?.registrationAddress?.country)?.label}
          </Text>
        </Box>
      )}
      {!isSameAsRegistrationAddress && (
        <>
          <Divider mt={4} mb={6} />
          <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
            <ControlledField.Select
              {...{ control, errors }}
              flex={shouldApplyDesktopStyles ? 1 : undefined}
              items={COUNTRIES}
              isRequired
              label={t('form.labels.country')}
              maxSelectedItems={1}
              name="country"
              onPick={setFocusCity}
              placeholder={t('form.placeholders.country')}
              rules={{
                required: t('form.errors.required'),
              }}
            />
            {shouldApplyDesktopStyles && <Spacer x="4" />}
            <ControlledField.Input
              {...{ control, errors }}
              autoCapitalize="none"
              flex={shouldApplyDesktopStyles ? 1 : undefined}
              isRequired
              label={t('form.labels.city')}
              mb={2}
              name="city"
              onSubmitEditing={setFocusPostalCode}
              placeholder={t('form.placeholders.city')}
              returnKeyType="next"
              rules={{
                required: t('form.errors.required'),
                pattern: {
                  value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
                  message: t('form.errors.special_characters', { max_length: 64 }),
                },
              }}
            />
          </Box>
          <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
            <ControlledField.Input
              {...{ control, errors }}
              autoCapitalize="none"
              flex={shouldApplyDesktopStyles ? 1 : undefined}
              isRequired
              label={t('form.labels.postal_code')}
              mb={2}
              name="postalCode"
              onSubmitEditing={setFocusStreet}
              placeholder={t('form.placeholders.postal_code')}
              returnKeyType="next"
              rules={{
                required: t('form.errors.required'),
                pattern: {
                  value: REGEX.SPECIAL_CHARACTERS_16_NOT_ALLOWED,
                  message: t('form.errors.special_characters', { max_length: 16 }),
                },
              }}
            />
            {shouldApplyDesktopStyles && <Spacer x="4" />}
            <ControlledField.Input
              {...{ control, errors }}
              autoCapitalize="none"
              flex={shouldApplyDesktopStyles ? 1 : undefined}
              isRequired
              label={t('form.labels.street')}
              mb={2}
              name="street"
              onSubmitEditing={setFocusBuildingNumber}
              placeholder={t('form.placeholders.street')}
              returnKeyType="next"
              rules={{
                required: t('form.errors.required'),
                pattern: {
                  value: REGEX.MAX_LENGTH_128,
                  message: t('form.errors.maximum_length', { max_length: 128 }),
                },
              }}
            />
          </Box>
          <Row>
            <ControlledField.Input
              {...{ control, errors }}
              autoCapitalize="none"
              flex={shouldApplyDesktopStyles ? 1 : 1.5}
              isRequired
              label={t('form.labels.building_number')}
              labelNumberOfLines={1}
              name="buildingNumber"
              onSubmitEditing={setFocusApartment}
              placeholder={t('form.placeholders.building_number')}
              returnKeyType="next"
              rules={{
                required: t('form.errors.required'),
              }}
            />
            <Spacer x={shouldApplyDesktopStyles ? '4' : '2'} />
            <ControlledField.Input
              {...{ control, errors }}
              autoCapitalize="none"
              flex={1}
              keyboardType="number-pad"
              label={t('form.labels.apartment')}
              labelNumberOfLines={1}
              name="apartment"
              onSubmitEditing={setFocusFloor}
              placeholder={t('form.placeholders.apartment')}
              returnKeyType="next"
              rules={{
                pattern: {
                  value: REGEX.MAX_LENGTH_16,
                  message: t('form.errors.maximum_length', { max_length: 16 }),
                },
              }}
            />
            <Spacer x="2" />
            <ControlledField.Input
              {...{ control, errors }}
              autoCapitalize="none"
              flex={1}
              keyboardType="number-pad"
              label={t('form.labels.floor')}
              labelNumberOfLines={1}
              name="floor"
              onSubmitEditing={hideKeyboard}
              placeholder={t('form.placeholders.floor')}
              returnKeyType="default"
              rules={{
                pattern: {
                  value: REGEX.MAX_LENGTH_16,
                  message: t('form.errors.maximum_length', { max_length: 16 }),
                },
              }}
            />
          </Row>
        </>
      )}
      <Button disabled={isSubmitting} loading={isSubmitting} mt={4} onPress={submit}>
        {t('common.save_changes')}
      </Button>
    </LightScreenWrapper>
  )
}
