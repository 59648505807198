import { Icon, Row, Text } from '../atoms'

import { MOBILE_NAV_ICON_SIZE } from '~constants'
import { IconNames } from '~types/icon'

type TransactionHeaderProps = {
  iconName: IconNames
  title: string
}
export const TransactionHeader = ({ iconName, title }: TransactionHeaderProps) => {
  return (
    <Row alignItems="center">
      <Icon color="primary.500" name={iconName} size={MOBILE_NAV_ICON_SIZE} />
      <Text.H2 color="coolGray.800" ml={4}>
        {title}
      </Text.H2>
    </Row>
  )
}
