import { BlurView } from 'expo-blur'
import { LinearGradient } from 'expo-linear-gradient'
import React, { useCallback } from 'react'
import { ImageSourcePropType, StyleSheet } from 'react-native'

import { Box, Row, Spacer, Text } from '../atoms'
import { ActionButton } from './ActionButton'
import { TokenAndNetworkLogo } from './TokenAndNetworkLogo'

import { useNavigation, useTheme, useTranslation, useWeb } from '~hooks'
import { openBuyCrypto, renderMaxDecimalPlaces, renderExactDecimalPlaces } from '~utils'

type WalletActionsCardProps = {
  isToken?: boolean
  networkImage?: ImageSourcePropType
  title?: string
  token?: string
  tokenImage?: ImageSourcePropType
  tokenAbbreviation?: string
  value?: number
  valueInEuro: number | string
}

export const WalletActionsCard = ({
  isToken,
  networkImage,
  title,
  token,
  tokenImage,
  tokenAbbreviation,
  value,
  valueInEuro,
}: WalletActionsCardProps) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate } = useNavigation()
  const { shouldApplyDesktopStyles } = useWeb()

  const navigateToTransactionSend = useCallback(() => {
    navigate('Transactions', { index: 0 })
  }, [navigate])

  const navigateToTransactionReceive = useCallback(() => {
    navigate('Transactions', { index: 2 })
  }, [navigate])

  return (
    <>
      <LinearGradient
        colors={[colors.secondary[500], colors.primary[500], colors.tertiary[500]]}
        end={{ x: 1, y: 1 }}
        start={{ x: 0, y: 1 }}
        style={styles.gradient}
      />
      <BlurView
        intensity={15}
        style={[
          styles.blurView,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            backgroundColor: isToken ? colors.white : colors.white20opacity,
            borderBottomLeftRadius: shouldApplyDesktopStyles ? 8 : 0,
            borderBottomRightRadius: shouldApplyDesktopStyles ? 8 : 0,
          },
        ]}
        tint="light"
      >
        <Box
          backgroundColor="white"
          bgOpacity={isToken ? 1 : 0.2}
          borderBottomEndRadius={shouldApplyDesktopStyles ? 8 : 0}
          borderBottomLeftRadius={shouldApplyDesktopStyles ? 8 : 0}
          flex={1}
          px={4}
          py={6}
        >
          <Row flex={1} justifyContent="space-between">
            <Row alignItems="center">
              {isToken && tokenImage ? (
                <>
                  <TokenAndNetworkLogo {...{ networkImage, tokenImage }} />
                  <Spacer x="10" />
                </>
              ) : null}
              <Box>
                {title && <Text.Bold color={isToken ? 'brand_dark' : 'white'}>{title}</Text.Bold>}

                <Text.H3 color={isToken ? 'brand_dark' : 'white'} mt={3}>
                  €{renderExactDecimalPlaces(Number(valueInEuro))}
                </Text.H3>

                {typeof value === 'number' && tokenAbbreviation && (
                  <Text.Caption color="muted.400" mt={3}>
                    {renderMaxDecimalPlaces(value) + ' ' + tokenAbbreviation}
                  </Text.Caption>
                )}
              </Box>
            </Row>
            {shouldApplyDesktopStyles && (
              <Row alignItems="center" w={360} justifyContent="space-around">
                <ActionButton
                  iconName="shopping-cart-2-line"
                  isToken={isToken}
                  onPress={openBuyCrypto}
                  title={t('common.buy')}
                />
                <ActionButton
                  iconName="upload-line"
                  isToken={isToken}
                  onPress={navigateToTransactionSend}
                  title={t('common.send')}
                />
                <ActionButton
                  iconName="download-line"
                  isToken={isToken}
                  onPress={navigateToTransactionReceive}
                  title={t('common.receive')}
                />
                <ActionButton
                  iconName="arrow-left-right-line"
                  isComingSoon
                  isToken={isToken}
                  title={t('common.exchange')}
                />
              </Row>
            )}
          </Row>
        </Box>
      </BlurView>
      {!shouldApplyDesktopStyles && (
        <Row
          bg="brand_dark"
          borderBottomEndRadius={8}
          borderBottomLeftRadius={8}
          justifyContent="space-around"
          pb={4}
          pt={1}
        >
          <ActionButton
            iconName="shopping-cart-2-line"
            onPress={openBuyCrypto}
            title={t('common.buy')}
          />
          <ActionButton
            iconName="upload-line"
            onPress={navigateToTransactionSend}
            title={t('common.send')}
          />
          <ActionButton
            iconName="download-line"
            onPress={navigateToTransactionReceive}
            title={t('common.receive')}
          />
          <ActionButton
            iconName="arrow-left-right-line"
            isComingSoon
            title={t('common.exchange')}
          />
        </Row>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  blurView: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  gradient: { borderTopLeftRadius: 8, borderTopRightRadius: 8, height: 4 },
})
