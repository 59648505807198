import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { QueryKeys } from '~enums'
import { TransactionHistoryList, getTransactionHistoryListRequest } from '~services'

export const useGetTransactionHistoryQuery = (
  options?: UseQueryOptions<TransactionHistoryList, AxiosError>
) => {
  const {
    data: transactionHistoryList,
    isInitialLoading: isInitialLoadingTransactionHistoryList,
    refetch: refetchTransactionsHistoryList,
    error: transactionHistoryListError,
  } = useQuery<TransactionHistoryList, AxiosError>(
    [QueryKeys.TransactionHistory],
    getTransactionHistoryListRequest,
    options
  )

  return {
    transactionHistoryList,
    isInitialLoadingTransactionHistoryList,
    refetchTransactionsHistoryList,
    transactionHistoryListError,
  }
}
