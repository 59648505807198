import { Icon } from './Icon'
import { Text } from './Text'
import { Pressable } from './Touchables/Pressable'

import { useTranslation } from '~hooks'

type SwitchButtonProps = {
  isActive: boolean
  onPress: () => void
}

export const SwitchButton = ({ isActive, onPress }: SwitchButtonProps) => {
  const { t } = useTranslation()

  return (
    <Pressable
      alignItems="center"
      bg={isActive ? 'primary.100' : 'transparent'}
      borderColor={isActive ? 'primary.100' : 'muted.300'}
      borderRadius={4}
      borderWidth={1}
      flexDirection="row"
      justifyContent="space-between"
      onPress={onPress}
      px={3}
      py={2}
    >
      <Text.Small color="primary.900" selectable={false}>
        {isActive ? t('common.see_less') : t('common.see_more')}
      </Text.Small>
      <Icon
        color="primary.900"
        name={isActive ? 'arrow-up-s-line' : 'arrow-down-s-line'}
        size={16}
      />
    </Pressable>
  )
}
