import { Image as ExpoImage } from 'expo-image'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, StyleSheet } from 'react-native'

import { Button } from '../atoms/Button'
import { Center } from '../atoms/Center'
import { Spacer } from '../atoms/Spacer'
import { Text } from '../atoms/Text'

import {
  breakpoints,
  radii,
  KYC_FAILED_IMAGE,
  KYC_PENDING_IMAGE,
  KYC_SUCCESS_IMAGE,
} from '~constants'
import { KYC_STATUS } from '~enums'

type VerificationModalProps = {
  onClose(): void
  status?: KYC_STATUS
  visible: boolean
}

export const VerificationModal = ({ visible, status, onClose }: VerificationModalProps) => {
  const { t } = useTranslation()

  const { icon, text } = useMemo(() => {
    switch (status) {
      case KYC_STATUS.PENDING:
        return { icon: KYC_PENDING_IMAGE, text: t('home_screen.kyc_pending_modal') }
      case KYC_STATUS.FAILED:
        return { icon: KYC_FAILED_IMAGE, text: t('home_screen.kyc_failed_modal') }
      case KYC_STATUS.SUCCESS:
        return { icon: KYC_SUCCESS_IMAGE, text: t('home_screen.kyc_success_modal') }
      default:
        return { icon: undefined, text: undefined }
    }
  }, [status, t])

  return (
    <Modal animationType="fade" onRequestClose={onClose} transparent visible={visible}>
      <Center bg="brand_dark" bgOpacity={0.8} h="full" px={4} w="full">
        <Center
          bg="white"
          borderRadius={radii.md}
          maxWidth={breakpoints.md}
          p={6}
          width="full"
          zIndex={10}
        >
          <ExpoImage source={icon} style={styles.icon} />
          <Spacer y="6" />
          <Text.Body color="coolGray.800">{text}</Text.Body>
          <Spacer y="6" />
          <Button onPress={onClose} variant="Ghost" w="full">
            {t('common.close')}
          </Button>
        </Center>
      </Center>
    </Modal>
  )
}

const styles = StyleSheet.create({
  icon: { height: 94, width: 94 },
})
