import axios from 'axios'

import { ENV } from '~constants'
import { RefreshToken } from '~types/token'

export const refreshTokenRequest = async ({
  refreshToken,
}: {
  refreshToken: string
}): Promise<RefreshToken> => {
  const response = await axios.post<RefreshToken>(`${ENV.API_URL}/auth/refresh-token`, {
    refreshToken,
  })

  return response?.data
}
