import { apiClient } from '~services/api'
import { ChangePasswordFormValues } from '~types/authForms'

export const changePasswordRequest = async (
  data: Omit<ChangePasswordFormValues, 'repeatNewPassword'>
) => {
  const response = await apiClient.post('/user/change-password', data)

  return response
}
