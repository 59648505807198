import { AxiosError } from 'axios'
import { AlertButton, Platform } from 'react-native'

import { alert } from './alert'
import { getNavigator } from './getNavigator'
import { wait } from './wait'

import { SECOND_IN_MS } from '~constants'
import { authContextRef } from '~contexts'
import i18n from '~i18n'
import { ApiError } from '~services'

export let isSingingOut = false
let showErrorMessage = true

export const signOutWhenNotAuthorized = async (error: AxiosError<ApiError>) => {
  const navigator = getNavigator()

  if (
    (error.response?.status === 401 || error.response?.data.error === 'noUser') &&
    !isSingingOut
  ) {
    isSingingOut = true
    showErrorMessage = false

    const CONFIRM_BUTTON: AlertButton = {
      text: i18n.t('common.ok').toUpperCase(),
      onPress: async () => {
        authContextRef.current?.signOut()
        await wait(SECOND_IN_MS)

        if (navigator) {
          navigator.navigate('SignIn')
        }
        isSingingOut = false
        showErrorMessage = true
      },
    }
    const CANCEL_BUTTON: AlertButton | undefined =
      Platform.OS === 'web' ? { ...CONFIRM_BUTTON, style: 'cancel' } : undefined

    const BUTTONS = CANCEL_BUTTON ? [CONFIRM_BUTTON, CANCEL_BUTTON] : [CONFIRM_BUTTON]

    alert(i18n.t('errors.not_authorized'), i18n.t('errors.to_use_app_sign_in_again'), BUTTONS)
  }

  return { showErrorMessage }
}
