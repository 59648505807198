import { CommonActions } from '@react-navigation/native'

import { ASYNC_STORAGE_KEYS } from '~constants'
import { useAuth, useCallback, useEffect, useTranslation } from '~hooks'
import { getToast, secureStore } from '~utils'

const { showErrorToast } = getToast()
const { CONFIRM_CHANGE_EMAIL_OOB_CODE } = ASYNC_STORAGE_KEYS

export const ActionScreen = (props: ActionScreenProps): JSX.Element => {
  const {
    navigation: { navigate, dispatch },
    route: {
      params: { mode, oobCode },
    },
  } = props
  const { t } = useTranslation()
  const { isSignedIn, signOut } = useAuth()

  const navigateTo = useCallback(
    (screenName: string, withOobCode: boolean = true) =>
      dispatch((state) => {
        const routes = [
          ...state.routes.filter((item) => item.name !== 'Action'),
          withOobCode ? { name: screenName, params: { oobCode } } : { name: screenName },
        ]

        return CommonActions.reset({
          ...state,
          routes,
          index: routes.length - 1,
        })
      }),
    [dispatch, oobCode]
  )

  useEffect(() => {
    if (mode === 'verifyEmail') {
      if (isSignedIn) {
        signOut()
      }
      navigateTo('AccountCreated')
    } else if (mode === 'resetPassword') {
      if (isSignedIn) {
        signOut()
      }
      navigateTo('ConfirmResetPassword')
    } else if (mode === 'verifyAndChangeEmail') {
      if (isSignedIn) {
        navigateTo('ConfirmChangeEmail')
      } else {
        navigateTo('SignIn', false)
        showErrorToast(t('errors.confirm_change_email'))
        secureStore.setItem(CONFIRM_CHANGE_EMAIL_OOB_CODE, oobCode)
      }
    } else navigateTo('NotFound', false)
  }, [isSignedIn, mode, navigate, navigateTo, oobCode, signOut, t])

  return <></>
}
