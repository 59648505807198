import { Image as ExpoImage, ImageSource } from 'expo-image'
import { FC } from 'react'
import { StyleSheet } from 'react-native'

import { Box } from '../atoms/Box'
import { Spacer } from '../atoms/Spacer'
import { Text } from '../atoms/Text'

type ListEmptyComponentBaseProps = {
  emptyText: string
  source: ImageSource
}

export const ListEmptyComponentBase: FC<ListEmptyComponentBaseProps> = ({ emptyText, source }) => {
  return (
    <Box
      alignItems="center"
      bg="white"
      borderBottomEndRadius={8}
      borderBottomLeftRadius={8}
      justifyContent="center"
      p={8}
    >
      <ExpoImage
        alt="Empty wallet list placeholder"
        contentFit="contain"
        source={source}
        style={styles.emptyState}
      />
      <Spacer y="4" />
      <Text.H5 color="muted.400">{emptyText}</Text.H5>
    </Box>
  )
}

const styles = StyleSheet.create({
  emptyState: {
    height: 84,
    width: 84,
  },
})
