import { useNavigation } from '@react-navigation/native'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { resendVerificationLinkRequest } from '~services'
import { getToast, hapticImpact } from '~utils'

const { showSuccessToast } = getToast()

export const useResendVerificationLinkMutation = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigation()

  const { mutate, isLoading, error } = useMutation(resendVerificationLinkRequest, {
    onSuccess: (_, { email }) => {
      navigate('EmailVerification', { email })
      showSuccessToast(t('toast.success.verification_link_sent'))
    },
    onError: () => {
      hapticImpact()
    },
  })

  return {
    isLoadingResendVerificationLink: isLoading,
    resendVerificationLinkError: error,
    resendVerificationLinkMutation: mutate,
  }
}
