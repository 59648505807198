export const REGEX = {
  EMAIL: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g,
  PASSWORD: /(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g,
  REGISTRATION_PASSWORD: [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/, /.{8,}/, /^\S+$/],
  SPECIAL_CHARACTERS_16_NOT_ALLOWED: /(?=^.{0,16}$)(^[A-Za-z\d\-\s\p{L}]+$)/u,
  SPECIAL_CHARACTERS_64_NOT_ALLOWED: /(?=^.{0,64}$)(^[A-Za-z\d\-\s\p{L}]+$)/u,
  MAX_LENGTH_16: /^.{1,16}$/,
  MAX_LENGTH_128: /^.{1,128}$/,
  MAX_LENGTH_32: /^.{1,32}$/,
  MAX_LENGTH_64: /^.{1,64}$/,
} as const
