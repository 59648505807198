import { useNavigation } from '@react-navigation/native'
import { isError } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useResetPasswordMutation } from '~query-hooks'
import { ResetPasswordValues } from '~types/authForms'
import { hapticImpact } from '~utils'

const defaultValues: ResetPasswordValues = {
  email: '',
}

export const useResetPasswordForm = () => {
  const { resetPasswordMutation } = useResetPasswordMutation()
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()
  const { navigate } = useNavigation()

  const {
    control,
    formState: { errors },
    setFocus,
    handleSubmit,
  } = useForm<ResetPasswordValues>({
    mode: 'onTouched',
    defaultValues,
  })

  const onSubmit = async (data: ResetPasswordValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      await resetPasswordMutation(data)
      navigate('ResetPasswordInfo', { email: data.email })
    } catch (e) {
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    submit: handleSubmit(onSubmit),
    isSubmitting,
    setIsSubmitting,
    setFocus,
    control,
    errors,
    error,
  }
}
