import * as Application from 'expo-application'
import Constants from 'expo-constants'

import languages from '../../../assets/languages.json'
import { DeleteAccountItem } from './components/DeleteAccountItem'

import { Divider, LightScreenWrapper, SettingsItem, Spacer, Text } from '~components'
import { useAuth, useCallback, useTranslation } from '~hooks'

const APP_VERSION = Application?.nativeApplicationVersion ?? Constants?.expoConfig?.version

export const SettingsScreen = ({ navigation }: SettingsScreenProps): JSX.Element => {
  const { t, i18n } = useTranslation()
  const { signOut } = useAuth()
  const { navigate } = navigation

  const navigateToSelectLanguage = useCallback(() => navigate('SelectLanguage'), [navigate])
  const navigateToAboutApp = useCallback(() => navigate('AboutApp'), [navigate])

  return (
    <LightScreenWrapper iconName="settings-3-line" stretchContent title={t('common.settings')}>
      <Text.Bold color="muted.400">{t('settings_screen.app')}</Text.Bold>
      <Spacer y="3" />
      <SettingsItem
        label={languages[i18n.language.toUpperCase() as keyof typeof languages].language}
        onPress={navigateToSelectLanguage}
        title={t('common.language')}
      />
      <Spacer y="3" />
      <Divider />
      <Spacer y="3" />
      <Text.Bold color="muted.400">{t('settings_screen.other')}</Text.Bold>
      <Spacer y="3" />
      <SettingsItem
        label={`${t('common.version')} ${APP_VERSION}`}
        onPress={navigateToAboutApp}
        title={t('settings_screen.about_app')}
      />
      <Spacer y="3" />
      <SettingsItem
        iconName="logout-box-r-line"
        label=""
        onPress={signOut}
        title={t('common.logout')}
      />
      <DeleteAccountItem />
    </LightScreenWrapper>
  )
}
