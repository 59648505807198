import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { EstimateTransactionFormValues, estimateTransactionFeeRequest } from '~services'

export const useEstimateTransactionFeeMutation = (
  options: UseMutationOptions<unknown, unknown, EstimateTransactionFormValues>
) => {
  const {
    mutateAsync: estimateTransactionFeeMutation,
    isLoading: isLoadingEstimateTransactionFee,
  } = useMutation(estimateTransactionFeeRequest, options)

  return { estimateTransactionFeeMutation, isLoadingEstimateTransactionFee }
}
