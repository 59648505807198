import { openURL } from 'expo-linking'
import { Platform } from 'react-native'

import {
  BITCOIN_SCAN_URL,
  BITCOIN_TEST_SCAN_URL,
  BUY_CRYPTO_URL,
  ENV,
  ETHEREUM_SCAN_URL,
  ETHEREUM_TEST_SCAN_URL,
  PRIVACY_POLICY_URL_DE,
  PRIVACY_POLICY_URL_EN,
  TERMS_AND_CONDITION_URL_DE,
  TERMS_AND_CONDITION_URL_EN,
} from '~constants'
import i18n from '~i18n'
import { NETWORK_LABEL_TYPE } from '~types/assets'

type OpenUrlInNewTab = {
  url: string
}
export const openUrlInNewTab = ({ url }: OpenUrlInNewTab) => {
  if (Platform.OS === 'web') {
    window.open(url, '_blank')
  } else {
    openURL(url)
  }
}

export const openBuyCrypto = () => {
  openUrlInNewTab({ url: BUY_CRYPTO_URL })
}

export const openPrivacyPolicy = () => {
  const url = i18n.language === 'de' ? PRIVACY_POLICY_URL_DE : PRIVACY_POLICY_URL_EN
  openUrlInNewTab({ url })
}

export const openTermAndConditions = () => {
  const url = i18n.language === 'de' ? TERMS_AND_CONDITION_URL_DE : TERMS_AND_CONDITION_URL_EN
  openUrlInNewTab({ url })
}

export const openTransactionDetails = ({
  networkLabel,
  transactionHash,
}: {
  networkLabel: NETWORK_LABEL_TYPE
  transactionHash: string
}) => {
  let URL = ''
  switch (networkLabel) {
    case 'Bitcoin':
      URL = ENV.ENVIRONMENT === 'production' ? BITCOIN_SCAN_URL : BITCOIN_TEST_SCAN_URL
      break
    default:
      URL = ENV.ENVIRONMENT === 'production' ? ETHEREUM_SCAN_URL : ETHEREUM_TEST_SCAN_URL
      break
  }

  openUrlInNewTab({ url: URL + transactionHash })
}
