import { apiClient } from '~services/api'
import { NETWORK_LABEL_TYPE, TOKEN_SHORTNAME_TYPE } from '~types/assets'

export type Asset = {
  contractAddress?: string
  cryptoToEuroRate: number
  fullName: string
  network?: NETWORK_LABEL_TYPE
  networkLabel?: NETWORK_LABEL_TYPE
  shortName: TOKEN_SHORTNAME_TYPE
  value: string
}

export type AssetList = Asset[]

export const getAssetsListRequest = async () => {
  const response = await apiClient.get<AssetList, AssetList>('/tangany/assets-list')

  return response
}
