import { MeData } from './meRequest'

import { apiClient } from '~services/api'
import { SignInFormValues } from '~types/authForms'
import { Token } from '~types/token'

export type UserData = MeData & Token

export const signInRequest = async (data: SignInFormValues): Promise<UserData> => {
  const response = await apiClient.post<UserData, UserData>('/auth/login', data)

  return response
}
