import { KYC_STATUS } from '~enums'
import { apiClient } from '~services/api'
import {
  UpdateCompanyDetailsFormValues,
  UpdateCompanyRepresentativeFormValues,
  UpdateContactAddressFormValues,
  UpdatePersonalDataFormValues,
  UpdateRegistrationAddressFormValues,
} from '~types/authForms'

export const updateUserDataRequest = async (
  data:
    | UpdatePersonalDataFormValues
    | { companyDetails: UpdateCompanyDetailsFormValues }
    | { companyRepresentative: UpdateCompanyRepresentativeFormValues }
    | { registrationAddress: UpdateRegistrationAddressFormValues }
    | { contactAddress: UpdateContactAddressFormValues }
    | { lastShownKycModal: KYC_STATUS }
) => {
  const response = await apiClient.post('/user/update-data', data)
  return response
}
