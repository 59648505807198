import axios, { AxiosError } from 'axios'
import i18n from 'i18next'
import qs from 'qs'

import { injectTokenToRequest } from '../interceptors/injectToken'
import { injectUserTelemetryDataToRequest } from '../interceptors/injectUserTelemetryData'

import { ENV, SECOND_IN_MS } from '~constants'
import { getToast, signOutWhenNotAuthorized } from '~utils'

export type ApiError =
  | {
      code: number
      message: string
      error: string
      errors: never
    }
  | {
      code: never
      message: never
      error: never
      errors: {
        [key: string]: string[]
      }
    }

const ApiErrorToHandleInSite = ['EmailNotVerified']

export const baseURL = ENV.API_URL

export const apiClient = axios.create({
  baseURL,
  timeout: 30 * SECOND_IN_MS,
  paramsSerializer: (params) => qs.stringify(params),
})

apiClient.interceptors.request.use(injectTokenToRequest, (error) => {
  console.log('Error while sending request', JSON.stringify(error, null, 2))
  return Promise.reject(error)
})

apiClient.interceptors.request.use(injectUserTelemetryDataToRequest, (error) => {
  console.log('Error while sending request', JSON.stringify(error, null, 2))
  return Promise.reject(error)
})

apiClient.interceptors.response.use(
  async (response) => {
    return response?.data
  },
  async (error: AxiosError<ApiError>) => {
    const { showErrorMessage } = await signOutWhenNotAuthorized(error)
    if (!showErrorMessage) return

    if (!error?.response || !error?.response?.data) {
      return
    }

    // handle form errors
    if ('errors' in error.response.data) {
      const errors = error.response.data.errors

      if (Object.keys(errors).length) {
        return Promise.reject(errors)
      }
      // handle BE error
    }
    if ('message' in error.response.data && 'error' in error.response.data) {
      const errorMessage = error?.response?.data?.message || error.response.data.error

      const showErrorMessage = !ApiErrorToHandleInSite.includes(error.response.data.error)

      if (errorMessage && showErrorMessage) {
        const { showErrorToast } = getToast()
        showErrorToast(errorMessage)
        return Promise.reject(new Error('Error displayed in toast'))
      }

      if (!showErrorMessage) {
        return Promise.reject(error?.response?.data)
      }
    }

    return Promise.reject(new Error(i18n.t('errors.something_went_wrong')))
  }
)
