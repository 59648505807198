import i18n from 'i18next'
import { DefaultVariants } from 'react-native-notificated/lib/typescript/defaultConfig/types'

import { notify } from '../providers/NotificatedProvider'

type GetToastProps = {
  showErrorToast: (description: string) => void
  showInformationToast: (description: string) => void
  showSuccessToast: (description: string) => void
  showWarningToast: (description: string) => void
}

export const getToast = (): GetToastProps => {
  const showCustomToast = ({
    description,
    variant,
  }: {
    description: string
    variant: keyof DefaultVariants
  }) => {
    const title = {
      error: i18n.t('errors.something_went_wrong'),
      info: i18n.t('common.information'),
      warning: i18n.t('common.attention'),
      success: i18n.t('common.success'),
    }[variant]

    notify(variant, {
      params: {
        title,
        description,
      },
    })
  }

  const showErrorToast = (description: string): void => {
    showCustomToast({ description, variant: 'error' })
  }

  const showInformationToast = (description: string): void => {
    showCustomToast({ description, variant: 'info' })
  }

  const showSuccessToast = (description: string): void => {
    showCustomToast({ description, variant: 'success' })
  }

  const showWarningToast = (description: string): void => {
    showCustomToast({ description, variant: 'warning' })
  }

  return {
    showErrorToast,
    showInformationToast,
    showSuccessToast,
    showWarningToast,
  }
}
