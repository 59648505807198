import { FC } from 'react'
import { ImageSourcePropType, StyleSheet } from 'react-native'

import { Box, ExpoImage } from '../atoms'

type TokenAndNetworkProps = {
  tokenImage: ImageSourcePropType
  networkImage?: ImageSourcePropType | undefined
  showOnlyToken?: boolean
}
export const TokenAndNetworkLogo: FC<TokenAndNetworkProps> = ({
  tokenImage,
  networkImage,
  showOnlyToken,
}) => {
  return (
    <Box position="relative" h={12} w={12}>
      <ExpoImage
        alt="Token logo"
        contentFit="contain"
        source={tokenImage}
        style={styles.token_image}
      />
      {showOnlyToken || !networkImage ? null : (
        <ExpoImage
          alt="Network logo"
          contentFit="contain"
          source={networkImage}
          style={styles.network_image}
        />
      )}
    </Box>
  )
}

const styles = StyleSheet.create({
  network_image: {
    borderRadius: 99,
    bottom: -4,
    height: 28,
    left: -3,
    position: 'absolute',
    width: 28,
  },
  token_image: {
    height: '100%',
    width: '100%',
  },
})
