import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { RefObject, useCallback, useImperativeHandle } from 'react'
import { ScrollView } from 'react-native'

import { BottomSheetHeader } from './BottomSheetHeader'

import { Modal } from '~components/Modal'
import { Box } from '~components/atoms'
import { useColorScheme } from '~contexts'
import { useBoolean, useWeb } from '~hooks'

type Props = {
  bottomSheetRef: RefObject<BottomSheetModal>
  children: JSX.Element | JSX.Element[]
  numberOfTitleLines?: number
  showCloseButton?: boolean
  title?: string
}

export const BottomSheetScrollView = ScrollView
export const BottomSheet = ({
  bottomSheetRef,
  children,
  numberOfTitleLines,
  showCloseButton = true,
  title,
}: Props) => {
  const [isOpen, setIsOpen] = useBoolean(false)
  const { webContentWidth } = useWeb()
  const { colorScheme } = useColorScheme()

  useImperativeHandle(bottomSheetRef, () => ({
    snapToPosition: (index: string | number) => {
      if (index === -1) {
        setIsOpen.off()
      }
    },
    present: setIsOpen.on,
    dismiss: setIsOpen.off,
    close: setIsOpen.off,
    collapse: setIsOpen.off,
    expand: setIsOpen.on,
    forceClose: setIsOpen.off,
    snapToIndex: (index: string | number) => {
      if (index === -1) {
        setIsOpen.off()
      }
    },
  }))
  const closeModalHandler = useCallback(() => {
    setIsOpen.off()
  }, [setIsOpen])

  return (
    <Modal onRequestClose={closeModalHandler} transparent visible={isOpen}>
      <Box
        bg="white"
        borderColor={colorScheme === 'dark' ? 'gray.900' : 'gray.50'}
        borderRadius={8}
        borderWidth={1}
        maxW={webContentWidth > 640 ? 640 : webContentWidth}
        mx="auto"
        w="100%"
      >
        <BottomSheetHeader
          title={title}
          numberOfLines={numberOfTitleLines}
          showCloseButton={showCloseButton}
          onClose={setIsOpen.off}
        />
        <Box bg={colorScheme === 'dark' ? 'gray.900' : 'gray.50'} pb="1px" />
        {children}
      </Box>
    </Modal>
  )
}
