export const ASYNC_STORAGE_KEYS = {
  COLOR_SCHEME: '@theme/colorScheme',
  CONFIRM_CHANGE_EMAIL_OOB_CODE: '@oobCode/confirm-change-email',
  NAVIGATION_STATE: '@navigation/navigation-state',
  NEXT_DEEP_LINK: '@navigation/next_deeplink',
  PUSH_TOKEN: '@notification/push-token',
  USER_LANGUAGE: '@language/user-language',
  // This value is used in `expo-secure-store` package and it can't include '@' and '/'
  USER_TOKEN: 'user_token',
  USER_REFRESH_TOKEN: 'user_refresh_token',
} as const
