import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxProps, Icon, Row, Text, Touchable } from '../atoms'

import { TRANSACTION_TYPE } from '~enums'
import { TranslationKeys } from '~i18n'
import { TransactionHistory } from '~services'
import {
  convertToEuro,
  formatToLongDateWithTime,
  openTransactionDetails,
  renderMaxDecimalPlaces,
} from '~utils'

type TransactionCoinItemProps = {
  cryptoToEuroRate: number | undefined
} & TransactionHistory &
  BoxProps

export const TransactionCoinItem = ({
  createdAt,
  cryptoToEuroRate,
  fullName,
  networkLabel = 'Ethereum',
  shortName,
  status,
  transactionHash = '',
  transactionType,
  value,
  ...props
}: TransactionCoinItemProps) => {
  const { i18n } = useTranslation()
  const locale = i18n.language.slice(0, 2).toLowerCase() as TranslationKeys

  const navigateToTransactionDetails = useCallback(() => {
    openTransactionDetails({ networkLabel, transactionHash })
  }, [networkLabel, transactionHash])

  return (
    <Touchable onPress={navigateToTransactionDetails}>
      <Row alignItems="center" bg="white" justifyContent="space-between" p={4} {...props}>
        {transactionType ? (
          <Box p={3}>
            <Icon
              color={transactionType === TRANSACTION_TYPE.INCOMING ? 'green.500' : 'muted.300'}
              name={transactionType === TRANSACTION_TYPE.INCOMING ? 'download-line' : 'upload-line'}
              size={24}
            />
          </Box>
        ) : null}
        <Box flex={1} ml={4} mr={2}>
          {createdAt && (
            <Box flex={1} justifyContent="center">
              <Text.Bold color="brand_dark">
                {formatToLongDateWithTime(createdAt, locale)}
              </Text.Bold>
            </Box>
          )}
          {status ? (
            <Text.Caption color="coolGray.400" mt={2}>
              {status}
            </Text.Caption>
          ) : null}
        </Box>
        <Box>
          <Text.Bold
            color={transactionType === TRANSACTION_TYPE.INCOMING ? 'success.600' : 'muted.400'}
            textAlign="right"
          >
            €{convertToEuro(value, cryptoToEuroRate)}
          </Text.Bold>

          {typeof value === 'number' && (
            <Text.Caption color="coolGray.400" mt={2} textAlign="right">
              {renderMaxDecimalPlaces(value)} {shortName}
            </Text.Caption>
          )}
        </Box>
      </Row>
    </Touchable>
  )
}
