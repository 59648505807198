import { StackNavigationOptions } from '@react-navigation/stack'
import { t } from 'i18next'

import { RootStackScreens } from './enums'
import { bottomTabsScreensData } from './tabs'

import { Header } from '~components'
import { isWeb } from '~constants'
import { BottomTabNavigator } from '~navigation/BottomTabNavigator'
import { WebNavigator } from '~navigation/webNavigator/WebNavigator'
import {
  AboutAppScreen,
  AccountCreatedScreen,
  ActionScreen,
  ActivateAccountScreen,
  ApplicationInfoScreen,
  ChangeEmailScreen,
  ChangePasswordScreen,
  CompanyDetailsScreen,
  CompanyRepresentativeScreen,
  ConfirmChangeEmailScreen,
  ConfirmResetPasswordScreen,
  ContactAddressScreen,
  EmailVerificationScreen,
  NotFoundScreen,
  PersonalDataScreen,
  ProfileScreen,
  QRScannerScreen,
  RegistrationAddressScreen,
  ResetPasswordInfoScreen,
  ResetPasswordScreen,
  SelectLanguageScreen,
  SettingsScreen,
  SignInScreen,
  SignUpScreen,
  TokenDetailsScreen,
  TransactionsScreen,
} from '~screens'

type RootScreenType = {
  name: keyof RootStackParamList
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: { navigation: any; route: any }) => JSX.Element
  options?: StackNavigationOptions
  deeplink: string
}

type RootScreensType = {
  authorized: RootScreenType[]
  unauthorized: RootScreenType[]
  modals: RootScreenType[]
}

// RootStack_SCREENS_START
export const rootStackScreensData: RootScreensType = {
  authorized: [
    {
      name: RootStackScreens.MainTab,
      component: isWeb ? WebNavigator : BottomTabNavigator,
      options: { title: t('navigation.screen_titles.home'), headerShown: false },
      deeplink: '/',
    },
    {
      name: RootStackScreens.Settings,
      component: SettingsScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.settings'),
      },
      deeplink: '/settings',
    },
    {
      name: RootStackScreens.TokenDetails,
      component: TokenDetailsScreen,
      options: {
        title: t('navigation.screen_titles.token_details'),
        header: (props) => <Header isRightIcon={false} {...props} />,
      },
      deeplink: '/token-details',
    },
    {
      name: RootStackScreens.Transactions,
      component: TransactionsScreen,
      options: {
        title: t('navigation.screen_titles.transactions'),
        header: (props) => <Header isRightIcon={false} {...props} />,
      },
      deeplink: '/transactions',
    },
    {
      name: RootStackScreens.QRScanner,
      component: QRScannerScreen,
      options: {
        header: (props) => <Header isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.qr_scanner'),
      },
      deeplink: '/qr-scanner',
    },
    {
      name: RootStackScreens.AboutApp,
      component: AboutAppScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.about_app'),
      },
      deeplink: '/about-app',
    },
    {
      name: RootStackScreens.SelectLanguage,
      component: SelectLanguageScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.select_language'),
      },
      deeplink: '/select-language',
    },
    {
      name: RootStackScreens.Profile,
      component: ProfileScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.profile'),
      },
      deeplink: '/profile',
    },
    {
      name: RootStackScreens.ChangeEmail,
      component: ChangeEmailScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.change_email'),
      },
      deeplink: '/change-email',
    },
    {
      name: RootStackScreens.ChangePassword,
      component: ChangePasswordScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.change_password'),
      },
      deeplink: '/change-password',
    },
    {
      name: RootStackScreens.PersonalData,
      component: PersonalDataScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.personal_data'),
      },
      deeplink: '/personal-data',
    },
    {
      name: RootStackScreens.RegistrationAddress,
      component: RegistrationAddressScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.registration_address'),
      },
      deeplink: '/registration-address',
    },
    {
      name: RootStackScreens.ContactAddress,
      component: ContactAddressScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.contact_address'),
      },
      deeplink: '/contact-address',
    },
    {
      name: RootStackScreens.CompanyDetails,
      component: CompanyDetailsScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.company_details'),
      },
      deeplink: '/company-details',
    },
    {
      name: RootStackScreens.CompanyRepresentative,
      component: CompanyRepresentativeScreen,
      options: {
        header: (props) => <Header isDark isRightIcon={false} {...props} />,
        title: t('navigation.screen_titles.company_representative'),
      },
      deeplink: '/company-representative',
    },
    {
      name: RootStackScreens.ConfirmChangeEmail,
      component: ConfirmChangeEmailScreen,
      options: {
        title: t('navigation.screen_titles.confirm_change_emila'),
      },
      deeplink: '/confirmchangeemail',
    },
    {
      name: RootStackScreens.Action,
      component: ActionScreen,
      options: { title: t('navigation.screen_titles.action') },
      deeplink: '/action',
    },
    // authorized_SCREENS_END
  ],
  unauthorized: [
    // Sign in screen should be at the first position (initial stack screen)
    {
      name: RootStackScreens.SignIn,
      component: SignInScreen,
      options: { headerShown: false, title: t('navigation.screen_titles.sign_in') },
      deeplink: '/sign-in',
    },
    {
      name: RootStackScreens.SignUp,
      component: SignUpScreen,
      options: { headerShown: false, title: t('navigation.screen_titles.sign_up') },
      deeplink: '/sign-up',
    },
    {
      name: RootStackScreens.ResetPassword,
      component: ResetPasswordScreen,
      options: { headerShown: false, title: t('navigation.screen_titles.reset_password') },
      deeplink: '/reset-password',
    },
    {
      name: RootStackScreens.ResetPasswordInfo,
      component: ResetPasswordInfoScreen,
      options: { headerShown: false, title: t('navigation.screen_titles.reset_password_info') },
      deeplink: '/reset-password-info',
    },
    {
      name: RootStackScreens.ActivateAccount,
      component: ActivateAccountScreen,
      options: { headerShown: false },
      deeplink: '/activate-account',
    },
    {
      name: RootStackScreens.EmailVerification,
      component: EmailVerificationScreen,
      options: { headerShown: false, title: t('navigation.screen_titles.email_verification') },
      deeplink: '/email-verification',
    },
    {
      name: RootStackScreens.AccountCreated,
      component: AccountCreatedScreen,
      options: { headerShown: false, title: t('navigation.screen_titles.account_created') },
      deeplink: '/account-created',
    },
    {
      name: RootStackScreens.Action,
      component: ActionScreen,
      options: { title: t('navigation.screen_titles.action') },
      deeplink: '/action',
    },
    {
      name: RootStackScreens.ConfirmResetPassword,
      component: ConfirmResetPasswordScreen,
      options: { title: t('navigation.screen_titles.confirm_reset_password') },
      deeplink: '/confirm-reset-password',
    },
    // unauthorized_SCREENS_END
  ],
  modals: [
    {
      name: RootStackScreens.ApplicationInfo,
      component: ApplicationInfoScreen,
      options: { title: t('navigation.screen_titles.application_info') },
      deeplink: '/app-info',
    },
    {
      name: RootStackScreens.NotFound,
      component: NotFoundScreen,
      options: { title: t('navigation.screen_titles.not_found') },
      deeplink: '*',
    },
    // modals_SCREENS_END
  ],
} // RootStack_SCREENS_END

export const webScreensData = bottomTabsScreensData.map((tab) => tab?.screens ?? []).flat()
