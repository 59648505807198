import { KYC_STATUS } from '~enums'
import { apiClient } from '~services/api'

export type GetVideoVerificationStatusProps = {
  id: string
}

export type GetVideoVerificationStatusData = {
  result: KYC_STATUS
}

export const getVideoVerificationStatusRequest = async ({
  id,
}: GetVideoVerificationStatusProps) => {
  return await apiClient.get<GetVideoVerificationStatusData, GetVideoVerificationStatusData>(
    `/idnow/video/${id}`
  )
}
