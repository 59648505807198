import { WalletItemProps } from '~components'

const ROUND_CRYPTO_COINS = 7
const ROUND_TRADITIONAL_COINS = 2

// Renders value with exact number of decimal places
export const renderExactDecimalPlaces = (
  value: number,
  to: number = ROUND_TRADITIONAL_COINS
): string => value.toFixed(to)

// Renders value with a maximum number of decimal places
export const renderMaxDecimalPlaces = (value: number, to: number = ROUND_CRYPTO_COINS): string => {
  const valueStr = value.toString()
  if (valueStr.includes('e')) {
    const [base, exponent] = valueStr.split('e')
    const baseDecimals = base.split('.')[1]?.length || 0
    return value.toFixed(Number(baseDecimals) - Number(exponent))
  }
  if (valueStr.split('.')[1]?.length > to) {
    return value.toFixed(to)
  }
  return valueStr
}

// FIXME: remove this values, and add real conversion factor
const CONVERSION_FACTOR_TO_EURO = 2000
const CONVERSION_FACTOR_FROM_EURO = 1 / CONVERSION_FACTOR_TO_EURO

export const convertToEuro = (
  value?: string | number,
  conversionFactor: number = CONVERSION_FACTOR_TO_EURO
): string => {
  if (typeof value === 'undefined') return '0'
  if (typeof value === 'number') {
    const euroValue = value * conversionFactor
    return euroValue.toFixed(ROUND_TRADITIONAL_COINS)
  } else {
    const euroValue = Number(value) * conversionFactor
    return euroValue.toFixed(ROUND_TRADITIONAL_COINS)
  }
}

export const convertFromEuro = (
  value?: string | number,
  conversionFactor: number = CONVERSION_FACTOR_FROM_EURO
): string => {
  if (typeof value === 'undefined') return '0'
  if (typeof value === 'number') {
    return String(Number((value * conversionFactor).toFixed(ROUND_CRYPTO_COINS)))
  } else {
    return String(Number((Number(value) * conversionFactor).toFixed(ROUND_CRYPTO_COINS)))
  }
}

type CalculateWalletValueProps = {
  assetsList: WalletItemProps[]
}

export const calculateWalletValueInEuro = ({ assetsList }: CalculateWalletValueProps): number => {
  const walletValueInEuro = assetsList.reduce(
    (acc, el) => acc + Number(convertToEuro(el.value, el.cryptoToEuroRate)),
    0
  )
  return Number(walletValueInEuro.toFixed(2))
}
