import { useMutation } from '@tanstack/react-query'

import { ASYNC_STORAGE_KEYS } from '~constants'
import { useAuth, useTranslation } from '~hooks'
import { confirmChangeEmailRequest } from '~services'
import { getToast, noop, secureStore } from '~utils'

const { CONFIRM_CHANGE_EMAIL_OOB_CODE } = ASYNC_STORAGE_KEYS

const { showSuccessToast } = getToast()

export const useConfirmChangeEmailMutation = () => {
  const { t } = useTranslation()
  const { signOut } = useAuth()

  const { isLoading, mutate, isError } = useMutation(confirmChangeEmailRequest, {
    onSuccess: () => {
      signOut(true)
      showSuccessToast(t('confirm_change_email_screen.email_changed_successfully_alert'))
    },
    onError: noop,
    onSettled: () => secureStore.removeItem(CONFIRM_CHANGE_EMAIL_OOB_CODE),
  })

  return {
    confirmChangeEmailMutation: mutate,
    confirmChangeEmailIsLoading: isLoading,
    isError,
  }
}
