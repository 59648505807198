import { TransactionHistoryList } from './getTransactionHistoryRequest'

import { apiClient } from '~services/api'
import { TOKEN_SHORTNAME_TYPE } from '~types/assets'

export type GetTokenTransactionsHistoryFormValues = {
  shortName: TOKEN_SHORTNAME_TYPE
}

type GetTokenTransactionsHistoryResponse = TransactionHistoryList

export const getTokenTransactionHistoryRequest = async ({
  shortName,
}: GetTokenTransactionsHistoryFormValues) => {
  const response = await apiClient.get<
    GetTokenTransactionsHistoryResponse,
    GetTokenTransactionsHistoryResponse
  >(`/tangany/token-transactions-history/${shortName}`)

  return response
}
