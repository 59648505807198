import { apiClient } from '~services/api'

export const confirmResetPasswordRequest = async ({
  oobCode,
  newPassword,
}: {
  oobCode: string
  newPassword: string
}) => {
  const response = await apiClient.post('/user/confirm-reset-password', { newPassword, oobCode })

  return response
}
