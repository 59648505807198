import * as Clipboard from 'expo-clipboard'
import React, { useCallback, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import { Box, Button, Center, ExpoImage, Icon, Loader, QRCode, Row, Spacer, Text } from '../atoms'
import { Field, TransactionHeader } from '../molecules'

import { INFO, theme } from '~constants'
import { KYC_STATUS, WALLET_CHAINS } from '~enums'
import { useTranslation, useWindowDimensions } from '~hooks'
import { useGetWalletStatusQuery, useMeQuery } from '~query-hooks'
import { getToast } from '~utils'

type WalletChainKeys = keyof typeof WALLET_CHAINS
type SelectedWalletChainType = (typeof WALLET_CHAINS)[WalletChainKeys]

const walletChainItems = [
  { label: 'Ethereum', value: WALLET_CHAINS.ETHEREUM },
  { label: 'Bitcoin', value: WALLET_CHAINS.BITCOIN },
]

const { showSuccessToast } = getToast()

export const Receive = () => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const { walletStatus, isLoadingWalletStatus } = useGetWalletStatusQuery()
  const { userData } = useMeQuery()

  const [selectedWalletType, setSelectedWalletType] = useState<SelectedWalletChainType[]>([
    WALLET_CHAINS.ETHEREUM,
  ])

  const walletAddress = useMemo(
    () => walletStatus?.[selectedWalletType[0]]?.address || '',
    [selectedWalletType, walletStatus]
  )

  const copyToClipboard = useCallback(async () => {
    await Clipboard.setStringAsync(walletAddress)
    showSuccessToast(t('toast.success.copied_to_clipboard'))
  }, [t, walletAddress])

  if (isLoadingWalletStatus) {
    return <Loader type="bubbles" />
  }

  return (
    <Box bg="white" borderRadius={8} p={4}>
      {!walletStatus ? (
        <Row alignItems="center" justifyContent="center">
          <ExpoImage source={INFO} style={styles.info_icon} />
          <Text.Body color="warning.900" ml={4}>
            {userData?.lastShownKycModal === KYC_STATUS.SUCCESS
              ? t('transactions_screen.wallet_issue')
              : t('transactions_screen.receive_info')}
          </Text.Body>
        </Row>
      ) : (
        <>
          <TransactionHeader iconName="download-line" title={t('common.receive')} />
          <Center my={6}>
            {walletAddress ? (
              <QRCode
                value={walletAddress}
                size={width < theme.light.breakpoints.mobile ? width - 60 : 300}
              />
            ) : null}
          </Center>
          <Text.Caption color="text.500">{t('common.blockchain')}</Text.Caption>
          <Field.Select
            items={walletChainItems}
            placeholder={t('transactions_screen.select_chain')}
            maxSelectedItems={1}
            setValue={setSelectedWalletType}
            value={selectedWalletType}
          />
          <Spacer y="6" />
          <Text.Caption color="text.500">{t('common.address')}</Text.Caption>
          <Field.Input isDisabled mb={6} mt={1} pointerEvents="none" value={walletAddress} />
          <Button
            leftIcon={<Icon color="white" name="file-copy-line" size={20} />}
            onPress={copyToClipboard}
          >
            {t('transactions_screen.copy_address')}
          </Button>
          {/* FIXME: add share button option, when releasing mobile version */}
          {/* <Button
            bgColor="primary.100"
            leftIcon={<Icon color="primary.900" name="share-line" size={20} />}
            mt={4}
            _text={{ color: 'primary.900' }}
          >
            {t('transactions_screen.share')}
          </Button> */}
        </>
      )}
    </Box>
  )
}

const styles = StyleSheet.create({
  info_icon: { height: 16, width: 16 },
})
