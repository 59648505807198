import { useNavigation } from '@react-navigation/native'
import { isError } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useMeQuery, useUpdateUserDataMutation } from '~query-hooks'
import { UpdateCompanyRepresentativeFormValues } from '~types/authForms'
import { hapticImpact } from '~utils'

export const defaultCompanyRepresentativeValues: UpdateCompanyRepresentativeFormValues = {
  title: '',
  salutation: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  nationality: '',
  phoneNumber: '',
  phoneNumberPrefix: '',
}

export const useCompanyRepresentativeForm = () => {
  const { t } = useTranslation()
  const { goBack } = useNavigation()

  const [areDefaultValuesSet, setAreDefaultValuesSet] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { isLoadingUserData, userData } = useMeQuery()
  const { updateUserDataMutation } = useUpdateUserDataMutation({
    successMessage: t('toast.success.company_representative_updated'),
  })

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    setError: setFormError,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<UpdateCompanyRepresentativeFormValues>({
    mode: 'onTouched',
    defaultValues: defaultCompanyRepresentativeValues,
  })

  useEffect(() => {
    if (!isLoadingUserData && userData?.companyRepresentative && !areDefaultValuesSet) {
      reset({ ...userData?.companyRepresentative })
      setAreDefaultValuesSet(true)
    }
  }, [areDefaultValuesSet, isLoadingUserData, reset, userData?.companyRepresentative])

  const onSubmit = async (companyRepresentative: UpdateCompanyRepresentativeFormValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      await updateUserDataMutation({ companyRepresentative })
      goBack()
    } catch (e) {
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    areDefaultValuesSet,
    control,
    error,
    errors,
    getValues,
    handleSubmit,
    isSubmitting,
    setFocus,
    setFormError,
    setIsSubmitting,
    setValue,
    submit: handleSubmit(onSubmit),
    trigger,
    watch,
  }
}
