import { Box, Button, Divider, Row, Spacer, Text } from '../atoms'
import { TransactionHeader } from '../molecules'

import { useCallback, useEffect, useNavigation, useTranslation } from '~hooks'
import { convertToEuro, getToast, truncateWalletAddress } from '~utils'

type TransactionSummaryProps = {
  amount: string
  cryptoToEuroRate: number
  estimatedTransactionFeeInEuro: string
  from: string
  isSubmitting: boolean
  networkLabel: string
  onBack: () => void
  onConfirm: () => Promise<void>
  to: string
  tokenAbbreviation: string
}

const { showSuccessToast } = getToast()

export const TransactionSummary = ({
  amount,
  cryptoToEuroRate,
  estimatedTransactionFeeInEuro,
  from,
  isSubmitting,
  networkLabel,
  onBack,
  onConfirm,
  to,
  tokenAbbreviation,
}: TransactionSummaryProps) => {
  const { t } = useTranslation()

  const navigation = useNavigation()

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      e.preventDefault()
      onBack?.()
    })

    return () => {
      unsubscribe()
    }
  }, [navigation, onBack])

  const onConfirmPress = useCallback(async () => {
    try {
      await onConfirm()
      showSuccessToast('Transaction confirmed')
      onBack?.()
    } catch (e) {
      console.log(e)
    }
  }, [onBack, onConfirm])

  const amountInEuro = convertToEuro(amount, cryptoToEuroRate)
  const totalCostInEuro = convertToEuro(
    Number(amountInEuro) + Number(estimatedTransactionFeeInEuro),
    1
  )

  return (
    <Box bg="white" borderRadius={8} p={4}>
      <TransactionHeader
        iconName="file-transfer-line"
        title={t('transactions_screen.transaction_summary')}
      />
      <Spacer y="6" />
      <SummaryRow label={t('common.amount')} value={amount + ' ' + tokenAbbreviation} />
      <Spacer y="4" />
      <SummaryRow label={t('common.value')} value={'€ ' + amountInEuro} />
      <Divider my={4} />
      <SummaryRow label={t('common.from')} value={truncateWalletAddress(from)} />
      <Spacer y="4" />
      <SummaryRow label={t('common.receiver')} value={truncateWalletAddress(to)} />
      <Spacer y="4" />
      <SummaryRow label={t('common.network')} value={networkLabel} />
      <Divider my={4} />
      <Box bg="primary.100" borderRadius={4} px={3} py={4} gap={3}>
        <Row justifyContent="space-between">
          <Text.Body color="text.500">{t('transactions_screen.transaction_fee')}</Text.Body>
          <Text.Body color="text.500">{'€ ' + estimatedTransactionFeeInEuro}</Text.Body>
        </Row>
        <Row justifyContent="space-between" alignItems="center">
          <Text.Bold color="primary.500">{t('transactions_screen.total_cost')}</Text.Bold>
          <Text.H5Bold color="text.900">{'€ ' + totalCostInEuro}</Text.H5Bold>
        </Row>
      </Box>
      <Button disabled={isSubmitting} mt={6} onPress={onBack} variant="Ghost">
        {t('common.back')}
      </Button>
      <Button disabled={isSubmitting} loading={isSubmitting} mt={4} onPress={onConfirmPress}>
        {t('common.confirm')}
      </Button>
    </Box>
  )
}

type SummaryRowProps = {
  label: string
  value: string
}
const SummaryRow = ({ label, value }: SummaryRowProps) => {
  return (
    <Row alignItems="center" justifyContent="space-between">
      <Text.Caption color="text.500" flex={2}>
        {label}
      </Text.Caption>
      <Spacer x="4" />
      <Text.Subtitle color="text.900" flex={3} textAlign={'right'}>
        {value}
      </Text.Subtitle>
    </Row>
  )
}
