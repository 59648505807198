import { useNavigation } from '@react-navigation/native'
import { isError } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useConfirmResetPasswordMutation } from '~query-hooks'
import { ConfirmResetPasswordFormValues } from '~types/authForms'
import { getToast, hapticImpact } from '~utils'

const defaultValues: ConfirmResetPasswordFormValues = {
  newPassword: '',
  repeatNewPassword: '',
}

type UseConfirmResetPasswordFormProps = {
  oobCode: string
}
export const useConfirmResetPasswordPasswordForm = ({
  oobCode,
}: UseConfirmResetPasswordFormProps) => {
  const { navigate } = useNavigation()
  const { t } = useTranslation()

  const { confirmResetPasswordMutation } = useConfirmResetPasswordMutation()

  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    formState: { errors },
    setError: setFormError,
    clearErrors: clearFormErrors,
    setFocus,
    handleSubmit,
  } = useForm<ConfirmResetPasswordFormValues>({
    mode: 'onTouched',
    defaultValues,
  })

  const onSubmit = async ({ newPassword }: ConfirmResetPasswordFormValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      await confirmResetPasswordMutation({ newPassword, oobCode })

      navigate('SignIn')
      const { showSuccessToast } = getToast()
      showSuccessToast(t('toast.success.password_changed'))
    } catch (e) {
      console.log(e)
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    clearFormErrors,
    control,
    error,
    errors,
    isSubmitting,
    setFocus,
    setFormError,
    setIsSubmitting,
    submit: handleSubmit(onSubmit),
  }
}
