import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { Box, Icon, Text } from '../atoms'

import { useTranslation, useWeb } from '~hooks'
import { IconNames } from '~types/icon'

interface ActionButtonProps {
  iconName: IconNames
  iconSize?: number
  isComingSoon?: boolean
  isToken?: boolean
  onPress?: () => void
  title: string
}

export const ActionButton = ({
  iconName,
  iconSize = 32,
  isComingSoon = false,
  isToken = false,
  onPress,
  title,
}: ActionButtonProps) => {
  const { t } = useTranslation()
  const { shouldApplyDesktopStyles } = useWeb()

  return (
    <TouchableOpacity disabled={isComingSoon} style={styles.button} onPress={onPress}>
      {isComingSoon &&
        (shouldApplyDesktopStyles ? (
          <Box
            bg="secondary.500"
            borderRadius={2}
            flexWrap="nowrap"
            position="absolute"
            bottom={isToken ? -48 : -32}
            px={1}
            py="1px"
          >
            <Text.Small color="white" flexWrap="nowrap" noOfLines={1}>
              {t('common.coming_soon')}
            </Text.Small>
          </Box>
        ) : (
          <Box
            bg="secondary.500"
            borderRadius={2}
            flexWrap="nowrap"
            position="absolute"
            top={-14}
            px={1}
            py="1px"
          >
            <Text.Small color="white" flexWrap="nowrap" noOfLines={1}>
              {t('common.coming_soon')}
            </Text.Small>
          </Box>
        ))}
      <Box alignItems="center" justifyContent="center">
        <Box>
          <Icon
            color={isToken ? 'primary.500' : 'white'}
            name={iconName}
            size={iconSize}
            // eslint-disable-next-line react-native/no-inline-styles
            style={[styles.icon, { opacity: isComingSoon ? 0.4 : 1 }]}
          />
        </Box>
        <Text.SmallBold color={isToken ? 'primary.500' : 'white'} opacity={isComingSoon ? 0.4 : 1}>
          {title}
        </Text.SmallBold>
      </Box>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    flex: 1,
  },
  icon: { padding: 12 },
})
