import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { DeleteAccountReturnData, deleteAccountRequest } from '~services'

export const useDeleteAccountMutation = () => {
  const { mutate, isLoading, error } = useMutation<DeleteAccountReturnData, AxiosError, string>(
    (password) => deleteAccountRequest({ password })
  )

  return { deleteAccount: mutate, isLoading, error }
}
