import { useNavigation } from '@react-navigation/native'
import { isError } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../useAuth'

import { useChangePasswordMutation, useMeQuery } from '~query-hooks'
import { ChangePasswordFormValues } from '~types/authForms'
import { getToast, hapticImpact } from '~utils'

const defaultValues: ChangePasswordFormValues = {
  oldPassword: '',
  newPassword: '',
  repeatNewPassword: '',
}

export const useChangePasswordForm = () => {
  const { t } = useTranslation()
  const { goBack } = useNavigation()

  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { userData } = useMeQuery()
  const { signIn } = useAuth()
  const { changePasswordMutation } = useChangePasswordMutation()

  const {
    control,
    formState: { errors },
    setError: setFormError,
    clearErrors: clearFormErrors,
    setFocus,
    handleSubmit,
  } = useForm<ChangePasswordFormValues>({
    mode: 'onTouched',
    defaultValues,
  })

  const onSubmit = async ({ repeatNewPassword, ...data }: ChangePasswordFormValues) => {
    try {
      setIsSubmitting(true)
      setError('')
      await changePasswordMutation(data)
      await signIn({ email: userData?.email || '', password: repeatNewPassword })
      const { showSuccessToast } = getToast()
      showSuccessToast(t('toast.success.password_changed'))
      goBack()
    } catch (e) {
      if (isError(e)) {
        setError(e.message)
      } else {
        setError(t('errors.something_went_wrong'))
      }
      hapticImpact()
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    clearFormErrors,
    control,
    error,
    errors,
    isSubmitting,
    setFocus,
    setFormError,
    setIsSubmitting,
    submit: handleSubmit(onSubmit),
  }
}
