import { useForm } from 'react-hook-form'

import { useResendVerificationLinkMutation } from '~query-hooks'
import { ResendVerificationLinkFormValues } from '~types/authForms'

const defaultValues: ResendVerificationLinkFormValues = {
  email: '',
}

export const useResendVerificationLinkForm = () => {
  const { resendVerificationLinkMutation, isLoadingResendVerificationLink } =
    useResendVerificationLinkMutation()

  const {
    control,
    formState: { errors },
    setFocus,
    handleSubmit,
  } = useForm<ResendVerificationLinkFormValues>({
    mode: 'onTouched',
    defaultValues,
  })

  const onSubmit = async (data: ResendVerificationLinkFormValues) => {
    resendVerificationLinkMutation(data)
  }

  return {
    control,
    errors,
    isSubmitting: isLoadingResendVerificationLink,
    setFocus,
    submit: handleSubmit(onSubmit),
  }
}
