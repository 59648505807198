import QRCodeSVG from 'react-native-qrcode-svg'

type QRCodeProps = {
  value: string
  size?: number
}

export const QRCode = ({ value, size = 30 }: QRCodeProps) => {
  return <QRCodeSVG value={value} size={size} />
}
