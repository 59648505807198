import { StyleSheet } from 'react-native'

import { Box, Text, Row, Center, ScrollView } from '~components/atoms'
import { palette } from '~constants'
import { useTheme } from '~hooks'

const colorsVariants: (keyof typeof palette)[] = [
  'primary',
  'secondary',
  'tertiary',
  'danger',
  'error',
  'success',
  'warning',
  'muted',
  'info',
  'light',
  'rose',
  'pink',
  'fuchsia',
  'purple',
  'violet',
  'indigo',
  'blue',
  'lightBlue',
  'darkBlue',
  'cyan',
  'teal',
  'emerald',
  'green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'red',
  'warmGray',
  'trueGray',
  'gray',
  'coolGray',
  'blueGray',
  'dark',
]

export const ColorsScreen = (): JSX.Element => {
  const { colors } = useTheme()

  return (
    <ScrollView style={styles.container}>
      {colorsVariants.map((colorVariant) => (
        <Center key={colorVariant}>
          <Text.Body>{colorVariant}</Text.Body>
          <Row my={2}>
            {Object.values(colors[colorVariant]).map((color) => (
              <Box bg={color as ColorNames} key={color} h={8} w={8} />
            ))}
          </Row>
        </Center>
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 16,
  },
})
