import { forwardRef, useCallback } from 'react'
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar'

import { DatePicker as DatePickerField } from '../../atoms/DatePicker'

export type ControlledDatePickerProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>
  errorMessage?: string
  errors?: FieldErrors
  isRequired?: boolean
  name: string
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
}
type RenderDatePickerProps = {
  field: ControllerRenderProps<FieldValues, string>
}

export const DatePicker = forwardRef<{ openDatePicker(): void }, ControlledDatePickerProps>(
  ({ control, errors, isRequired, name, rules }, ref) => {
    const renderComponent = useCallback(
      ({ field: { value, onChange, ...rest } }: RenderDatePickerProps) => {
        return (
          <DatePickerField
            date={value}
            errorMessage={errors?.[name]?.message?.toString() || ''}
            isInvalid={!!errors?.[name]}
            isRequired={isRequired}
            // eslint-disable-next-line react/jsx-no-bind
            onConfirmDate={(params: { date: CalendarDate }) => onChange(params.date)}
            ref={ref}
          />
        )
      },
      [errors, isRequired, name, ref]
    )

    return <Controller control={control} render={renderComponent} name={name} rules={rules} />
  }
)
