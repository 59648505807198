import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useCallback } from 'react'

import { QueryKeys } from '~enums'
import { TransactionHistoryList, getTokenTransactionHistoryRequest } from '~services'
import { TOKEN_SHORTNAME_TYPE } from '~types/assets'

export const useGetTokenTransactionHistoryQuery = ({
  shortName,
  options,
}: {
  shortName: TOKEN_SHORTNAME_TYPE
  options?: UseQueryOptions<TransactionHistoryList, AxiosError>
}) => {
  const request = useCallback(() => getTokenTransactionHistoryRequest({ shortName }), [shortName])

  const {
    data: tokenTransactionHistoryList,
    error: tokenTransactionHistoryListError,
    isInitialLoading: isInitialLoadingTokenTransactionHistoryList,
    refetch: refetchTokenTransactionsHistoryList,
  } = useQuery<TransactionHistoryList, AxiosError>(
    [QueryKeys.TokenTransactionHistory, shortName],
    request,
    options
  )

  return {
    isInitialLoadingTokenTransactionHistoryList,
    refetchTokenTransactionsHistoryList,
    tokenTransactionHistoryList,
    tokenTransactionHistoryListError,
  }
}
