import { StyleSheet } from 'react-native'

import { Box, Button, ExpoImage, Text, UnAuthorizeWrapper } from '~components'
import { ERROR } from '~constants'
import { useCallback, useTranslation } from '~hooks'
import { useResendVerificationLinkMutation } from '~query-hooks'

export const ActivateAccountScreen = ({ route }: ActivateAccountScreenProps): JSX.Element => {
  const { params } = route
  const { t } = useTranslation()

  const { resendVerificationLinkMutation, isLoadingResendVerificationLink } =
    useResendVerificationLinkMutation()

  const resendLink = useCallback(async () => {
    resendVerificationLinkMutation({ email: params?.email })
  }, [params?.email, resendVerificationLinkMutation])

  return (
    <UnAuthorizeWrapper showTopNavBar={false}>
      <Box bg="white" borderRadius={8} gap={6} p={4}>
        <Text.H4>{t('activate_account_screen.activate_your_account')}</Text.H4>
        <ExpoImage
          alt="verify email icon"
          contentFit="contain"
          source={ERROR}
          style={styles.verifyEmailImage}
        />
        <Text.Body>
          {t('activate_account_screen.we_have_trouble', { email: params?.email })}
        </Text.Body>
        <Text.Bold my={6}>{t('activate_account_screen.did_not_receive_link')}</Text.Bold>
        <Button
          disabled={isLoadingResendVerificationLink}
          loading={isLoadingResendVerificationLink}
          onPress={resendLink}
          width="100%"
        >
          {t('activate_account_screen.resend_activation_link')}
        </Button>
      </Box>
    </UnAuthorizeWrapper>
  )
}

const styles = StyleSheet.create({
  verifyEmailImage: {
    alignSelf: 'center',
    height: 94,
    width: 94,
  },
})
