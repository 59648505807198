import { FC, PropsWithChildren } from 'react'

import { AuthContextProvider, AuthContextType, authContextRef } from '~contexts'
import { useCallback, useEffect, useMemo, useState } from '~hooks'
import { useSignInMutation, useSignUpMutation, useSignOutMutation } from '~query-hooks'
import { getToken } from '~services'
import { SignUpFormValues } from '~types/authForms'

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean | null>(null)
  const { signInMutation } = useSignInMutation()
  const { signOutMutation } = useSignOutMutation()
  const { signUpMutation } = useSignUpMutation()

  useEffect(() => {
    const bootstrap = async () => {
      // TODO: This should be moved to backend calls, in this bootstrap function we should fetch user info and not token
      const token = await getToken()
      setIsSignedIn(!!token)
    }

    bootstrap()
  }, [])

  const signIn: AuthContextType['signIn'] = useCallback(
    async (data, options) => {
      await signInMutation(data, options)

      setIsSignedIn(true)
    },
    [signInMutation]
  )

  const signOut = useCallback(
    (skipRequest = false) =>
      signOutMutation(skipRequest, {
        onSettled: () => {
          setIsSignedIn(false)
        },
      }),
    [signOutMutation]
  )

  const signUp = useCallback(
    async (data: SignUpFormValues) => {
      await signUpMutation(data)
    },
    [signUpMutation]
  )

  const value = useMemo(() => {
    return {
      isSignedIn,
      signIn,
      signOut,
      signUp,
    }
  }, [isSignedIn, signIn, signOut, signUp])

  authContextRef.current = value

  return <AuthContextProvider value={value}>{children}</AuthContextProvider>
}
