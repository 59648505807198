import { uniq } from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { Box, Button, Center, ExpoImage, Icon, Loader, Row, Spacer, Text } from '../atoms'
import { Field, TransactionHeader, WalletItemProps } from '../molecules'
import { ControlledField } from './ControlledField'
import { TransactionSummary } from './TransactionSummary'

import { INFO, isWeb } from '~constants'
import { KYC_STATUS, WALLET_CHAINS } from '~enums'
import {
  useBoolean,
  useDebounce,
  useMakeTransactionForm,
  useNavigation,
  useToggle,
  useTranslation,
} from '~hooks'
import {
  useEstimateTransactionFeeMutation,
  useGetAssetsListQuery,
  useGetWalletStatusQuery,
  useMeQuery,
} from '~query-hooks'
import { DeviceEventEmitterService } from '~services'
import {
  convertFromEuro,
  convertToEuro,
  renderMaxDecimalPlaces,
  replaceComaWithDot,
  validateBtcAddress,
  validateEthAddress,
} from '~utils'

type SendProps = {
  goToQRScanner: () => Promise<void>
}

const DEFAULT_ESTIMATE_TRANSACTION_FEE = '0'
const DEFAULT_CRYPTO_TO_EURO_RATE = 1

export const Send = ({ goToQRScanner }: SendProps) => {
  const { t } = useTranslation()
  const { goBack } = useNavigation()

  // LOCAL STATES
  const [isSummary, setIsSummary] = useBoolean(false)
  const [sendFromCrypto, toggleSendFromCrypto] = useToggle(true)
  const [cryptoToEuroRate, setCryptoToEuroRate] = useState<number>(1)

  // BE STATES
  const { assetsList, isInitialLoadingAssetsList } = useGetAssetsListQuery()
  const { walletStatus, isLoadingWalletStatus } = useGetWalletStatusQuery()
  const { userData } = useMeQuery()

  // MAKE TRANSACTION FORM
  const {
    control,
    errors,
    handleSubmit,
    isSubmitting,
    resetField,
    setValue,
    submit,
    trigger,
    watch,
  } = useMakeTransactionForm({
    cryptoToEuroRate,
    sendFromCrypto,
  })

  const watchAmount = watch('amount')
  const watchTo = watch('to')
  const watchToken = watch('shortName')

  const [watchAmountDebounced] = useDebounce(
    sendFromCrypto ? watchAmount : convertFromEuro(watchAmount, 1 / cryptoToEuroRate),
    500
  )

  // PICKED NETWORK, TOKEN AND ADDRESS_FROM

  const [selectedNetwork, setSelectedNetwork] = useState<string[]>([])

  const onChangeAmountType = useCallback(() => {
    toggleSendFromCrypto()
    resetField('amount')
  }, [resetField, toggleSendFromCrypto])

  const networkItems = useMemo(() => {
    const networkItems = uniq(
      assetsList.map((asset) => {
        const value = asset?.networkLabel || asset?.tokenFullName
        return { label: value, value }
      })
    )
    return networkItems
  }, [assetsList])

  const selectedNetworkAssetToSend = useMemo(
    () =>
      selectedNetwork?.[0] === undefined
        ? undefined
        : assetsList?.find((el) => el.networkLabel === selectedNetwork?.[0]) ||
          assetsList?.find((el) => el.tokenFullName === selectedNetwork?.[0]),
    [assetsList, selectedNetwork]
  )

  const addressFrom = useMemo(() => {
    if (!walletStatus) return ''
    if (
      selectedNetworkAssetToSend?.networkLabel === 'Bitcoin' ||
      selectedNetworkAssetToSend?.tokenFullName === 'Bitcoin'
    ) {
      return walletStatus[WALLET_CHAINS.BITCOIN]?.address
    } else if (selectedNetworkAssetToSend?.networkLabel === 'Ethereum') {
      return walletStatus[WALLET_CHAINS.ETHEREUM]?.address
    }
    return ''
  }, [selectedNetworkAssetToSend, walletStatus])

  const tokenItems = useMemo(() => {
    if (!selectedNetworkAssetToSend?.networkLabel) {
      return uniq(
        assetsList.map((asset) => {
          return { label: asset.tokenFullName, value: asset.tokenShortName }
        })
      )
    }
    const tokenItems = uniq(
      assetsList
        .filter((asset) => asset.networkLabel === selectedNetworkAssetToSend?.networkLabel)
        .map((asset) => {
          return { label: asset.tokenFullName, value: asset.tokenShortName }
        })
    )

    return tokenItems
  }, [assetsList, selectedNetworkAssetToSend?.networkLabel])

  const selectedTokenAssetToSend: WalletItemProps | undefined = useMemo(
    () =>
      watchToken === undefined
        ? undefined
        : assetsList?.find((el) => el.tokenShortName === watchToken),
    [assetsList, watchToken]
  )

  const onSelectNetwork = useCallback(
    (newValue: string[]) => {
      setSelectedNetwork(newValue)

      if (
        !selectedTokenAssetToSend?.networkLabel ||
        selectedTokenAssetToSend?.networkLabel === newValue?.[0]
      ) {
        return
      }

      resetField('shortName')

      if (watchAmount) {
        trigger('amount')
      }
      if (watchTo) {
        trigger('to')
      }
    },
    [resetField, selectedTokenAssetToSend, trigger, watchAmount, watchTo]
  )

  useEffect(() => {
    if (selectedTokenAssetToSend?.cryptoToEuroRate) {
      setCryptoToEuroRate(selectedTokenAssetToSend?.cryptoToEuroRate)
    }
  }, [selectedTokenAssetToSend?.cryptoToEuroRate])

  // ESTIMATE FEE
  const [estimatedFee, setEstimatedFee] = useState<string>(DEFAULT_ESTIMATE_TRANSACTION_FEE)

  const resetEstimatedFee = useCallback(() => {
    setEstimatedFee(DEFAULT_ESTIMATE_TRANSACTION_FEE)
  }, [])

  const { estimateTransactionFeeMutation, isLoadingEstimateTransactionFee } =
    useEstimateTransactionFeeMutation({ onError: resetEstimatedFee })

  const handleEstimateTransactionFee = useCallback(async () => {
    try {
      if (watchAmountDebounced && watchTo && watchToken && !!Object.keys(errors)) {
        const isFormValid = await trigger()
        if (!isFormValid) {
          resetEstimatedFee()
          return
        }
        const { fee } = await estimateTransactionFeeMutation({
          shortName: watchToken,
          to: watchTo,
          amount: watchAmountDebounced,
        })

        setEstimatedFee(fee)
      } else {
        resetEstimatedFee()
      }
    } catch (e) {
      console.log('Cannot calculate transaction fee', e)
    }
  }, [
    errors,
    estimateTransactionFeeMutation,
    resetEstimatedFee,
    trigger,
    watchAmountDebounced,
    watchTo,
    watchToken,
  ])

  useEffect(() => {
    handleEstimateTransactionFee()
  }, [handleEstimateTransactionFee])

  const openQrScanner = useCallback(async () => {
    await goToQRScanner()
    DeviceEventEmitterService?.addListener?.(
      'handleCryptoAddress',
      (eventData: { address: string; isEthAddress: boolean }) => {
        setValue('to', eventData.address)
        if (eventData?.isEthAddress) {
          switch (selectedNetworkAssetToSend?.networkLabel) {
            case 'Ethereum':
              goBack()
              break
            case 'Bitcoin':
            default:
              setSelectedNetwork(['Ethereum'])
              if (selectedTokenAssetToSend?.networkLabel !== 'Ethereum') {
                setValue('shortName', undefined)
              }
              goBack()
          }
        } else {
          switch (selectedNetworkAssetToSend?.networkLabel) {
            case 'Bitcoin':
              goBack()
              break
            case 'Ethereum':
            default:
              setSelectedNetwork(['Bitcoin'])
              if (selectedTokenAssetToSend?.networkLabel !== 'Bitcoin') {
                setValue('shortName', undefined)
              }
              goBack()
          }
        }
      }
    )
  }, [
    goBack,
    goToQRScanner,
    selectedNetworkAssetToSend?.networkLabel,
    selectedTokenAssetToSend?.networkLabel,
    setValue,
  ])

  const estimateFeeRatioBasedOnSelectedToken = useMemo(() => {
    if (selectedTokenAssetToSend) {
      if (selectedTokenAssetToSend?.tokenShortName === 'BTC') {
        return (
          assetsList?.find((el) => el.tokenShortName === 'BTC')?.cryptoToEuroRate ||
          DEFAULT_CRYPTO_TO_EURO_RATE
        )
      } else {
        return (
          assetsList?.find((el) => el.tokenShortName === 'ETH')?.cryptoToEuroRate ||
          DEFAULT_CRYPTO_TO_EURO_RATE
        )
      }
    }
    return DEFAULT_CRYPTO_TO_EURO_RATE
  }, [assetsList, selectedTokenAssetToSend])

  const estimatedTransactionFeeInEuro = useMemo(
    () => convertToEuro(estimatedFee, estimateFeeRatioBasedOnSelectedToken),
    [estimateFeeRatioBasedOnSelectedToken, estimatedFee]
  )

  const onSubmitStep0 = useCallback(() => {
    handleSubmit(async () => {
      if (estimatedFee !== DEFAULT_ESTIMATE_TRANSACTION_FEE && !isNaN(Number(estimatedFee))) {
        setIsSummary.on()
      }
    })()
  }, [estimatedFee, handleSubmit, setIsSummary])

  const triggerAmountValidation = useCallback(() => {
    trigger('amount')
  }, [trigger])

  const triggerToValidation = useCallback(() => {
    trigger('to')
  }, [trigger])

  if (isLoadingWalletStatus || isInitialLoadingAssetsList) {
    return <Loader type="bubbles" />
  }

  return !isSummary ? (
    <Box bg="white" borderRadius={8} p={4}>
      {!walletStatus ? (
        <Row alignItems="center" justifyContent="center">
          <ExpoImage source={INFO} style={styles.info_icon} />
          <Text.Body color="warning.900" ml={4}>
            {userData?.lastShownKycModal === KYC_STATUS.SUCCESS
              ? t('transactions_screen.wallet_issue')
              : t('transactions_screen.receive_info')}
          </Text.Body>
        </Row>
      ) : (
        <>
          <TransactionHeader iconName="upload-line" title={t('common.send')} />
          <Spacer y="6" />
          <Field.Select
            label={t('common.network')}
            leftElement={
              selectedNetworkAssetToSend ? (
                <ExpoImage
                  alt="Network logo"
                  contentFit="contain"
                  source={selectedNetworkAssetToSend?.networkImage}
                  style={styles.network_image}
                  transition={300}
                />
              ) : null
            }
            items={networkItems}
            maxSelectedItems={1}
            placeholder={t('transactions_screen.select_network')}
            setValue={onSelectNetwork}
            value={selectedNetwork}
          />
          <ControlledField.Select
            {...{ control, errors }}
            isRequired
            label={t('common.token')}
            leftElement={
              selectedTokenAssetToSend ? (
                <ExpoImage
                  alt="Token logo"
                  contentFit="contain"
                  source={selectedTokenAssetToSend?.tokenImage}
                  style={styles.token_image}
                  transition={300}
                />
              ) : null
            }
            name="shortName"
            items={tokenItems}
            maxSelectedItems={1}
            placeholder={t('transactions_screen.select_token')}
            rules={{ required: t('form.errors.required') }}
          />
          {selectedTokenAssetToSend ? (
            <>
              <Row justifyContent="space-between">
                <Text.Caption color="text.500">
                  {t('transactions_screen.my_balance')}:{' '}
                  {renderMaxDecimalPlaces(selectedTokenAssetToSend?.value)}{' '}
                  {selectedTokenAssetToSend?.tokenShortName}
                </Text.Caption>
                <Text.Caption color="text.500">
                  {t('common.value')}: €{' '}
                  {convertToEuro(selectedTokenAssetToSend?.value, cryptoToEuroRate)}
                </Text.Caption>
              </Row>
              <Spacer y="4" />
            </>
          ) : null}
          <ControlledField.Input
            {...{ control, errors }}
            isRequired
            label={t('common.amount')}
            leftElement={
              selectedTokenAssetToSend ? (
                <Center borderColor="muted.300" borderRightWidth={1} h="full" px={3}>
                  <Text.Caption color="text.400" fontSize={20}>
                    {sendFromCrypto ? selectedTokenAssetToSend?.tokenShortName : '€'}
                  </Text.Caption>
                </Center>
              ) : undefined
            }
            name="amount"
            onEndEditing={triggerAmountValidation}
            onChangeText={replaceComaWithDot}
            placeholder={t('transactions_screen.enter_amount')}
            rightElement={
              <Pressable onPress={onChangeAmountType}>
                <Icon color="primary.500" name="refresh-line" size={26} style={styles.input_icon} />
              </Pressable>
            }
            rules={{
              required: t('form.errors.required'),
              validate: (tokenAmount: string) => {
                if (!watchToken) return
                const valueToSend = Number(tokenAmount)
                const selectedAssetTotalValue = sendFromCrypto
                  ? Number(selectedTokenAssetToSend?.value)
                  : Number(convertToEuro(selectedTokenAssetToSend?.value, cryptoToEuroRate))
                const isInvalidFormat = isNaN(Number(valueToSend))

                if (valueToSend < 0) return t('form.errors.send_value_greater_than_zero')

                if (isInvalidFormat) return t('form.errors.invalid_number_format')

                const isInsufficientFounds = selectedAssetTotalValue - valueToSend < 0

                if (isInsufficientFounds) return t('form.errors.insufficient_crypto_balance')
              },
            }}
          />

          {watchAmount === '' ||
          convertFromEuro(watchAmount, 1 / cryptoToEuroRate) === 'NaN' ? null : (
            <>
              <Text.Caption alignSelf="flex-end" color="text.500">
                {t('common.value')}:{' '}
                {sendFromCrypto
                  ? `€ ${convertToEuro(watchAmount, cryptoToEuroRate)}`
                  : convertFromEuro(watchAmount, 1 / cryptoToEuroRate) +
                    ' ' +
                    (selectedTokenAssetToSend?.tokenShortName ?? '')}
              </Text.Caption>
            </>
          )}
          <ControlledField.Input
            {...{ control, errors }}
            isRequired
            label={t('common.receiver')}
            name="to"
            onBlur={triggerToValidation}
            placeholder={t('transactions_screen.enter_address')}
            rightElement={
              !isWeb ? (
                <Pressable onPress={openQrScanner}>
                  <Icon
                    color="muted.500"
                    name="qr-scan-2-line"
                    size={26}
                    style={styles.input_icon}
                  />
                </Pressable>
              ) : undefined
            }
            rules={{
              required: t('form.errors.required'),
              validate: (tokenAddress) => {
                if (!watchToken) return
                const isAddressValid =
                  watchToken === 'BTC'
                    ? validateBtcAddress(tokenAddress)
                    : validateEthAddress(tokenAddress)
                if (!isAddressValid) return t('form.errors.invalid_crypto_address')
              },
            }}
          />

          <Spacer y="4" />
          <Box bg="primary.100" borderRadius={4} px={3} py={4}>
            <Text.Bold color="primary.500">{t('transactions_screen.transaction_fee')}</Text.Bold>
            <Spacer y="3" />
            <Row justifyContent="space-between">
              <Row>
                {isLoadingEstimateTransactionFee ? (
                  <Box w={14} h="100%">
                    <Loader type="circle" size={14} />
                  </Box>
                ) : (
                  <Text.Body color="text.500">
                    {renderMaxDecimalPlaces(Number(estimatedFee))}
                  </Text.Body>
                )}
                <Text.Body color="text.500">
                  {' '}
                  {selectedNetworkAssetToSend?.networkLabel === 'Bitcoin' ? 'BTC' : 'ETH'}
                </Text.Body>
              </Row>
              <Row alignItems="center">
                <Text>{'€ '}</Text>
                {isLoadingEstimateTransactionFee ? (
                  <Box w={14} h="100%">
                    <Loader type="circle" size={14} />
                  </Box>
                ) : (
                  <Text.Body>{estimatedTransactionFeeInEuro}</Text.Body>
                )}
              </Row>
            </Row>
          </Box>
          <Spacer y="6" />
          <Button
            disabled={!!Object.keys(errors).length || isLoadingEstimateTransactionFee}
            leftIcon={<Icon name="upload-line" color="text.50" size={20} />}
            onPress={onSubmitStep0}
          >
            {t('common.send')}
          </Button>
        </>
      )}
    </Box>
  ) : (
    <TransactionSummary
      {...{ cryptoToEuroRate, estimatedTransactionFeeInEuro, isSubmitting }}
      amount={sendFromCrypto ? watchAmount : convertFromEuro(watchAmount, 1 / cryptoToEuroRate)}
      from={addressFrom}
      networkLabel={
        selectedNetworkAssetToSend?.networkLabel || selectedNetworkAssetToSend?.tokenFullName || ''
      }
      onBack={setIsSummary.off}
      onConfirm={submit}
      to={watchTo}
      tokenAbbreviation={selectedTokenAssetToSend?.tokenShortName || ''}
    />
  )
}

const styles = StyleSheet.create({
  info_icon: { height: 16, width: 16 },
  input_icon: { marginRight: 10 },
  network_image: {
    height: 40,
    width: 40,
  },
  token_image: {
    height: 32,
    marginLeft: 4,
    marginRight: 4,
    width: 32,
  },
})
