import { Box, Button, ControlledField, LightScreenWrapper, Row, Spacer } from '~components'
import { COUNTRIES, REGEX, isWeb } from '~constants'
import { useCallback, useTranslation, useRegistrationAddressForm, useWeb } from '~hooks'
import { hideKeyboard } from '~utils'

export const RegistrationAddressScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { shouldApplyDesktopStyles } = useWeb()

  const { control, errors, isSubmitting, setFocus, submit } = useRegistrationAddressForm()

  const setFocusCity = useCallback(() => {
    !isWeb && setFocus('city')
  }, [setFocus])

  const setFocusPostalCode = useCallback(() => {
    setFocus('postalCode')
  }, [setFocus])

  const setFocusStreet = useCallback(() => setFocus('street'), [setFocus])
  const setFocusBuildingNumber = useCallback(() => setFocus('buildingNumber'), [setFocus])
  const setFocusApartment = useCallback(() => setFocus('apartment'), [setFocus])
  const setFocusFloor = useCallback(() => setFocus('floor'), [setFocus])

  return (
    <LightScreenWrapper
      iconName="map-pin-line"
      title={t('navigation.screen_titles.registration_address')}
    >
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Select
          {...{ control, errors }}
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          items={COUNTRIES}
          isRequired
          label={t('form.labels.country')}
          maxSelectedItems={1}
          name="country"
          onPick={setFocusCity}
          placeholder={t('form.placeholders.country')}
          rules={{
            required: t('form.errors.required'),
          }}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          isRequired
          label={t('form.labels.city')}
          mb={2}
          name="city"
          onSubmitEditing={setFocusPostalCode}
          placeholder={t('form.placeholders.city')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.SPECIAL_CHARACTERS_64_NOT_ALLOWED,
              message: t('form.errors.special_characters', { max_length: 64 }),
            },
          }}
        />
      </Box>
      <Box flexDirection={shouldApplyDesktopStyles ? 'row' : 'column'}>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          isRequired
          label={t('form.labels.postal_code')}
          mb={2}
          name="postalCode"
          onSubmitEditing={setFocusStreet}
          placeholder={t('form.placeholders.postal_code')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.SPECIAL_CHARACTERS_16_NOT_ALLOWED,
              message: t('form.errors.special_characters', { max_length: 16 }),
            },
          }}
        />
        {shouldApplyDesktopStyles && <Spacer x="4" />}
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : undefined}
          isRequired
          label={t('form.labels.street')}
          mb={2}
          name="street"
          onSubmitEditing={setFocusBuildingNumber}
          placeholder={t('form.placeholders.street')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.MAX_LENGTH_128,
              message: t('form.errors.maximum_length', { max_length: 128 }),
            },
          }}
        />
      </Box>
      <Row>
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={shouldApplyDesktopStyles ? 1 : 1.5}
          isRequired
          label={t('form.labels.building_number')}
          labelNumberOfLines={1}
          name="buildingNumber"
          onSubmitEditing={setFocusApartment}
          placeholder={t('form.placeholders.building_number')}
          returnKeyType="next"
          rules={{
            required: t('form.errors.required'),
            pattern: {
              value: REGEX.MAX_LENGTH_16,
              message: t('form.errors.maximum_length', { max_length: 16 }),
            },
          }}
        />
        <Spacer x={shouldApplyDesktopStyles ? '4' : '2'} />
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={1}
          keyboardType="number-pad"
          label={t('form.labels.apartment')}
          labelNumberOfLines={1}
          name="apartment"
          onSubmitEditing={setFocusFloor}
          placeholder={t('form.placeholders.apartment')}
          returnKeyType="next"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_16,
              message: t('form.errors.maximum_length', { max_length: 16 }),
            },
          }}
        />
        <Spacer x="2" />
        <ControlledField.Input
          {...{ control, errors }}
          autoCapitalize="none"
          flex={1}
          keyboardType="number-pad"
          label={t('form.labels.floor')}
          labelNumberOfLines={1}
          name="floor"
          onSubmitEditing={hideKeyboard}
          placeholder={t('form.placeholders.floor')}
          returnKeyType="default"
          rules={{
            pattern: {
              value: REGEX.MAX_LENGTH_16,
              message: t('form.errors.maximum_length', { max_length: 16 }),
            },
          }}
        />
      </Row>
      <Button disabled={isSubmitting} loading={isSubmitting} mt={4} onPress={submit}>
        {t('common.save_changes')}
      </Button>
    </LightScreenWrapper>
  )
}
