import { Box, Button, ControlledField, Text } from '~components'
import { useDeleteAccountForm, useTranslation } from '~hooks'

type DeleteAccountModalStep1Props = {
  onCancel(): void
}

export const DeleteAccountModalStep1 = ({ onCancel }: DeleteAccountModalStep1Props) => {
  const { t } = useTranslation()

  const { submit, isSubmitting, isValid, control, errors } = useDeleteAccountForm()

  return (
    <>
      <Text.H4 color="coolGray.800">{t('settings_screen.confirm_account_deletion')}</Text.H4>
      <Text.Body color="coolGray.800">
        {t('settings_screen.confirm_account_deletion_instructions')}
      </Text.Body>
      <ControlledField.Input
        {...{ control, errors }}
        autoCapitalize="none"
        label={t('form.labels.password')}
        name="password"
        placeholder={t('form.placeholders.password')}
        rules={{
          required: t('form.errors.required'),
        }}
        testID="passwordInput"
        type="password"
      />
      <ControlledField.Checkbox
        {...{ control, errors }}
        checkboxText={t('settings_screen.confirm_account_deletion_checkbox')}
        isRequired
        name="isConfirmed"
        rules={{
          required: t('form.errors.required'),
        }}
        size={18}
      />

      <Box w="full">
        <Button disabled={isSubmitting || !isValid} onPress={submit} variant="Delete" w="full">
          {t('settings_screen.delete_account')}
        </Button>
        <Button disabled={isSubmitting} onPress={onCancel} variant="Ghost" w="full">
          {t('common.close')}
        </Button>
      </Box>
    </>
  )
}
