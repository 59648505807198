import { useMemo } from 'react'

import { useTheme } from '../useTheme'
import { useWeb } from './useWeb'

import { darkNavigationTheme, lightNavigationTheme } from '~constants'
import { useColorScheme } from '~contexts'

export const useNavigationTheme = () => {
  const { colorScheme } = useColorScheme()
  const { colors } = useTheme()
  const { shouldApplyDesktopStyles } = useWeb()

  const tabBarTheme = useMemo(
    () => ({
      tabBarActiveTintColor: colors.primary[500],
      tabBarInactiveTintColor: shouldApplyDesktopStyles ? 'white' : colors.brand_dark,
      topTabBarStyle: {
        backgroundColor: colors.transparent,
        paddingHorizontal: 24,
        paddingTop: 4,
      },
      bottomTabBarStyle: {
        backgroundColor: colors.white,
        paddingHorizontal: 32,
        paddingTop: 4,
      },
    }),
    [colors.brand_dark, colors.primary, colors.transparent, colors.white, shouldApplyDesktopStyles]
  )

  const navigationTheme = colorScheme === 'dark' ? darkNavigationTheme : lightNavigationTheme

  return {
    navigationTheme,
    tabBarTheme,
  }
}
