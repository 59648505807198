import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../useAuth'

import { useDeleteAccountMutation } from '~query-hooks'
import { DeleteAccountFormValues } from '~types/authForms'
import { getToast, hapticImpact } from '~utils'

const defaultValues: DeleteAccountFormValues = {
  password: '',
  isConfirmed: false,
}

export const useDeleteAccountForm = () => {
  const { t } = useTranslation()
  const { showSuccessToast } = getToast()
  const { signOut } = useAuth()

  const { deleteAccount, error, isLoading } = useDeleteAccountMutation()

  const {
    control,
    formState: { errors, isValid },
    setFocus,
    handleSubmit,
  } = useForm<DeleteAccountFormValues>({
    mode: 'onTouched',
    defaultValues,
  })

  const onSubmit = (data: DeleteAccountFormValues) => {
    deleteAccount(data.password, {
      onSuccess: () => {
        signOut(true)
        showSuccessToast(t('toast.success.account_deleted'))
      },
      onError: () => {
        hapticImpact()
      },
    })
  }

  return {
    submit: handleSubmit(onSubmit),
    isSubmitting: isLoading,
    isValid,
    setFocus,
    control,
    errors,
    error,
  }
}
